import moment from 'moment';
import countryCodes from 'resources/data/countryCodes';
/* eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars */
import { IProfile } from 'types/index.d';

const form = {
  name: {
    min: 2,
    max: 64,
  },
  surname: {
    min: 2,
    max: 64,
  },
  otherSurnames: {
    min: 2,
    max: 64,
  },
  birthday: {
    max: moment().add(-18, 'years'),
    min: moment().add(-80, 'years'),
  },
  gender: ['male', 'female', 'other'],
  street: {
    min: 2,
    max: 64,
  },
  city: {
    min: 2,
    max: 64,
  },
  isNumber: (text: string) => /[0-9]/g.test(text),
};

const personalDetails = (profile: IProfile) => {
  const validationData = {
    name: true,
    surname: true,
    birthday: true,
    nationality: true,
    otherSurnames: true,
    street: true,
    streetNumber: true,
    city: true,
    cityCode: true,
    country: true,
    gender: true,
  };
  const {
    name,
    surname,
    birthday,
    otherSurnames,
    gender,
    nationality,
    address,
  } = profile;
  if (!(form.name.min < name.length && name.length < form.name.max)) {
    validationData.name = false;
  }
  if (!(form.surname.min < surname.length && surname.length < form.surname.max)) {
    validationData.surname = false;
  }
  if (!birthday || !moment(birthday).isBetween(form.birthday.min, form.birthday.max)) {
    validationData.birthday = false;
  }
  if (!(form.otherSurnames.min < otherSurnames.length
    && otherSurnames.length < form.otherSurnames.max)) {
    validationData.otherSurnames = false;
  }
  if (!form.gender.includes(gender)) {
    validationData.gender = false;
  }
  if (!countryCodes.includes(nationality)) {
    validationData.nationality = false;
  }
  if (!(form.street.min < address.street.length
    && address.street.length < form.street.max)) {
    validationData.street = false;
  }
  if (!(form.city.min < address.city.length
    && address.city.length < form.city.max)) {
    validationData.city = false;
  }
  if (!form.isNumber(address.cityCode)) {
    validationData.cityCode = false;
  }
  if (!form.isNumber(address.streetNumber)) {
    validationData.streetNumber = false;
  }
  if (!countryCodes.includes(address.country)) {
    validationData.country = false;
  }
  return validationData;
};

export default { personalDetails };
