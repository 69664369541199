import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import Header from 'components/header/Header';

export default class Component404 extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="card">
          <div style={{ margin: '10vh 10px 0', textAlign: 'left' }}>
            <div><strong><Translate i18nKey="missing-page.message" /></strong></div>
          </div>
        </div>
      </div>
    );
  }
}
