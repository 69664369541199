import React, { Component } from 'react';
import {
  DocumentTypes,
  IdDocumentSide,
  VerificationFlow,
  VibrationTunes,
  VideoStreamStatus,
  WhatToCapture,
  TimeoutActions,
} from 'resources/enums';
import { startProcess } from 'services/ProcessId.service';
import { fetch, requestFullscreen, generateKey } from 'utils';
import gaPageView from 'utils/GA';
import { error } from 'utils/log';
import { getFlow, getBlockingErrorCodes, vibrate } from 'utils/flowController';
import IdDocumentOverlay from 'components/idDocumentOverlay/IdDocumentOverlay';
import Frame from 'components/frame/Frame';
import IdDocumentScanInstructionsAuto from 'components/idDocumentScanInstructions/IdDocumentScanInstructionsAuto';
import IdDocumentScanInstructionsManual from 'components/idDocumentScanInstructions/IdDocumentScanInstructionsManual';
import SuccessPictogram from 'components/successPictogram/SuccessPictogram';
import IdDocumentScanTimeout from 'components/idDocumentScanTimeout/IdDocumentScanTimeout';
import CaptureImage from 'components/captureImage/CaptureImage';
import ManualCaptureMessage from 'components/manualCaptureMessage/ManualCaptureMessage';
import {
  ERROR_CODES_WITH_DESCRIPTION,
  CODES_TO_SKIP_MANUAL_CAPTURE,
  CODES_TO_SKIP_MANUAL_CAPTURE_FOR_SDK3,
} from 'resources/constants/errorCodes';

/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { IAppearance } from 'components/videoStream/VideoStream';
import { DoneStatus, EActionTypes } from 'types/enum';
import { IMotionData } from 'types/index';

enum Modes {
  auto = 'auto',
  manual = 'manual',
}

enum Statuses {
  wait = 'wait',
  instruction = 'instruction',
  overlay = 'overlay',
  success = 'success',
  timeout = 'timeout',
  // Auto capturing
  scan = 'scan',
  // Manual capturing
  capture = 'capture',
  confirmation = 'confirmation',
  check = 'check'
}
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */

const SCAN_TIMEOUT = 10 * 1000;

const OVERLAY_TIMEOUT = 3 * 1000;

const SUCCESS_CHECKMARK_TIMEOUT = 1000;

type Props = {
  onDone: (status: DoneStatus) => void,
  appState: any,
  changeData: any,
  documentType: DocumentTypes,
  addDVSLog: any,
  videoStream?: any,
};

type State = {
  status: Statuses,
  mode: Modes,
  side: IdDocumentSide,
  techMessage: string[],
  isMounted: boolean,
};

const getIdScanTechMessage = (errorCode: number): string => {
  return `${errorCode} (${ERROR_CODES_WITH_DESCRIPTION[`_${errorCode}`] || 'DGW No Description'})`;
};
export default class IdDocumentScan extends Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props, prevState: State): any {
    const { appState, videoStream } = nextProps;
    const { isOnline } = appState;
    if ((!isOnline || videoStream.status === VideoStreamStatus.stop)
      && IdDocumentScan.isAutoScanProcess(prevState)) {
      return {
        status: Statuses.instruction,
      };
    }
    return null;
  }

  static isAutoScanProcess = (state: State) => {
    const { mode, status } = state;
    return mode === Modes.auto
      && [Statuses.overlay, Statuses.scan].includes(status);
  };

  private scanStartTime: number;

  private lastResponse: any = {};

  private manualCaptured: any = {
    image: '',
    previewImage: '',
  };

  private idScanController: any;

  private motionData: Partial<IMotionData>[] = [];

  private frameCount = 0;

  constructor(props: any) {
    super(props);
    this.state = {
      status: Statuses.wait,
      mode: Modes.auto,
      side: IdDocumentSide.side1,
      techMessage: [''],
      isMounted: true,
    };
  }

  async componentDidMount() {
    const { appState } = this.props;
    const { collectMotionData } = appState.config;
    if (collectMotionData) {
      if (DeviceMotionEvent && typeof (DeviceMotionEvent as any).requestPermission === 'function') {
        (DeviceMotionEvent as any).requestPermission();
      }
      window.addEventListener('devicemotion', this.handleDeviceMotion);
    }
    try {
      requestFullscreen();

      const {
        addDVSLog,
        videoStream,
        documentType
      } = this.props;
      await videoStream.ensureVideoStream();

      const { oi = '' } = appState;
      await fetch(`/session${oi ? `?oi=${oi}` : ''}`, {
        method: 'POST',
      });

      const { side, isMounted } = this.state;
      gaPageView(`/${side}`);
      addDVSLog(`Document verification session started for document type: ${documentType}.`);

      const { processId } = appState.data || {};
      if (!processId) {
        await this.getProcessId();
      }

      if (isMounted) this.setState({ status: Statuses.instruction });
    } catch (err) {
      const { onDone } = this.props;
      const { message, stack } = (err || {}) as any;
      error(`Error when initializing auto capturing: message: ${message} stack: ${stack}`);
      onDone(DoneStatus.fail);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { side } = this.state;
    if (side !== prevState.side) {
      gaPageView(`/${side}`);
    }

    this.ensureVideoStreamAppearance(prevState);

    // Handle case when auto scan was interrupted
    const { status } = this.state;
    if (status === Statuses.instruction && IdDocumentScan.isAutoScanProcess(prevState)) {
      if (this.idScanController && this.idScanController.abort) this.idScanController.abort(); // Stop /idScan request
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('devicemotion', this.handleDeviceMotion);
    this.setState({ isMounted: false });
  }

  handleDeviceMotion = (event: DeviceMotionEvent) => {
    const {
      acceleration,
      accelerationIncludingGravity,
      rotationRate,
      timeStamp,
      interval,
    } = event;

    this.motionData.push({
      accX: acceleration?.x,
      accY: acceleration?.y,
      accZ: acceleration?.z,
      accGravX: accelerationIncludingGravity?.x,
      accGravY: accelerationIncludingGravity?.y,
      accGravZ: accelerationIncludingGravity?.z,
      gyroAlpha: rotationRate?.alpha,
      gyroBeta: rotationRate?.beta,
      gyroGamma: rotationRate?.gamma,
      timeRelativeMilli: timeStamp,
      interval: interval,
    });
  };

  ensureVideoStreamAppearance = (prevState: State) => {
    const { videoStream } = this.props;
    const { mode, status } = this.state;
    const { mode: prevMode, status: prevStatus } = prevState;

    const currentStateManualCapture = mode === Modes.manual && status === Statuses.capture;
    const prevStateManualCapture = prevMode === Modes.manual && prevStatus === Statuses.capture;

    if (currentStateManualCapture !== prevStateManualCapture) {
      if (currentStateManualCapture) {
        const appearance: IAppearance = { canvasClassNames: 'video-canvas-size', videoClassNames: 'video-canvas-size' };
        if (!videoStream.isLandscapeOrientation) {
          appearance.poweredByLogo = { changePosition: true };
        }
        videoStream.updateAppearance(appearance);
      } else {
        videoStream.updateAppearance({});
      }
    }
  };

  getProcessId = (): Promise<void> => (
    new Promise((resolve, reject) => {
      startProcess()
        .then((processId) => {
          const { changeData, addDVSLog } = this.props;
          addDVSLog(`Process ID: ${processId}.`);
          changeData({ processId });
          resolve();
        })
        .catch((err) => {
          const { onDone } = this.props;
          const { message, stack } = err;
          error(`Something went wrong while starting process: message: ${message} stack: ${stack}`);
          onDone(DoneStatus.fail);
          reject();
        });
    })
  );

  instructionOnClickHandler = () => {
    if (!document.fullscreenElement) requestFullscreen();

    this.setState({ status: Statuses.overlay });
    const { appState } = this.props;
    const { collectMotionData } = appState.config;
    if (collectMotionData) {
      if (this.motionData.length) {
        const lastActionIndex = this.motionData.length - 1;
        this.motionData[lastActionIndex].action = EActionTypes.instructionButtonClick;
      } else {
        this.motionData.push({ action: EActionTypes.instructionButtonClick });
      }
    }

    setTimeout(() => {
      const { mode } = this.state;
      if (mode === Modes.auto) {
        this.setState({ status: Statuses.scan }, () => {
          this.scanStartTime = new Date().valueOf();
          this.autoIdScan();
        });
      } else {
        this.setState({ status: Statuses.capture });
      }
    }, OVERLAY_TIMEOUT);
  };

  updateAppData = (fail = false) => {
    const { changeData } = this.props;
    const { side } = this.state;
    const { errorCode, resultXML } = this.lastResponse;
    const sideCount = side === IdDocumentSide.side2 ? 2 : 1;
    changeData({ verification: { errorCode, resultXML, sideCount, fail } });
  };

  showSuccessCheckmark = () => (new Promise((resolve) => {
    this.setState({ status: Statuses.success }, () => {
      setTimeout(() => {
        resolve(null);
      }, SUCCESS_CHECKMARK_TIMEOUT);
    });
  }));

  finishIdDocumentScan = () => {
    this.updateAppData();
    const { onDone } = this.props;
    onDone(null);
  };

  resetManualCaptureVideoAppearance = () => {
    const { videoStream } = this.props;
    videoStream.updateAppearance({
      canvasClassNames: '',
      videoClassNames: '',
      videoStyle: {},
      poweredByLogo: {},
    });
  };

  skipManualCaptureForSDK3 = () => {
    const { errorCode } = this.lastResponse;
    const code = Number(errorCode);
    return CODES_TO_SKIP_MANUAL_CAPTURE_FOR_SDK3.includes(code);
  };

  skipManualCaptureForSDK2 = () => {
    const { side } = this.state;
    const { documentType } = this.props;
    const { errorCode, resultXML = {}, images = {} } = this.lastResponse;
    const code = Number(errorCode);
    const { viz, mrz } = resultXML;
    const { faceImage, templateMathcingFailed } = images;
    const idIncompleteData: boolean = code === 222;
    if (idIncompleteData) return true;
    // Skip Manual capture for second side if document is expired or not in list
    if (documentType === DocumentTypes.paperPermit && side === IdDocumentSide.side2) {
      return CODES_TO_SKIP_MANUAL_CAPTURE.includes(code) && viz && faceImage;
    } else if (documentType === DocumentTypes.passport && side === IdDocumentSide.side1
      || documentType === DocumentTypes.id && side === IdDocumentSide.side2) {
      return CODES_TO_SKIP_MANUAL_CAPTURE.includes(code) && mrz && faceImage
        && (viz || !templateMathcingFailed);
    }
    return false;

  };

  needToSkipManualCapture = () => {
    const { resultXML = {} } = this.lastResponse;
    const { isSDK3 = false } = resultXML;
    return isSDK3 ? this.skipManualCaptureForSDK3() : this.skipManualCaptureForSDK2();
  };

  needToStopFlow = () => {
    const { side } = this.state;
    const { appState, documentType } = this.props;
    const { errorCode } = this.lastResponse;
    const { stopFlowIfBlockingError } = appState.config.modules.dv.idScan;
    const { errorCodes } = appState.config.flowRules.side1.retry;
    return stopFlowIfBlockingError && getBlockingErrorCodes(errorCodes).includes(errorCode)
      && (side === IdDocumentSide.side2 || documentType === DocumentTypes.passport);
  };

  handleAutoScanEnd = () => {
    const { side } = this.state;
    if (this.needToSkipManualCapture()) {
      if (this.needToStopFlow()) {
        this.setState({ status: Statuses.timeout });
      } else {
        const { documentType } = this.props;
        if (side === IdDocumentSide.side2 || documentType === DocumentTypes.passport) {
          this.finishIdDocumentScan();
        } else {
          this.setState({ side: IdDocumentSide.side2, status: Statuses.instruction });
        }
      }
    } else {
      this.setState({ mode: Modes.manual, status: Statuses.instruction });
    }
  };

  handleManualScanEnd = () => {
    const { side } = this.state;

    this.resetManualCaptureVideoAppearance();

    if (this.needToStopFlow()) {
      this.setState({ status: Statuses.timeout });
    } else {
      const { documentType } = this.props;
      if (side === IdDocumentSide.side2 || documentType === DocumentTypes.passport) {
        this.finishIdDocumentScan();
      } else {
        this.setState({
          side: IdDocumentSide.side2, mode: Modes.auto, status: Statuses.instruction,
        });
      }
    }
  };

  ensureFlow = async () => {
    const { side, techMessage } = this.state;
    const { documentType, onDone, addDVSLog } = this.props;
    const { errorCode, resultXML = {}, images } = this.lastResponse;
    const { faceImage, firstImage, templateMathcingFailed, secondImage } = images;
    const { mrz, barcode, isSDK3 } = resultXML;
    const mrzOrBarcode = mrz || barcode;
    const flow = getFlow(side, {
      errorCode,
      faceImage: !!faceImage,
      firstImage: !!firstImage,
      secondImage: !!secondImage,
      resultXML: !!resultXML,
      templateMathcingFailed: !!templateMathcingFailed,
      documentType,
      resJson: this.lastResponse, // For SDK error report
      mrzOrBarcode,
      isSDK3,
    });
    addDVSLog(
      `ID scan finished for ${side}, errorCode: ${errorCode}, faceImage: ${!!faceImage}, `
      + `firstImage: ${!!firstImage}, secondImage: ${!!secondImage}, resultXML: ${!!resultXML}, `
      + `mrzOrBarcode: ${!!mrzOrBarcode}, `
      + `${isSDK3 ? '' : `templateMathcingFailed: ${!!templateMathcingFailed}, `}flow: ${flow}.`,
    );

    // Tech message
    const techMessageString = getIdScanTechMessage(errorCode);
    techMessage.splice(0, 0, techMessageString);
    this.setState({ techMessage });

    switch (flow) {
      case VerificationFlow.side2:
        vibrate(VibrationTunes.success);
        await this.showSuccessCheckmark();
        this.setState({ side: IdDocumentSide.side2, mode: Modes.auto, status: Statuses.instruction }, () => {
          this.frameCount = 0;
          this.resetManualCaptureVideoAppearance();
        });
        break;
      case VerificationFlow.lfv:
        vibrate(VibrationTunes.success);
        await this.showSuccessCheckmark();
        this.finishIdDocumentScan();
        break;
      case VerificationFlow.retry: {
        const { mode } = this.state;
        if (mode === Modes.manual) {
          this.handleManualScanEnd();
        } else {
          this.autoIdScan(); // Try until timeout
        }
      } break;
      default:
        error(`Flow error: no match flow: ${flow}`);
        onDone(DoneStatus.fail);
        break;
    }
  };

  idScan = async () => {
    const { side, mode, techMessage } = this.state;

    let image: string;
    if (mode === Modes.auto) {
      const { videoStream } = this.props;
      image = videoStream.captureImage(WhatToCapture.idDocument).capturedImg;
    } else {
      image = this.manualCaptured.image;
    }

    const { appState, addDVSLog, onDone } = this.props;
    const { allowUseDataForML } = appState;

    let imageObj;
    if (side === IdDocumentSide.side1) {
      imageObj = { idImage1: image };
    } else {
      imageObj = { idImage2: image };
    }

    const postBody: any = {
      ...imageObj,
      isManualCapture: mode === Modes.manual ? true : false,
      allowUseDataForML,
    };

    const { collectMotionData } = appState.config;
    if (collectMotionData) {
      const frameName = mode === Modes.auto ?
        `imageFrame${this.frameCount + 1}` :
        'imageFrameManual';
      if (this.motionData.length) {
        const lastActionIndex = this.motionData.length - 1;
        this.motionData[lastActionIndex].action = frameName;
      } else {
        this.motionData.push({ action: frameName });
      }
      postBody.motionData = this.motionData;
      this.motionData = [];
    }

    addDVSLog(`ID scan started for ${side}, mode: ${mode}.`);
    try {
      this.idScanController = new AbortController();
      const { signal } = this.idScanController;
      this.frameCount += 1;
      const res = await fetch('/idscan', { method: 'POST', body: JSON.stringify(postBody), signal });
      const resJson = await res.json(); // TODO: move to utils fetch
      this.lastResponse = resJson;
      this.ensureFlow();
      return await Promise.resolve(true);
    } catch (err) {
      const { message, stack } = (err || {}) as any;
      error(`IdScan error: side: ${side} message: ${message} stack: ${stack}`);
      this.lastResponse = {};

      if (message === 'Wrong time') return onDone(DoneStatus.wrongTime);

      addDVSLog(`Internal Server Error during ID scan for ${side}.`);

      // Tech message
      techMessage.splice(0, 0, 'Server error');
      this.setState({ techMessage });

      return Promise.reject(err);
    }
  };

  autoIdScan = async () => {
    const { status } = this.state;
    if (status !== Statuses.scan) return;

    const isTimeouted = (new Date().valueOf() - this.scanStartTime) > SCAN_TIMEOUT;
    if (isTimeouted) {
      this.handleAutoScanEnd();
      return;
    }

    try {
      await this.idScan();
    } catch (err) {
      this.autoIdScan(); // Try until timeout
    }
  };

  manualIdScan = async () => {
    try {
      await this.idScan();
    } catch (err) {
      this.handleManualScanEnd();
    }
  };

  manualScanRecaptureHandler = () => {
    const { videoStream, appState } = this.props;
    const { collectMotionData } = appState.config;
    videoStream.updateAppearance({ videoStyle: {}, poweredByLogo: {} });
    if (collectMotionData) {
      if (this.motionData.length) {
        const lastActionIndex = this.motionData.length - 1;
        this.motionData[lastActionIndex].action = EActionTypes.recapture;
      } else {
        this.motionData.push({ action: EActionTypes.recapture });
      }
    }
    this.setState({ status: Statuses.capture }, () => {
      /**
       * The camera is frozen when switching from landscape to portrait mode when recapture.
       * More https://pxlvision.atlassian.net/browse/PID-173.
       */
      videoStream.stopVideoStream();
      videoStream.startVideoStream();
    });
  };

  timeoutActionHandler = (action: TimeoutActions) => {
    const { onDone } = this.props;
    switch (action) {
      case TimeoutActions.reset:
        this.lastResponse = {};
        this.updateAppData();
        this.setState({
          mode: Modes.auto, side: IdDocumentSide.side1, status: Statuses.instruction,
        });
        break;
      case TimeoutActions.exit: {
        this.updateAppData(true);
        onDone(DoneStatus.exit);
      } break;
      default:
    }
  };

  manualCaptureHandler = () => {
    const { videoStream, appState } = this.props;
    const { collectMotionData } = appState.config;

    const { capturedImg, previewImage } = videoStream.captureImage(WhatToCapture.idDocument);
    this.manualCaptured.image = capturedImg;
    this.manualCaptured.previewImage = previewImage ? previewImage : capturedImg;

    videoStream.updateAppearance({ videoStyle: { display: 'none' }, poweredByLogo: { hide: true } });
    if (collectMotionData) {
      if (this.motionData.length) {
        const lastActionIndex = this.motionData.length - 1;
        this.motionData[lastActionIndex].action = EActionTypes.manualCapture;
      } else {
        this.motionData.push({ action: EActionTypes.manualCapture });
      }
    }

    this.setState({ status: Statuses.confirmation });
  };

  getIdDocumentContent = () => {
    const { documentType } = this.props;
    const { mode, status, side } = this.state;
    let content;

    switch (`${mode}-${status}`) {
      case `${Modes.auto}-${Statuses.instruction}`:
        content = (
          <Frame>
            <IdDocumentScanInstructionsAuto
              documentType={documentType}
              side={side}
              onClickHandler={this.instructionOnClickHandler}
            />
          </Frame>
        );
        break;
      case `${Modes.manual}-${Statuses.instruction}`:
        content = (
          <Frame>
            <IdDocumentScanInstructionsManual onClickHandler={this.instructionOnClickHandler} />
          </Frame>
        );
        break;
      case `${Modes.auto}-${Statuses.overlay}`:
      case `${Modes.manual}-${Statuses.overlay}`:
        content = (
          <>
            <Frame />
            <IdDocumentOverlay
              documentType={documentType}
              side={side}
            />
          </>
        );
        break;
      case `${Modes.auto}-${Statuses.success}`:
      case `${Modes.manual}-${Statuses.success}`:
        content = (
          <SuccessPictogram />
        );
        break;
      case `${Modes.auto}-${Statuses.timeout}`:
      case `${Modes.manual}-${Statuses.timeout}`:
        content = (
          <Frame>
            <IdDocumentScanTimeout callback={this.timeoutActionHandler} />
          </Frame>
        );
        break;
      case `${Modes.auto}-${Statuses.scan}`:
        content = (
          <Frame classNames="animatable" />
        );
        break;
      case `${Modes.manual}-${Statuses.capture}`:
        content = (
          <CaptureImage callback={this.manualCaptureHandler} />
        );
        break;
      case `${Modes.manual}-${Statuses.confirmation}`: {
        content = (
          <ManualCaptureMessage
            image={this.manualCaptured.previewImage}
            continueCallback={this.manualIdScan}
            recaptureCallback={this.manualScanRecaptureHandler}
          />
        );
      } break;
      default:
        break;
    }

    return content;
  };

  render() {
    const { techMessage } = this.state;
    let techMessageHtml: any = '';

    if (process.env.REACT_APP_ENV === 'DEV' || process.env.REACT_APP_ENV === 'QA') {
      techMessageHtml = techMessage.map((tmsg) => (
        <span key={generateKey()}>
          {tmsg}
          <br />
        </span>
      ));
    }

    return (
      <>
        <div className="tech-message">{techMessageHtml}</div>
        {this.getIdDocumentContent()}
      </>
    );
  }
}
