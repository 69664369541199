import React from 'react';
import { Helmet } from 'react-helmet';
import 'App.scss';
import i18n from 'i18next';
import 'configs/i18n.config';
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import {
  IBrowser,
  IData,
  IHistory,
  ISettings,
  IState,
  IURLParams,
  IAppConfig,
  TStateData,
  TStateChanger,
} from 'types/index.d';
import {
  Modules,
  Roles,
  SelfNavigationActions,
} from 'types/enum';
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */
import appConfig from 'resources/branding/appconfig.json';
import appMetadata from 'resources/branding/appMetadata.json';
import ModuleManager from 'modules/Manager';
import {
  desktopIsNotSupported,
  deviceIsSupported,
  getOrientation,
  getUA,
  handleFetchErrors,
  deepMerge,
  mobileAndTabletCheck,
  fetch,
} from 'utils';
import { error, warn } from 'utils/log';
import Loader from 'components/loader/Loader';
import NoInternetConnection from 'components/noInternetConnection/NoInternetConnection';
import * as serviceWorker from 'serviceWorker';

export const defaultState: IState = {
  browser: {
    orientation: getOrientation(),
    isOnline: navigator.onLine,
    userAgent: getUA(),
  },
  settings: {
    allowUseDataForML: false,
    TaC: false,
  },
  history: {},
  data: {
    isSecondTry: false,
    selectedType: '',
    verification: {},
    docBackSide: '',
    additionalDoc: '',
    terms: [],
    dvsLogs: [],
    serviceProviderData: null,
    processId: null,
    personalDetails: {
      address: {
        street: '',
        streetNumber: '',
        city: '',
        cityCode: '',
        country: '',
      },
      birthday: '',
      canGo: false,
      gender: 'male',
      name: '',
      nationality: '',
      otherSurnames: '',
      surname: '',
    },
    mtan: {
      email: '',
      phone: '',
      countryCode: 'CH',
    },
    dvFailed: false,
  },
  session: {},
  appConfig,
  urlParams: {},
  paramAppConfig: {},
};

function setLanguage(lang = '') {
  const { lang: langConfig } = appConfig;
  const langParamsWhitelist: string[] = langConfig.langs || [];
  const tmpLang: string = lang.toLowerCase();
  if (tmpLang && langParamsWhitelist.includes(tmpLang)) {
    i18n.changeLanguage(tmpLang);
  }
}

function getUrlParams(): IURLParams {
  const urlParams: any = {};
  const params: any = (new URL((window as any).location)).searchParams;
  params.forEach((value: string, key: string) => {
    urlParams[key] = value;
  });

  return urlParams;
}

function getUseDataForMl(urlParams: any) {
  const { modules, allowShareDataForML } = appConfig;
  const { terms: termsView } = modules;
  const { isCheckBoxEnabled, defaultValue } = allowShareDataForML;
  const { userConsentDP } = urlParams;
  if (userConsentDP) {
    if (userConsentDP.toLowerCase() === 'yes') return true;
    if (userConsentDP.toLowerCase() === 'no') return false;
  }
  return termsView.enabled && isCheckBoxEnabled ? false : defaultValue;
}

export default class App extends React.Component<Record<string, never>, IState> {
  private selfNavigationAction: SelfNavigationActions = null;

  constructor(props: null) {
    super(props);
    this.initErrorLogging();
    const { filtered, original } = this.getViewsList();
    const params = getUrlParams();
    const {
      tc,
      appConfig: paramAppConfig,
      oi,
      lang = '',
    } = params;

    setLanguage(lang);
    defaultState.appConfig.lang.currentLang = i18n.language;

    const state = {
      ...defaultState,
      settings: {
        ...defaultState.settings,
        allowUseDataForML: getUseDataForMl(params),
      },
      history: {
        views: filtered,
        originalViews: original,
      },
      tc,
      oi,
      paramAppConfig,
      urlParams: params,
    };
    this.state = state;
  }

  componentDidMount(): void {
    window.addEventListener('orientationchange', this.setOrientation);
    window.addEventListener('focus', this.setOrientationOnFocus);
    window.addEventListener('online', this.toogleInternetConnectionStatus);
    window.addEventListener('offline', this.toogleInternetConnectionStatus);
    window.onpopstate = this.popStateHandler;
    serviceWorker.register({ onUpdate: this.onServiceWorkerUpdate });
    this.initializeHistory();
    this.getSession();
  }

  componentWillUnmount(): void {
    window.removeEventListener('orientationchange', this.setOrientation);
    window.removeEventListener('focus', this.setOrientationOnFocus);
    window.removeEventListener('online', this.toogleInternetConnectionStatus);
    window.removeEventListener('offline', this.toogleInternetConnectionStatus);
  }

  onServiceWorkerUpdate = (): void => {
    const { history } = this.state;
    const newHistory: IHistory = { ...history };
    newHistory.view = Modules.newVersionIsAvailable;
    this.setState({ history: newHistory });
  };

  popStateHandler = (): void => {
    switch (this.selfNavigationAction) {
      case SelfNavigationActions.pushNewHistory:
        this.pushHistory(Roles.firstPage);
        this.selfNavigationAction = null;
        break;
      case SelfNavigationActions.doNothing:
        this.selfNavigationAction = null;
        break;
      case SelfNavigationActions.goToFirstPage: {
        this.selfNavigationAction = null;
        const { urlParams } = this.state;
        this.changeData({ verification: {} });
        this.changeHistory({ prevView: null, view: Modules.transactioncode });
        this.changeSettings({ allowUseDataForML: getUseDataForMl(urlParams), TaC: false });
        this.pushHistory(Roles.firstPage);
        break;
      }
      default:
        this.historyGo(-1, SelfNavigationActions.goToFirstPage);
        break;
    }
  };

  setOrientationOnFocus = (): void => {
    // set actual orientation in next tick as immediate after focusing orientation isn't recognized
    // as landscape
    setTimeout(() => {
      this.setOrientation();
    }, 1);
  };

  setOrientation = (): void => {
    this.changeBrowser({ orientation: getOrientation() });
  };

  toogleInternetConnectionStatus = (): void => {
    this.changeBrowser({ isOnline: navigator.onLine });
  };

  pushHistory = (role: Roles = null): void => {
    const { history: { view } } = this.state;

    // Push state into the browser history
    window.history.pushState({
      view,
      role,
    }, null);
  };

  replaceHistory = (): void => {
    const { history: { view } } = this.state;
    window.history.replaceState({
      view,
    }, null);
  };

  historyGo = (step: number, selfNavigationAction: SelfNavigationActions = null): void => {
    this.selfNavigationAction = selfNavigationAction;
    window.history.go(step);
  };

  sessionHandler = (session: any): void => { // TO WATCH
    const {
      urlParams,
      paramAppConfig,
      history,
      settings,
    } = this.state;
    const { translate } = urlParams;
    const newHistory: IHistory = { ...history };
    const newSettings: ISettings = { ...settings };

    newSettings.exitURL = this.getExitUrl();
    this.changeSettings(newSettings);

    if (process.env.REACT_APP_ENV === 'DEV' || process.env.REACT_APP_ENV === 'QA') {
      const { appConfig: stateConfig } = this.state;
      const newAppConfig = this.mergeConfigs(stateConfig, paramAppConfig);
      this.changeAppConfig(newAppConfig);
      const { filtered, original } = this.getViewsList();
      newHistory.views = filtered;
      newHistory.originalViews = original;
    }

    if (desktopIsNotSupported(translate)) {
      newHistory.view = Modules.desktopNotSupported;
    } else if (!deviceIsSupported()) {
      newHistory.view = Modules.deviceNotSupported;
    } else {
      newHistory.view = Modules.transactioncode;
    }

    this.setState({ session, history: newHistory });
  };

  mergeConfigs = (config: any, urlConfig: any): any => {
    let configForTest: any = {};
    if (urlConfig) {
      try {
        configForTest = JSON.parse(urlConfig);
      } catch (err: any) {
        if (err) warn(`Error parsing JSON: ${err}`);
      }

      if (Object.keys(configForTest).length !== 0) {
        const keysToRemove: string[] = [
          'flowRules',
        ];
        keysToRemove.forEach((key: string) => {
          delete configForTest[key];
        });
      }
    }

    return deepMerge(config, configForTest);
  };

  getSession = async (): Promise<any> => {
    fetch('/session')
      .then(handleFetchErrors)
      .then(async (result: any) => {
        let data = {};
        try {
          data = await result.json();
        } catch (err: any) {
          const { message, stack } = err;
          error(`Error with the parsing recieved json from server. Message: ${message} Stack: ${stack}`);
          data = {};
        }
        return data;
      })
      .then(this.sessionHandler)
      .catch((err: any) => {
        const { message, stack } = err;
        error(`Something went wrong while getting session: message: ${message} stack: ${stack}`);
        this.setState({ session: {} });
      });
  };

  changeBrowser = (mergeState: TStateChanger<IBrowser>): void => {
    this.setState((prevState: IState) => ({
      browser: {
        ...prevState.browser,
        ...typeof mergeState === 'function' ? mergeState(prevState) : mergeState,
      },
    }));
  };

  changeHistory = (mergeState: TStateChanger<IHistory>): void => {
    this.setState((prevState: IState) => ({
      history: {
        ...prevState.history,
        ...typeof mergeState === 'function' ? mergeState(prevState) : mergeState,
      },
    }), () => {
      if (window.history.state?.role === Roles.firstPage) {
        this.pushHistory();
      } else {
        this.replaceHistory();
      }
    });
  };

  changeSettings = (mergeState: TStateChanger<ISettings>): void => {
    this.setState((prevState: IState) => ({
      settings: {
        ...prevState.settings,
        ...typeof mergeState === 'function' ? mergeState(prevState) : mergeState,
      },
    }));
  };

  changeData = (mergeState: TStateChanger<IData>): void => {
    this.setState((prevState: IState) => ({
      data: {
        ...prevState.data,
        ...typeof mergeState === 'function' ? mergeState(prevState) : mergeState,
      },
    }));
  };

  changeAppConfig = (mergeState: TStateChanger<any>): void => {
    this.setState((prevState: IState) => ({
      appConfig: {
        ...prevState.appConfig,
        ...typeof mergeState === 'function' ? mergeState(prevState) : mergeState,
      },
    }));
  };

  changeState = (key: keyof IState, data: TStateData): void => {
    this.setState((prevState: IState) => {
      const newProp = { ...prevState[key], ...data };
      return { ...prevState, [key]: newProp };
    });
  };

  setLanguage = (lang: string): void => {
    setLanguage(lang);
    this.changeAppConfig((prevState) => ({
      lang: {
        ...prevState.appConfig.lang,
        currentLang: lang,
      }
    }));
  };

  addDVSLog = (log: string): void => {
    const { data } = this.state;
    const { dvsLogs } = data;
    const date: string = new Date().toISOString();
    dvsLogs.push(`${date} ${log}`);
    this.changeData({ dvsLogs });
  };

  getViewsList = (): any => {
    const { modules } = appConfig;
    return Object.keys(modules).reduce((acc: any, key: string) => {
      acc.original.push(Modules[key as keyof typeof Modules]);
      if (modules[key as keyof typeof modules].enabled) {
        acc.filtered.push(Modules[key as keyof typeof Modules]);
      }
      return acc;
    }, { original: [], filtered: [] });
  };

  getExitUrl = (): string => {
    const { appConfig: { urls: { exit } = {} as any } } = this.state;
    return exit;
  };

  leaveApp = (exitOnError = false): void => {
    const { settings: { exitURL } } = this.state;
    let url: string = exitURL;
    if (exitOnError) {
      const parsedURL = new URL(exitURL);
      const { searchParams } = parsedURL;
      searchParams.append('closed', 'true');
      url = parsedURL.href;
    }
    window.open(url, '_self');
  };

  getDynamicContentOfHeader = (): React.ReactNode => {
    const { appConfig: viewConfig } = this.state;
    const { metaTags = [], urls, brand } = viewConfig;
    const fontsHref: string = urls.fonts.href;

    return (
      <Helmet defer={false}>
        <link href={fontsHref} rel="stylesheet" />
        <title>{brand.title}</title>
        {metaTags.map((metaTag: any) => (
          <meta
            key={metaTag.name}
            name={metaTag.name}
            content={metaTag.content}
          />
        ))}
      </Helmet>
    );
  };

  getCrowdinContent = (): React.ReactNode | string => {
    const { urlParams = {} } = this.state;
    const { translate } = urlParams;
    if (process.env.REACT_APP_ENV !== 'CROWDIN' || !translate) return '';

    /* eslint-disable */
    const { crowdinProjectID = '' } = appMetadata;
    (window as any)._jipt = [];
    (window as any)._jipt.push(['project', crowdinProjectID]);

    return (
      <Helmet defer={false}>
        <script type="text/javascript" src="//cdn.crowdin.com/jipt/jipt.js" />
      </Helmet>
    );
    /* eslint-enable */
  };

  initErrorLogging(): void {
    window.onerror = (err) => {
      error(`Unhandled error: message: ${err}`);
    };
  }

  initializeHistory(): void {
    const { state } = window.history;

    if (!state) {
      // Case when user opens the application in the new tab of browser.
      window.history.replaceState({
        view: null,
        role: Roles.placeholder,
      }, null);
      this.pushHistory(Roles.firstPage);
      return;
    }

    switch (state.role) {
      case Roles.placeholder:
        // Case when user backs to the application through forward button of browser.
        this.historyGo(1, SelfNavigationActions.doNothing);
        break;
      case Roles.firstPage:
        // Case when user just refreshes the first page, do nothing.
        break;
      default:
        // Case when user refreshes the any page except first one.
        this.historyGo(-2, SelfNavigationActions.pushNewHistory);
        break;
    }
  }

  render(): React.ReactNode {
    const {
      browser: { orientation, isOnline },
      history: { view },
      appConfig: stateConfig,
    } = this.state;
    const device = mobileAndTabletCheck() ? 'mobile' : 'desktop';
    const className = `App ${orientation} ${device}`;
    let content: any;
    if (!view) {
      content = (
        <div className="loader-overlay">
          <Loader />
        </div>
      );
    } else {
      content = (
        <ModuleManager
          appState={this.state}
          changeHistory={this.changeHistory}
          changeSettings={this.changeSettings}
          changeData={this.changeData}
          changeAppConfig={this.changeAppConfig}
          changeState={this.changeState}
          addDVSLog={this.addDVSLog}
          setLang={(lang: string) => this.setLanguage(lang)}
          leaveApp={this.leaveApp}
          getViewsList={this.getViewsList}
        />
      );
    }
    return (
      <div className={className}>
        {this.getDynamicContentOfHeader()}
        {this.getCrowdinContent()}
        <section className={`${!isOnline && 'no-internet-connection'}`}>
          {
            !isOnline && (
              <NoInternetConnection
                leaveApp={this.leaveApp}
                closeButtonOnErrorScreens={stateConfig.closeButtonOnErrorScreens}
              />
            )
          }
          {content}
        </section>
      </div>
    );
  }
}
