import React from 'react';
import SelectType from 'modules/documentVerification/views/selectType/SelectType';
import IdDocumentScan from 'modules/documentVerification/views/idDocumentScan/IdDocumentScan';

/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { IAppConfig, IData } from 'types/index.d';
import { DocumentTypes, DoneStatus, Orientation } from 'types/enum';
import Header from 'components/header/Header';
import VideoStream from 'components/videoStream/VideoStream';
import DocumentBackSide from './views/documentBackSide/DocumentBackSide';
import DocumentRecording from './views/documentRecording/DocumentRecording';

enum Views {
  selectType = 'SELECTTYPE',
  idScan = 'IDSCAN',
  docBackSide = 'DOCBACKSIDE',
  docRecording = 'DOCRECORDING',
}
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */

interface IProps {
  onDone: (status: DoneStatus) => void;
  addDVSLog: (data: string) => void;
  changeData: (data: Partial<IData>) => void;
  appState: {
    config: IAppConfig;
    data: IData;
    viewsList: string[];
    orientation: Orientation;
    isOnline: boolean;
    session: any;
    allowUseDataForML: boolean;
    oi?: string,
  };
}

interface IState {
  view: Views;
  documentType: DocumentTypes;
}

export default class DocumentVerification extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    const { appState } = this.props;
    const { selectTypeOptions } = appState.config;
    const { selectType } = appState.config.modules.dv;
    const availableDocTypes: string[] = Object.keys(selectTypeOptions)
      .filter((key) => selectTypeOptions[key]);

    if (!availableDocTypes.length) {
      return { view: Views.idScan, documentType: DocumentTypes.id };
    }

    if (!selectType || availableDocTypes.length === 1) {
      const documentType = DocumentTypes[availableDocTypes[0] as keyof typeof DocumentTypes];
      return { view: Views.idScan, documentType };
    }

    return { view: Views.selectType, documentType: null };
  };

  swissPassportOrPaperPermit = (): boolean => {
    const { appState } = this.props;
    const { documentType } = this.state;
    const { data } = appState;
    const { resultXML } = data.verification;
    const { documentType: docType = '', permitFormat = '', documentCountry = '' } = resultXML;

    const isSwissDoc: boolean = documentCountry === 'CHE' && (docType === 'passport' || (docType === 'permit' && permitFormat === 'paper'));

    return (isSwissDoc || documentType === DocumentTypes.paperPermit);
  };

  selectTypeCallback = (documentType: DocumentTypes) => {
    this.setState({ view: Views.idScan, documentType }, () => {
      const { changeData } = this.props;
      changeData({ selectedType: documentType });
    });
  };

  onDone = (status: DoneStatus = null) => {
    const { onDone } = this.props;

    if ([DoneStatus.exit, DoneStatus.fail, DoneStatus.wrongTime].includes(status)) {
      onDone(status);
      return;
    }

    const { view } = this.state;
    const { appState } = this.props;
    const { config } = appState;
    if (view === Views.idScan) {
      const { docBackSide } = config.modules.dv;
      if (docBackSide && this.swissPassportOrPaperPermit()) {
        this.setState({ view: Views.docBackSide });
        return;
      }
    }

    const { docRecord } = config.modules.dv;
    if ([Views.idScan, Views.docBackSide].includes(view) && docRecord) {
      this.setState({ view: Views.docRecording });
      return;
    }

    onDone(null);
  };

  render() {
    const {
      appState,
      addDVSLog,
      changeData,
    } = this.props;
    const { view, documentType } = this.state;
    let content: any = '';
    let className = '';
    if (view === Views.selectType) {
      className = 'comp-select-type';
      content = (
        <SelectType
          selectTypeOptions={appState.config.selectTypeOptions}
          selectTypeCallback={this.selectTypeCallback}
        />
      );
    } else {
      let innerContent: any = '';
      switch (view) {
        case Views.idScan:
          innerContent = (
            <IdDocumentScan
              onDone={this.onDone}
              appState={appState}
              changeData={changeData}
              documentType={documentType}
              addDVSLog={addDVSLog}
            />
          );
          break;
        case Views.docBackSide:
          innerContent = (
            <DocumentBackSide
              onDone={this.onDone}
              changeData={changeData}
              documentType={documentType}
              addDVSLog={addDVSLog}
            />
          );
          break;
        case Views.docRecording:
          innerContent = (
            <DocumentRecording
              onDone={this.onDone}
              addDVSLog={addDVSLog}
              documentType={documentType}
              appState={appState}
            />
          );
          break;

        default:
          break;
      }

      className = 'comp-dv';
      content = (
        <VideoStream appState={appState}>
          {innerContent}
        </VideoStream>
      );
    }

    return (
      <div className={className}>
        <Header />
        {content}
      </div>
    );
  }
}
