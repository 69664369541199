import React, { Component } from 'react';
import PhoneInput, { Props as PhoneInputProps } from 'react-phone-number-input'; // eslint-disable-line
import Translate from 'components/translate/Translate';

interface IProps {
  mainProps: {
    label: string;
    changeState: (obj: { [key:string]: string }) => void;
    error: boolean;
    errorText: string;
    name?: string;
    className?: string;
  };
  inputProps: Omit<PhoneInputProps, 'onChange'>;
}

export default class PhoneInputContent extends Component<IProps> {
  render(): React.ReactNode {
    const {
      mainProps,
      inputProps,
    } = this.props;
    const {
      label,
      changeState,
      name,
      error,
      errorText,
      className,
    } = mainProps;
    const defaultClassNames = ['custom-input', 'phone-input'];

    return (
      <div className={[...defaultClassNames, className, ...(error ? ['error-input'] : [])].join(' ')}>
        <label><Translate i18nKey={label} /></label>
        <PhoneInput
          className="input-container"
          onChange={(value: any) => {
            changeState({ [(name || 'phone') as string]: value || '' });
          }}
          {...inputProps}
        />
        {false && error && <div className="input-error phone-input-error"><Translate i18nKey={errorText} /></div>}
      </div>
    );
  }
}
