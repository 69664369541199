import React, { Component } from 'react';
import SignPage from 'modules/contract/views/signPage/SignPage';
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import {
  IMtan,
  ISignedContract,
} from 'types/index.d';
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */

interface IProps {
  currentLanguage: string;
  processId: string;
  transaction: {
    code: string;
  };
  mtan: IMtan;
  firstName: string;
  lastName: string;
  onDone: (data: any) => void;
}

class Contract extends Component<IProps> {
  onDone = (data: ISignedContract) => {
    const { onDone } = this.props;
    onDone(data);
  };

  render() {
    const {
      transaction,
      processId,
      mtan,
      firstName,
      lastName,
    } = this.props;
    return (
      <SignPage
        processId={processId}
        transactionCode={transaction.code}
        mtan={mtan}
        firstName={firstName}
        lastName={lastName}
        onDone={this.onDone}
      />
    );
  }
}

export default Contract;
