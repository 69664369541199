import React, { Component } from 'react';
import { ReactComponent as Pic } from 'assets/images/picto-success.svg';
// import { WhatToScan } from 'types/enum';

type Props = {
  classNames?: string,
};

export default class SuccessPictogram extends Component<Props> {
  render() {
    const { classNames = '' } = this.props;

    return (
      <div className={`comp-success-pictogram ${classNames}`}>
        <div className="pictogram">
          <Pic className="pic" />
        </div>
      </div>
    );
  }
}
