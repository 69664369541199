import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import Header from 'components/header/Header';
import Loader from 'components/loader/Loader';

interface IProps {
  transactionData: any;
  sendVerification: () => void;
}

interface IState {
  loading: boolean;
  retry: boolean;
}

export default class VerifyCodeStart extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      retry: false,
    };
  }

  requestCode = () => {
    const { sendVerification } = this.props;
    this.setState({ loading: true, retry: false }, async () => {
      try {
        await sendVerification();
      } catch (e: any) {
        this.setState({ loading: false, retry: true });
      }
    });
  };

  render() {
    const { transactionData } = this.props;
    const { loading, retry } = this.state;
    return (
      <div className="comp-verify-code-start">
        <Header />
        <div className="card">
          <div className="main-content">

            <div>
              <h1 className="welcome"><Translate i18nKey="verify-code.title" /></h1>
              <div className="verify-code-text">
                <Translate
                  i18nKey={`verify-code.body-text-${transactionData.mtan.default}`}
                />
              </div>
            </div>
          </div>
          <div className="button-with-loader">
            <div
              className={`button-big ${loading ? 'inactive' : ''}`}
              role="button"
              onClick={() => this.requestCode()}
              onKeyPress={() => this.requestCode()}
              tabIndex={-1}
            >
              <span><Translate i18nKey={`verify-code.${retry ? 'retry' : 'button-continue'}`} /></span>
              {loading && <Loader />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
