import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import SimpleInput from 'components/inputs/SimpleInput';
import Header from 'components/header/Header';
import FormAddress from 'components/formAddress/FormAddress';
import SelectBox from 'components/inputs/SelectBox';

/* eslint-disable no-unused-vars */
import { IProfile } from 'types/index.d';
import validator from 'utils/validator';
import countryCodes from 'resources/data/countryCodes';
import i18n from 'configs/i18n.config';

interface IProps {
  onDone: (state: IProfile) => void;
}

const genderOptions = [{
  label: i18n.t('personal-details.male'),
  value: 'male',
}, {
  label: i18n.t('personal-details.female'),
  value: 'female',
}, {
  label: i18n.t('personal-details.other'),
  value: 'other',
}];

/* eslint-enable no-unused-vars */

export default class SelfDeclaration extends Component<IProps, IProfile> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      name: '',
      surname: '',
      otherSurnames: '',
      birthday: '',
      gender: 'male',
      address: {
        street: '',
        streetNumber: '',
        city: '',
        cityCode: '',
        country: '',
      },
      nationality: '',
      canGo: false,
    };
  }

  changeState = (state: any) => {
    const data = { ...this.state, ...state };
    const validationData = validator.personalDetails(data);
    data.canGo = this.isValid(validationData);
    this.setState(data);
  };

  // Temporary for Demo, will be updated to use another package for form validation
  isValidKey = (data: any, key: string): boolean => !!data[key];

  /// TODO: uncomment on self declaration integration
  isValid = (data: any): boolean => this.isValidKey(data, 'name')
    && this.isValidKey(data, 'surname');
  // && this.isValidKey(data, 'nationality')
  // && this.isValidKey(data, 'otherSurnames')
  // && this.isValidKey(data, 'birthday')
  // && this.isValidKey(data, 'gender')
  // && this.isValidKey(data, 'street')
  // && this.isValidKey(data, 'streetNumber')
  // && this.isValidKey(data, 'city')
  // && this.isValidKey(data, 'cityCode')
  // && this.isValidKey(data, 'country');

  continue = () => {
    const { onDone } = this.props;
    onDone(this.state);
  };

  render() {
    const {
      name,
      surname,
      otherSurnames,
      birthday,
      address,
      canGo,
    } = this.state;
    const validationData = validator.personalDetails(this.state);
    return (
      <div className="personal-details-page">
        <Header />
        <div className="card">
          <div className="card-header">
            <h1><Translate i18nKey="personal-details.title" /></h1>
            <hr />
          </div>
          <div className="card-body">
            <section className="credentials">
              <div className="section-container credentials-container">
                <SimpleInput
                  label="personal-details.name"
                  type="text"
                  changeState={this.changeState}
                  value={name}
                  name="name"
                  error={!this.isValidKey(validationData, 'name')}
                  errorText="personal-details.name-error"
                  className="name-line"
                />
                <SimpleInput
                  label="personal-details.family-name"
                  type="text"
                  changeState={this.changeState}
                  value={surname}
                  name="surname"
                  error={!this.isValidKey(validationData, 'surname')}
                  errorText="personal-details.family-name-error"
                  className="surname-line"
                />
                <SimpleInput
                  label="personal-details.other-surnames"
                  type="text"
                  changeState={this.changeState}
                  value={otherSurnames}
                  name="otherSurnames"
                  error={!this.isValidKey(validationData, 'otherSurnames')}
                  errorText="personal-details.other-surnames-error"
                  className="other-surnames-line"
                />
                <SimpleInput
                  label="personal-details.birthday"
                  type="date"
                  changeState={this.changeState}
                  value={birthday}
                  name="birthday"
                  error={!this.isValidKey(validationData, 'birthday')}
                  errorText="personal-details.birthday-error"
                  className="birthday-line"
                />
              </div>
            </section>
            <FormAddress
              data={address}
              changeState={this.changeState}
              isValidKey={this.isValidKey}
              validationData={validationData}
            />
            <section className="additional-info">
              <div className="section-container additional-info-container">
                <SelectBox
                  label="personal-details.nationality"
                  name="nationality"
                  options={countryCodes.map((code) => ({
                    value: code,
                    label: i18n.t([`nationalities.${code}`]),
                  }))}
                  changeState={this.changeState}
                  error={!this.isValidKey(validationData, 'nationality')}
                  errorText="personal-details.nationality-error"
                  className="nationality-line"
                />
                <SelectBox
                  label="personal-details.gender"
                  name="gender"
                  options={genderOptions}
                  changeState={this.changeState}
                  error={false}
                  errorText="personal-details.gender-error"
                  className="gender-line"
                  inputProps={{
                    defaultValue: genderOptions[0],
                  }}
                />
              </div>
            </section>
          </div>
          <div className="card-footer">
            <div className="continue-button">
              <button
                className={`button-big overlay-button ${!canGo ? 'inactive' : ''}`}
                type="button"
                disabled={!canGo}
                onClick={this.continue}
              >
                <Translate i18nKey="personal-details.continue" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
