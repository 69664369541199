import React, { Component } from 'react';
import parse from 'html-react-parser';
import { Trans } from 'react-i18next';
import i18n from 'configs/i18n.config';

type Props = {
  i18nKey: string,
};

class Translate extends Component<Props> {
  getCorrectedTranslation = () => {
    const { i18nKey } = this.props;
    let initialTranslation = i18n.t(i18nKey);
    const linksNodes = [];
    let linkIndex = 0;
    while (initialTranslation.includes('</a>')) {
      const linkStartIndex = initialTranslation.indexOf('<a');
      const linkEndIndex = initialTranslation.indexOf('a>') + 2;
      let link = initialTranslation.slice(linkStartIndex, linkEndIndex);
      // Add classname on link, to give to it general link style
      link = `${link.slice(0, 2)} class="translation-link" ${link.slice(2)}`;
      linksNodes.push(parse(link));
      const hrefStartRegex = /<a[^>]*>/i;
      const hrefEndRegex = /<\/a>/i;
      initialTranslation = initialTranslation.replace(hrefStartRegex, `<${linkIndex}>`).replace(hrefEndRegex, `</${linkIndex}>`);
      linkIndex += 1;
    }

    return {
      linksNodes,
      correctTranslation: initialTranslation,
    };
  };

  render() {
    const { i18nKey } = this.props;
    const { linksNodes, correctTranslation } = this.getCorrectedTranslation();
    return (
      linksNodes.length ?
        <Trans
          components={linksNodes}
        >
          {correctTranslation}
        </Trans> :
        <Trans i18nKey={i18nKey} />
    );
  }
}

export default Translate;
