import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import Header from 'components/header/Header';
import { ReactComponent as Pic1 } from 'assets/images/error-icon-exclamation.svg';
import { getUA } from 'utils';
import AppContext from 'modules/AppContext';

type Props = {
  supportEmail: string,
  brandName: string,
};

class DeviceNotSupported extends Component<Props> {
  render() {
    const { supportEmail, brandName = 'daego' } = this.props;
    const { closeButtonOnErrorScreens, leaveApp } = this.context;
    const { isApple } = getUA();
    const applicationLink = isApple
      ? 'https://itunes.apple.com/app/daego/id1318724269'
      : 'https://play.google.com/store/apps/details?id=com.pxlvision.pxlbeamverifeye';
    return (
      <div className="comp-device-not-supported">
        <Header />
        <div className="card">

          <div className="fill-area">
            <div className="contents">
              <div className="icon">
                <Pic1 className="pictogram" />
              </div>
              <div className="text">
                <div className="bigger"><Translate i18nKey="device-not-supported.sorry" /></div>
                <div className="smaller"><Translate i18nKey="device-not-supported.message" /></div>
              </div>
            </div>
          </div>
          <div className="solutions">
            <div className="message">
              <Translate i18nKey="device-not-supported.reason" />
            </div>
            <div className="heading"><Translate i18nKey="device-not-supported.browser-compatibility" /></div>
            <div>
              <ul className="list">
                <li><Translate i18nKey="device-not-supported.apple-device" /></li>
                <li>
                  <Translate i18nKey="device-not-supported.all-devices" />
                </li>
              </ul>
            </div>
            <div className="heading"><Translate i18nKey="device-not-supported.what-you-can-do" /></div>
            <ul>
              {brandName === 'daego' && (
                <li>
                  <span>
                    <a href={applicationLink}>
                      <Translate i18nKey="device-not-supported.download-app" />
                    </a>
                  </span>
                </li>
              )}
              <li>
                <span>
                  <a href={`mailto:${supportEmail}`}>
                    <Translate i18nKey="device-not-supported.contact" />
                  </a>
                </span>
              </li>
            </ul>
          </div>
          {
            closeButtonOnErrorScreens
            && (
              <div
                className="button button-big"
                role="button"
                onKeyPress={() => leaveApp(true)}
                onClick={() => leaveApp(true)}
                tabIndex={-1}
              >
                <Translate i18nKey="error-pages.close" />
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

DeviceNotSupported.contextType = AppContext;

export default DeviceNotSupported;
