import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import format from 'string-format';
import { Views } from 'modules/terms/types.d';
import AppContext from 'modules/AppContext';

type Props = {
  onDone: (data: any) => void,
  setView: (view: Views) => void,
  terms: any,
  allowShareDataForML: any,
  allowUseDataForML: boolean;
};

type State = {
  TaC: boolean,
  allowUseDataForML: boolean,
};

// eslint-disable-next-line no-unused-vars
class TermsAgreement extends Component<Props, State> {
  public agreeButtonRef = React.createRef<HTMLDivElement>();

  constructor(props: any) {
    super(props);
    const { allowUseDataForML } = props;
    this.state = {
      TaC: false,
      allowUseDataForML,
    };
  }

  goToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  sendData = (data: any) => {
    const { onDone } = this.props;
    onDone(data);
  };

  agreeAndStart = () => {
    const { TaC, allowUseDataForML } = this.state;
    if (!TaC) return;
    this.sendData({ TaC, allowUseDataForML });
  };

  toggleTerms = () => {
    const { TaC } = this.state;
    if (!TaC) {
      this.agreeButtonRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    this.setState({ TaC: !TaC });
  };

  toggleDataForML = () => {
    const { allowUseDataForML } = this.state;
    this.setState({ allowUseDataForML: !allowUseDataForML });
  };

  navigateToLegalDocument = (view: Views) => {
    const { setView } = this.props;
    setView(view);
  };

  navigateAway = (url: 'string') => {
    const { lang: { currentLang, default: defaultLang } } = this.context;
    const formatedUrl = format(url, { lang: currentLang || defaultLang });
    window.open(formatedUrl, '_blank');
  };

  render() {
    const { terms: termsUrls, allowShareDataForML } = this.props;
    const { isCheckBoxEnabled } = allowShareDataForML;
    const { TaC, allowUseDataForML } = this.state;
    const inactive = (TaC) ? 'active' : 'inactive';
    const dataForMLInactive = (allowUseDataForML) ? 'active' : 'inactive';
    const privacyPolicyHandler: any = termsUrls.privacyPolicy
      ? (() => this.navigateAway(termsUrls.privacyPolicy))
      : (() => this.navigateToLegalDocument(Views.privacyPolicy));
    const termsAndConditionsHandler: any = termsUrls.termsAndConditions
      ? (() => this.navigateAway(termsUrls.termsAndConditions))
      : (() => this.navigateToLegalDocument(Views.termsAndConditions));
    const additionalPrivacyPolicyHandler: any = termsUrls.additionalPrivacyPolicy
      ? (() => this.navigateAway(termsUrls.additionalPrivacyPolicy))
      : (() => this.navigateToLegalDocument(Views.additionalPrivacyPolicy));
    const supportEmail = 'mailto:privacy@daego.com';
    return (
      <div className="comp-terms">
        <div className="card">

          <div className="main-content">
            <h1><Translate i18nKey="terms.title" /></h1>
            <div className="agree-list">
              <ul>
                <li>
                  <Translate i18nKey="terms.by-clicking" />
                  <Translate i18nKey="terms.continue" />
                  <Translate i18nKey="terms.agree-message" />
                  {' '}
                  <div
                    className="link"
                    role="button"
                    onKeyUp={privacyPolicyHandler}
                    onClick={privacyPolicyHandler}
                    tabIndex={-1}
                  >
                    <span><Translate i18nKey="terms.privacy-policy" /></span>
                  </div>
                  {' '}
                  <Translate i18nKey="terms.and-the" />
                  {' '}
                  <div
                    className="link"
                    role="button"
                    onKeyUp={termsAndConditionsHandler}
                    onClick={termsAndConditionsHandler}
                    tabIndex={-1}
                  >
                    <span>
                      {' '}
                      <Translate i18nKey="terms.terms-of-use" />
                    </span>
                  </div>
                  .
                </li>
                <li>
                  <Translate i18nKey="terms.message1" />
                  {' '}
                  <Translate i18nKey="terms.message1-addition" />
                  {' '}
                  <Translate i18nKey="terms.message1-end" />
                </li>
                <li>
                  <Translate i18nKey="terms.message2" />
                  {' '}
                  <a href={`mailto:${supportEmail}`} className="link">
                    <span><Translate i18nKey="terms.consent" /></span>
                  </a>
                  {' '}
                  <Translate i18nKey="terms.message2-end" />
                  <div
                    className="link"
                    role="button"
                    onKeyUp={additionalPrivacyPolicyHandler}
                    onClick={additionalPrivacyPolicyHandler}
                    tabIndex={-1}
                  >
                    <span><Translate i18nKey="terms.additional-privacy-policy" /></span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="agree-checkboxes">
            <div className="agree-checkbox">
              <div className={`checkbox-container ${inactive}`}>
                <input type="checkbox" id="agree-checkbox" checked={TaC} onChange={this.toggleTerms} />
              </div>
              <label htmlFor="agree-checkbox">
                <Translate i18nKey="terms.hereby" />
              </label>
            </div>
            {
              isCheckBoxEnabled && (
                <div className="agree-checkbox">
                  <div className={`checkbox-container allowUseDataForML-checkbox ${dataForMLInactive}`}>
                    <input type="checkbox" id="allowUseDataForML-agree-checkbox" checked={allowUseDataForML} onChange={this.toggleDataForML} />
                  </div>
                  <label htmlFor="allowUseDataForML-agree-checkbox">
                    <Translate i18nKey="terms.allowUseDataForML" />
                  </label>
                </div>
              )
            }
          </div>
          <div className="button-container">
            <div
              className={`button button-big ${inactive}`}
              role="button"
              tabIndex={-1}
              onKeyPress={() => this.agreeAndStart()}
              onClick={() => this.agreeAndStart()}
              ref={this.agreeButtonRef}
            >
              <Translate i18nKey="terms.btn.continue" />
            </div>
          </div>

        </div>
      </div>
    );
  }
}

TermsAgreement.contextType = AppContext;

export default TermsAgreement;
