import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import StartText from 'components/startText/StartText';
import { ReactComponent as FlowLandscape } from 'assets/images/flow-landscape.svg';
import { ReactComponent as FlowPortrait } from 'assets/images/flow-portrait.svg';

type Props = {
  onDone: () => void,
  onCompatibilityNotesTextClick: (data: any) => void,
};

export default class Start extends Component<Props> {
  startHandler = () => {
    const { onDone } = this.props;
    onDone();
  };

  render() {
    const { onCompatibilityNotesTextClick } = this.props;
    return (
      <div className="comp-start">
        <div className="card">
          <div className="main-content">
            <div className="welcome"><Translate i18nKey="start.welcome" /></div>
            <div className="pictograms">
              <FlowLandscape className="flow-landscape" />
              <FlowPortrait className="flow-portrait" />
            </div>
            <StartText onCompatibilityNotesTextClick={onCompatibilityNotesTextClick} />
          </div>
          <div
            className="button button-big"
            role="button"
            tabIndex={-1}
            onKeyPress={this.startHandler}
            onClick={this.startHandler}
          >
            <Translate i18nKey="start.btn.start" />
          </div>
        </div>
      </div>
    );
  }
}
