import React, { Component } from 'react';
import ReactCodeInput, { ReactCodeInputProps } from 'react-code-input'; // eslint-disable-line no-unused-vars, @typescript-eslint/no-unused-vars
import Translate from 'components/translate/Translate';
import Header from 'components/header/Header';
import { ReactComponent as LogoPlaceholder } from 'assets/images/sp-logo-placeholder.svg';
import { ReactComponent as FailPic } from 'assets/images/error-icon-exclamation.svg';
import AppContext from 'modules/AppContext';

type Props = {
  tc: string,
  customerLogo: string | null;
};

class InvalidTC extends Component<Props> {
  render() {
    const { tc, customerLogo } = this.props;
    const { leaveApp, closeButtonOnErrorScreens } = this.context;
    const tcInputProps = {
      type: 'number',
      fields: 9,
      autoFocus: false,
      isValid: false,
      value: tc,
      disabled: true,
    } as ReactCodeInputProps;
    let logoImg: any = <LogoPlaceholder className='default-sp-logo' />;
    if (customerLogo) {
      logoImg = (
        <img
          src={customerLogo}
          alt="Service Provider logo"
          className="sp-logo"
        />
      );
    }
    return (
      <div className="comp-invalid-code">
        <Header />
        <div className="card">
          <div className="main-content">
            <div className="title"><Translate i18nKey="invalidTC.title" /></div>
            <FailPic className="fail-icon" />
            <div>
              <div className="message"><Translate i18nKey="invalidTC.input-title" /></div>
              <ReactCodeInput {...tcInputProps} />
              <div>
                {
                  tc
                    ? <Translate i18nKey="invalidTC.error-message" />
                    : <Translate i18nKey="invalidTC.no-code-message" />
                }
              </div>
            </div>
            <div className="service-provider-logo">{logoImg}</div>
          </div>
          {
            closeButtonOnErrorScreens
            && (
              <div
                className="button button-big"
                role="button"
                onKeyPress={() => leaveApp(true)}
                onClick={() => leaveApp(true)}
                tabIndex={-1}
              >
                <Translate i18nKey="error-pages.close" />
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

InvalidTC.contextType = AppContext;

export default InvalidTC;
