import React, { Component } from 'react';
import {
  getCustomerForwardURL,
  handleFetchErrors,
  fetch,
  deepMerge,
} from 'utils';
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import {
  IBrowser,
  IServiceData,
  TDeepPartial,
  IAppConfig,
  IDraftTransactionCodeState,
  IState,
  TStateData,
} from 'types/index.d';
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */
import Loading from 'modules/transactionCode/views/loading/Loading';
import TransactionCodeView from 'modules/transactionCode/views/transactionCode/TransactionCode';
import QrCodeView from 'modules/transactionCode/views/qrCodeScanner/QrCodeScanner';
import InvalidTC from 'modules/transactionCode/views/invalidTC/InvalidTC';
import Views from 'modules/transactionCode/Views';

type Props = {
  viewConfig: IAppConfig;
  exitURL: string;
  serviceProviderData: IServiceData;
  tc?: string;
  browser: IBrowser;
  session: any;
  onDone: (data: any) => void;
  getViewsList?: () => any;
  changeState?: (key: keyof IState, data: TStateData) => void;
};

type State = {
  view: Views;
  prevView: Views;
  draftAppState: IDraftTransactionCodeState;
  isDone: boolean;
  spLogo: string | null;
};

export default class TransactionCode extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      viewConfig,
      exitURL,
      serviceProviderData = {
        data: {
          customer: {},
          transaction: {},
        },
      } as any,
    } = this.props;
    this.state = {
      view: Views.loading,
      prevView: null,
      draftAppState: {
        appConfig: viewConfig,
        exitURL,
        serviceProviderData,
      },
      isDone: false,
      spLogo: null,
    };
  }

  componentDidMount() {
    const { tc, viewConfig } = this.props;
    const { modules } = viewConfig;

    if (tc) {
      this.getServiceProviderData(tc)
        .then((serviceProviderData: any) => {
          this.handleServiceProviderData(serviceProviderData);
          this.onDone();
        })
        .catch(() => {
          this.setView(Views.invalidTC);
        });
    } else if (modules.transactioncode.enabled) {
      this.setView(Views.transactioncode);
    } else {
      this.setView(Views.invalidTC);
    }
  }

  componentDidUpdate() {
    const { isDone } = this.state;
    if (isDone) {
      const { onDone } = this.props;
      const { draftAppState } = this.state;
      onDone(draftAppState);
    }
  }

  getServiceProviderData = (code: any) => fetch('/check_transaction_code', {
    method: 'POST',
    body: JSON.stringify({ code }),
  })
    .then(handleFetchErrors)
    .then((res: any) => res.json());

  changeConfigViewsDependingOnTC = (allowedViews: any) => {
    const { getViewsList, changeState } = this.props;
    const { draftAppState: { appConfig } } = this.state;
    const { modules: { lfv, additionalDoc } } = appConfig;

    if (!allowedViews.includes(2) && !allowedViews.includes(3) && lfv.enabled) lfv.enabled = !lfv.enabled;
    if (!allowedViews.includes(5) && additionalDoc.enabled) additionalDoc.enabled = !additionalDoc.enabled;
    changeState('history', { views: getViewsList().filtered });
  };

  handleServiceProviderData = (serviceProviderData: any) => {
    const forwardURL: string = getCustomerForwardURL(serviceProviderData);
    const { data = {} } = serviceProviderData;
    const { transaction = {}, customer = {} } = data;
    const { logo: spLogo = null } = customer;
    if (Object.prototype.hasOwnProperty.call(transaction, 'allowed_functionality')) {
      const { allowed_functionality: allowedFunctionality = [] } = transaction;
      this.changeConfigViewsDependingOnTC(allowedFunctionality);
    }

    const newState = this.deepMergeServiceProviderData(serviceProviderData)(this.state);
    if (forwardURL) newState.draftAppState.exitURL = forwardURL;
    this.setState({ ...newState, spLogo });
  };

  setView = (view: Views, preservePrevView = true) => {
    const { view: prevView } = this.state;

    this.setState({
      view,
      prevView: preservePrevView ? prevView : null,
    });
  };

  goBack = () => {
    const { prevView } = this.state;
    this.setView(prevView, false);
  };

  onDone = () => {
    this.setState({ isDone: true });
  };

  deepMergeServiceProviderData(serviceProviderData: TDeepPartial<IServiceData>) {
    const { provider, data: { customer = {}, transaction = {} } = {} } = serviceProviderData as any;
    return (prevState: State) => deepMerge(prevState, {
      draftAppState: {
        serviceProviderData: {
          ...(provider ? { provider } : {}),
          data: {
            customer,
            transaction,
          },
        },
      },
    });
  }

  render() {
    const {
      prevView,
      view,
      draftAppState: { appConfig },
      spLogo,
    } = this.state;
    const { tc } = this.props;
    const { modules } = appConfig;

    const {
      session,
      browser: {
        isOnline,
        orientation,
        userAgent: {
          isApple,
          isDesktop,
        },
      }
    } = this.props;

    let compView: React.ReactElement;
    switch (view) {
      case Views.qrCode:
        compView = (
          <QrCodeView
            isOnline={isOnline}
            isDesktop={isDesktop}
            session={session}
            isApple={isApple}
            orientation={orientation}
            prevView={prevView}
            handleServiceProviderData={this.handleServiceProviderData}
            getServiceProviderData={this.getServiceProviderData}
            setView={this.setView}
            onDone={this.onDone}
            goBack={this.goBack}
          />
        );
        break;
      case Views.transactioncode:
        compView = (
          <TransactionCodeView
            qrCodeEnabled={modules.transactioncode.qrCode}
            handleServiceProviderData={this.handleServiceProviderData}
            getServiceProviderData={this.getServiceProviderData}
            setView={this.setView}
            onDone={this.onDone}
            customerLogo={spLogo}
          />
        );
        break;
      case Views.loading:
        compView = (
          <Loading />
        );
        break;
      case Views.invalidTC:
      default:
        compView = (
          <InvalidTC
            tc={tc}
            customerLogo={spLogo}
          />
        );
    }

    return (
      compView
    );
  }
}
