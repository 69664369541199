import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import AppContext from 'modules/AppContext';

type Props = {
  isfeedbackButtonExist: boolean,
  feedbackBlockStyle: any,
  finish: any,
  giveFeedback: () => void,
  exitURL: string,
};

interface State {
  feedBack: false,
  thankYou: false,
}

class ThankYou extends Component<Props, State> {
  render() {
    const {
      isfeedbackButtonExist,
      feedbackBlockStyle,
      finish,
      giveFeedback,
    } = this.props;

    const { leaveApp } = this.context;
    const feedbackButtonContainer = isfeedbackButtonExist
      ? (
        <div className="block feedback-button-container" style={feedbackBlockStyle}>
          <div className="text-caption">
            <div>
              <Translate i18nKey="thank-you.goal-text" />
            </div>
            <div>
              <Translate i18nKey="thank-you.welcome-feedback" />
            </div>
          </div>

          <div className="button-container">
            <div
              className="button button-big secondary"
              role="button"
              tabIndex={-1}
              onKeyPress={() => giveFeedback()}
              onClick={() => giveFeedback()}
            >
              <Translate i18nKey="thank-you.give-feedback" />
            </div>
          </div>
        </div>
      )
      : '';

    return (
      // <Swipe
      //   goBack
      //   goForward={isfeedbackButtonExist ? () => setView(Views.feedback) : false}
      // >
      <div className="comp-thank-you">
        <div className="card">

          <div className="heading"><Translate i18nKey="thank-you.title" /></div>

          <div className="text-caption">
            <div className="message"><Translate i18nKey="thank-you.message1" /></div>
            <div className="message"><Translate i18nKey="thank-you.message2" /></div>
          </div>

          {finish && (
            <div>
              <button
                className="button-big"
                type="button"
                onKeyUp={leaveApp}
                onClick={leaveApp}
              >
                <Translate i18nKey="thank-you.finish" />
              </button>
            </div>
          )}

          {feedbackButtonContainer}

        </div>
      </div>
      // </Swipe>
    );
  }
}

ThankYou.contextType = AppContext;

export default ThankYou;