import React, { Component } from 'react';
import platform from 'platform';
import Translate from 'components/translate/Translate';
import Frame from 'components/frame/Frame';
import DocumentRecordingInstructions from 'components/documentRecordingInstructions/DocumentRecordingInstructions';
import IdDocumentOverlay from 'components/idDocumentOverlay/IdDocumentOverlay';
import SuccessPictogram from 'components/successPictogram/SuccessPictogram';
import {
  VideoStreamStatus,
  VibrationTunes,
  IdDocumentSide,
} from 'resources/enums';
import gaPageView from 'utils/GA';
import { error } from 'utils/log';
import { vibrate } from 'utils/flowController';

/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { DocumentTypes, DoneStatus } from 'types/enum';
import { fetch } from 'utils';
import { UserAgentData } from 'types/index.d';

enum Statuses {
  wait = 'wait',
  instruction = 'instruction',
  overlay = 'overlay',
  recording = 'recording',
  processing = 'processing',
  success = 'success',
  fail = 'fail',
}
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */

const TIMEOUT = 60 * 1000;

const OVERLAY_TIMEOUT = 3 * 1000;

type Props = {
  onDone: (status: DoneStatus) => void;
  addDVSLog: (data: string) => void;
  appState: any;
  documentType: DocumentTypes,
  videoStream?: any,
};

type State = {
  side: IdDocumentSide,
  status: Statuses,
};

export default class DocumentRecording extends Component<Props, State> {
  private timeoutFunc: any;

  constructor(props: any) {
    super(props);
    this.state = {
      side: IdDocumentSide.side1,
      status: Statuses.wait,
    };
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State): any {
    const { appState, videoStream } = nextProps;
    const { isOnline } = appState;
    const { status } = prevState;

    const wasInterrupted: boolean = (
      !isOnline || videoStream.status === VideoStreamStatus.stop
    ) && [Statuses.overlay, Statuses.recording].includes(status);
    if (wasInterrupted) {
      return {
        status: Statuses.instruction,
      };
    }

    return null;
  }

  componentDidMount() {
    gaPageView('/document_recording');
    this.setState({ status: Statuses.instruction });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { status } = this.state;
    const { status: prevStatus } = prevState;

    if ([Statuses.instruction].includes(status)
      && [Statuses.overlay, Statuses.recording].includes(prevStatus)) {
      this.stopRrecording();
    }
  }

  startTimeout = () => {
    this.timeoutFunc = setTimeout(() => {
      const { status } = this.state;
      if (status === Statuses.recording) {
        const { videoStream } = this.props;
        videoStream.stopVideoRecording();
      }
      if (status === Statuses.processing) {
        window.stop();
      }
      this.setState({ status: Statuses.fail });
      error(`Document recording timeouted after ${TIMEOUT / 1000} seconds.`);
    }, TIMEOUT);
  };

  iosWithVersionNotSupportedMediaRecorder = () => {
    const iosMinSupportVersion = 14.6;
    if ((navigator as any).userAgentData) {
      return (navigator as any).userAgentData.getHighEntropyValues([
        'platform',
        'platformVersion',
      ])
        .then((ua: UserAgentData) => {
          return ua.platform.toLowerCase() === 'ios' && (parseFloat(ua.platformVersion) < iosMinSupportVersion);
        });
    }
    const platformInfo = platform.parse(navigator.userAgent);
    if (!platformInfo) return false;
    const { os = {} } = platformInfo;
    const { family = '' } = os;
    // Apple started to support webrtc MediaRecorder from 14.6 version of iOS
    return family.toLowerCase() === 'ios' && (parseFloat(platformInfo.os.version) < iosMinSupportVersion);
  };

  recordWithImages = (): Promise<string> => (
    new Promise((resolve, reject) => {
      const { videoStream } = this.props;
      videoStream.collectImagesForVideo(50)
        .then((images: string[]) => {
          resolve(JSON.stringify({
            images,
          }));
        })
        .catch(reject);
    })
  );

  recordWithVideo = (): Promise<any> => {
    const { videoStream } = this.props;
    const { side } = this.state;
    return videoStream.recordVideo((5 * 1000))
      .then((capturedVideo: any) => {
        if (!capturedVideo) return undefined;

        const fd = new FormData();
        fd.append('video', capturedVideo, `document_record_${side}`);
        return fd;
      })
      .catch((err: any) => {
        throw err;
      });
  };

  sendData = (data: any): void => {
    const { appState, addDVSLog, onDone } = this.props;
    const { side } = this.state;
    this.setState({ status: Statuses.processing });
    fetch('/document_record', {
      method: 'POST',
      body: data,
    })
      .then((res: any) => res.json())
      .then(() => {
        this.setState({ status: Statuses.success });
        vibrate(VibrationTunes.success);
        addDVSLog(`Document record was successfully saved for ${side}.`);
        window.setTimeout(() => {
          const { data: stateData } = appState;
          const { sideCount } = stateData.verification;
          if (sideCount === 2 && side === IdDocumentSide.side1) {
            this.setState({ side: IdDocumentSide.side2, status: Statuses.instruction });
          } else {
            onDone(null);
          }
        }, 1000);
      })
      .catch((err: any) => {
        this.setState({ status: Statuses.fail });
        const { message, stack } = err;
        error(`Fetch /document_record error: message: ${message} stack: ${stack}`);
        addDVSLog(`Internal Server Error during Document record saving ${side}.`);
      })
      .finally(() => clearTimeout(this.timeoutFunc));
  };

  startRecording = async () => {
    this.setState({ status: Statuses.recording });

    this.startTimeout();

    const { addDVSLog } = this.props;
    const { side } = this.state;
    addDVSLog(`Document recording started for ${side}`);

    let dataPromise: Promise<any>;
    if (!(window as any).MediaRecorder || await this.iosWithVersionNotSupportedMediaRecorder()) {
      dataPromise = this.recordWithImages();
    } else {
      dataPromise = this.recordWithVideo();
    }

    dataPromise
      .then((data) => {
        if (!data) return;
        this.sendData(data);
      })
      .catch(() => {
        this.setState({ status: Statuses.fail });
      });
  };

  stopRrecording = () => {
    const { videoStream } = this.props;
    clearTimeout(this.timeoutFunc);
    videoStream.stopVideoRecording();
  };

  instructionOnClickHandler = () => {
    this.setState({ status: Statuses.overlay });
    setTimeout(async () => {
      await this.startRecording();
    }, OVERLAY_TIMEOUT);
  };

  // TODO: Will be changed with enhanced functionality
  failHandler = () => {
    const { onDone } = this.props;

    onDone(null);
  };

  render() {
    const { documentType } = this.props;
    const { status, side } = this.state;
    let content: any = '';
    switch (status) {
      case Statuses.instruction:
        content = (
          <Frame>
            <DocumentRecordingInstructions
              documentType={documentType}
              side={side}
              onClickHandler={this.instructionOnClickHandler}
            />
          </Frame>
        );
        break;
      case Statuses.overlay:
        content = (
          <>
            <Frame />
            {/* TODO: Temporary, will be replace by it's own animation */}
            <IdDocumentOverlay
              documentType={documentType}
              side={side}
            />
          </>
        );
        break;
      case Statuses.recording:
        content = (
          <Frame classNames="animatable" />
        );
        break;
      case Statuses.processing:
        content = (
          <Frame classNames="animatable">
            <div className="inner-frame">
              <div className="text"><Translate i18nKey="dv.doc-record.wait" /></div>
            </div>
          </Frame>
        );
        break;
      case Statuses.success:
        content = (
          <SuccessPictogram />
        );
        break;
      case Statuses.fail:
        content = (
          <Frame>
            <div className="inner-frame">
              <div>
                <div className="text"><Translate i18nKey="dv.doc-record.fail" /></div>
                <div
                  className="button button-big overlay-button"
                  role="button"
                  onClick={this.failHandler}
                  onKeyPress={() => { }}
                  tabIndex={-1}
                >
                  <Translate i18nKey="dv.doc-record.btn.ok" />
                </div>
              </div>
            </div>
          </Frame>
        );
        break;
      default:
        break;
    }

    return content;
  }
}
