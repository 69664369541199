import React, { Component } from 'react';

type Props = {
  children: any
  goBack?: any,
  goForward?: any,
  loading?: boolean,
};

type State = {
  touchStartX: number,
};

export default class Swipe extends Component<Props, State> {
  private deltaX = 0;

  constructor(props: any) {
    super(props);
    this.state = {
      touchStartX: 0,
    };
  }

  goBack = () => {
    const { loading } = this.props;
    if (loading) return;

    const { goBack } = this.props;
    if (goBack && typeof goBack === 'function') {
      goBack();
    } else if (goBack) {
      window.history.back();
    }
  };

  goForward = () => {
    const { loading } = this.props;
    if (loading) return;

    const { goForward } = this.props;
    if (goForward && typeof goForward === 'function') {
      goForward();
    }
  };

  handleStart = (clientX: any) => {
    this.setState({
      touchStartX: clientX,
    });
  };

  handleMove = (clientX: any) => {
    const { touchStartX } = this.state;
    this.deltaX = clientX - touchStartX;
  };

  handleEnd = () => {
    if (this.deltaX < -100) {
      this.goForward();
    } else if (this.deltaX > 100) {
      this.goBack();
    }
    this.setState({
      touchStartX: 0,
    });
    this.deltaX = 0;
  };

  handleTouchStart = (touchStartEvent: any) => {
    this.handleStart(touchStartEvent.targetTouches[0].clientX);
  };

  handleTouchMove = (touchMoveEvent: any) => {
    this.handleMove(touchMoveEvent.targetTouches[0].clientX);
  };

  handleTouchEnd = () => {
    this.handleEnd();
  };

  render() {
    const { children } = this.props;
    return (
      <div
        className="swipe-content"
      // TODO: disable temporary, check do we need this and what exactly should be the behaviour
      // onTouchStart={(touchStartEvent) => this.handleTouchStart(touchStartEvent)}
      // onTouchMove={(touchMoveEvent) => this.handleTouchMove(touchMoveEvent)}
      // onTouchEnd={() => this.handleTouchEnd()}
      >
        {children}
      </div>
    );
  }
}
