import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import RatingItem from 'components/raitingItem/RatingItem';
import Loader from 'components/loader/Loader';
import { ReactComponent as IconBack } from 'assets/images/arrow-left.svg';
import { ReactComponent as ScrollToTop } from 'assets/images/scroll-to-top.svg';
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { IServiceData } from 'types/index.d';
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */
import {
  fetch,
  generateKey,
} from 'utils';

type Props = {
  goBack: () => void,
  goToThankYou: () => void,
  setLoading: (loading: boolean) => void,
  setFeedback: (feedback: boolean) => void,
  serviceProviderData: IServiceData,
  loading: boolean;
};

type Rating = {
  text: string,
  rating: number
};

type State = {
  ratings: Rating[],
};

const initialRatings: Array<Rating> = [{
  text: 'usability',
  rating: 0,
}, {
  text: 'functionality',
  rating: 0,
}, {
  text: 'performance',
  rating: 0,
}, {
  text: 'reliability',
  rating: 0,
}, {
  text: 'accuracy',
  rating: 0,
}];

export default class Feedback extends Component<Props, State> {
  private topButtonRef = React.createRef<HTMLDivElement>();

  private feedbackRef = React.createRef<HTMLDivElement>();

  private backButtonRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      ratings: initialRatings.map((data) => ({ ...data })),
    };
  }

  sendLoadingState = (loading: any) => {
    const { setLoading } = this.props;
    setLoading(loading);
  };

  backButtonHandler() {
    const { goBack, loading } = this.props;
    if (loading) return;
    goBack();
  }

  noRatingsGiven() {
    const { ratings } = this.state;
    return (ratings.filter((a) => a.rating !== 0).length === 0);
  }

  sendFeedback() {
    const { loading, setLoading } = this.props;
    if (loading) return;

    const { ratings } = this.state;
    const { setFeedback, serviceProviderData, goToThankYou } = this.props;
    let code = '';
    if (serviceProviderData && serviceProviderData.data && serviceProviderData.data.transaction) {
      code = serviceProviderData.data.transaction.code;
    }
    const feedback = this.feedbackRef.current.innerHTML;
    if (!feedback && this.noRatingsGiven()) return;
    const data = {
      code,
      ratings,
      feedback,
    };
    setLoading(true);
    fetch('/feedback', {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => {
        setFeedback(false);
        setLoading(false);
        goToThankYou();
      })
      .catch(() => {
        setLoading(false);
      });
  }

  goToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  render() {
    const { loading } = this.props;
    const { ratings } = this.state;
    const inactive: string = loading ? 'inactive' : 'active';

    return (
      <div className="comp-feedback">
        <div className="card">
          <div className="heading"><Translate i18nKey="feedback.title" /></div>

          <div className="text-caption">
            <div>
              <Translate i18nKey="feedback.offer" />
            </div>
          </div>

          {ratings.map((rating: Rating, index) => {
            const element = (
              <div
                className="rating-container"
                key={generateKey()}
              >
                <div><Translate i18nKey={`feedback.${rating.text}`} /></div>
                <RatingItem
                  rating={rating.rating}
                  changeRating={(newRating: number) => {
                    const newRatings = ratings.splice(0);
                    newRatings[index].rating = newRating;
                    this.setState({ ratings: newRatings });
                  }}
                />
              </div>
            );
            return element;
          })}

          <div className="textarea-container">

            <div className="text-caption">
              <div>
                <Translate i18nKey="feedback.message" />
              </div>
            </div>

            <div ref={this.feedbackRef} contentEditable />

          </div>

          <div className="button-container button-with-loader">
            <div
              className={`button button-big secondary ${inactive}`}
              role="button"
              tabIndex={-1}
              onKeyPress={() => this.sendFeedback()}
              onClick={() => this.sendFeedback()}
            >
              <span><Translate i18nKey="feedback.btn.send-feedback" /></span>
              {loading && <Loader />}
            </div>
          </div>

          <div className="footer">
            <div
              className="back-button"
              ref={this.backButtonRef}
              role="button"
              onKeyDown={() => this.backButtonHandler()}
              onClick={() => this.backButtonHandler()}
              tabIndex={-1}
            >
              <IconBack className="icon-back" />
              <span><Translate i18nKey="feedback.btn.back" /></span>
            </div>
            <div
              className="scroll-to-top"
              ref={this.topButtonRef}
              role="button"
              onKeyDown={this.goToTop}
              onClick={this.goToTop}
              tabIndex={-1}
            >
              <ScrollToTop />
              <span><Translate i18nKey="feedback.btn.top" /></span>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
