/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import React, { Component } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import html2canvas from 'html2canvas';
import Translate from 'components/translate/Translate';
import Frame from 'components/frame/Frame';
import CaptureImage from 'components/captureImage/CaptureImage';
import SuccessPictogram from 'components/successPictogram/SuccessPictogram';
import { IAppearance } from 'components/videoStream/VideoStream';
import Loader from 'components/loader/Loader';
import { fetch, vibrate } from 'utils';
import { error } from 'utils/log';
import gaPageView from 'utils/GA';
import { DoneStatus, VibrationTunes } from 'types/enum';
import { WhatToCapture } from 'resources/enums';

/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
enum Statuses {
  wait = 'wait',
  instruction = 'instruction',
  capture = 'capture',
  crop = 'crop',
  processing = 'processing',
  success = 'success',
}
/* eslint-enable no-unused-vars */

const SUCCESS_CHECKMARK_TIMEOUT = 1000;

interface IProps {
  onDone: (status: DoneStatus) => void;
  addDVSLog: (data: string) => void;
  videoStream?: any;
  isLandscape: boolean;
}
interface IState {
  status: Statuses;
  crop: Crop;
}

export default class UtilityBill extends Component<IProps, IState> {
  private capturedImage: any = null;

  constructor(props: IProps) {
    super(props);
    this.state = {
      status: Statuses.wait,
      crop: {
        y: 30,
        x: 30,
        unit: '%',
        width: 40,
        height: 40,
      },
    };
  }

  async componentDidMount() {
    const { addDVSLog } = this.props;
    addDVSLog('Started Utility bill capturing.');
    gaPageView('/utility_billy');
    try {
      const { videoStream } = this.props;
      await videoStream.ensureVideoStream();
      this.setState({ status: Statuses.instruction });
    } catch (err) {
      const { onDone } = this.props;
      const { message, stack } = (err || {}) as any;
      error(`Error when initializing Utility bill: message: ${message} stack: ${stack}`);
      onDone(DoneStatus.fail);
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    this.ensureVideoStreamAppearance(prevState);
  }

  ensureVideoStreamAppearance = (prevState: IState) => {
    const { videoStream } = this.props;
    const { status } = this.state;
    const { status: prevStatus } = prevState;

    const currentCondition = status === Statuses.capture;
    const prevCondition = prevStatus === Statuses.capture;

    if (currentCondition && !prevCondition) {
      const appearance: IAppearance = { canvasClassNames: 'video-canvas-size', videoClassNames: 'video-canvas-size' };
      if (!videoStream.isLandscapeOrientation) {
        appearance.poweredByLogo = { changePosition: true };
      }
      videoStream.updateAppearance(appearance);
    } else if (!currentCondition && prevCondition) {
      videoStream.updateAppearance({});
    }
  };

  instructionOnClickHandler = () => {
    this.setState({ status: Statuses.capture });
  };

  captureHandler = () => {
    const { videoStream } = this.props;

    const { capturedImg } = videoStream.captureImage(WhatToCapture.utilityBill);

    this.capturedImage = capturedImg;

    videoStream.updateAppearance({ videoStyle: { display: 'none' }, poweredByLogo: { hide: true } });

    this.setState({ status: Statuses.crop });
  };

  recaptureCallback = () => {
    const { videoStream } = this.props;
    videoStream.updateAppearance({ videoStyle: {}, poweredByLogo: {} });
    this.setState({ status: Statuses.capture }, () => {
      /**
       * The camera is frozen when switching from landscape to portrait mode when recapture.
       * More https://pxlvision.atlassian.net/browse/PID-173.
       */
      videoStream.stopVideoStream();
      videoStream.startVideoStream();
    });
  };

  saveImage = (image: string) => {
    const { addDVSLog, onDone, videoStream } = this.props;
    this.setState({ status: Statuses.processing });
    fetch('/utilityBill', {
      method: 'POST',
      body: JSON.stringify({
        image,
      }),
    })
      .then((result: any) => result.json())
      .then(() => {
        videoStream.updateAppearance({
          videoStyle: {},
          poweredByLogo: {},
          canvasClassNames: '',
          videoClassNames: '',
        });
        this.setState({ status: Statuses.success });
        vibrate(VibrationTunes.success);
        addDVSLog('Utility bill image was successfully saved.');
        setTimeout(() => {
          onDone(null);
        }, SUCCESS_CHECKMARK_TIMEOUT);
      })
      .catch((err: any) => {
        const { message, stack } = err;
        error(`Error when sending Utility bill image: message: ${message} stack: ${stack}`);
        onDone(err);
      });
  };

  getImageForSDK = (image: string): Promise<string> => {
    const img = new Image();
    img.src = image;

    return new Promise((resolve) => {
      img.onload = () => {
        const { isLandscape } = this.props;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let imgWidth = img.height;
        let imgheight = img.width;
        if (isLandscape) {
          imgWidth = img.width;
          imgheight = img.height;
        }
        // image MUST be 1920 x 1080
        const longEdge = 1920;
        const shortEdge = 1080;
        const imageResizeRatio = Math.min(longEdge / imgWidth, shortEdge / imgheight);
        const nW = imgWidth * imageResizeRatio;
        const nH = imgheight * imageResizeRatio;
        canvas.width = longEdge;
        canvas.height = shortEdge;
        if (isLandscape) {
          ctx.drawImage(img, 0, 0, imgWidth, imgheight, 0, 0, nW, nH);
        } else {
          // rotate, because PXL SDK can't work with portrait-oriented
          const angle = -90 * (Math.PI / 180); // radians
          ctx.translate(0, 1080);
          ctx.rotate(angle);

          ctx.drawImage(img, 0, 0, imgheight, imgWidth, 0, 0, nH, nW);
        }

        resolve(canvas.toDataURL('image/jpeg'));
      };
    });
  };

  continueCallback = async () => {
    const element = document.getElementsByClassName('ReactCrop image-preview')[0] as HTMLElement;
    const canvas = await html2canvas(element);
    const dataURI = canvas.toDataURL('image/jpeg', 1.0);
    const image = await this.getImageForSDK(dataURI);

    this.saveImage(image);
  };

  render() {
    const { status } = this.state;
    let content: any = '';
    switch (status) {
      case Statuses.instruction:
        content = (
          <Frame>
            <div className="inner-frame">
              <div className="text">
                <p><Translate i18nKey="utility-bill.message" /></p>
                <div
                  className="button button-big overlay-button"
                  role="button"
                  onClick={this.instructionOnClickHandler}
                  onKeyPress={() => { }}
                  tabIndex={-1}
                >
                  <Translate i18nKey="utility-bill.ok" />
                </div>
              </div>
            </div>
          </Frame>
        );
        break;
      case Statuses.capture:
        content = (
          <CaptureImage callback={this.captureHandler} />
        );
        break;
      case Statuses.crop:
        const { crop } = this.state;
        content = (
          <div className="comp-utility-bill">
            <div className="captured-section">
              <div className="confirmation-section">
                <h1 className="title"><Translate i18nKey="utility-bill.confirmation-message" /></h1>
                <ReactCrop
                  src={this.capturedImage}
                  crop={crop}
                  className="image-preview"
                  onChange={(newCrop) => {
                    this.setState({ crop: newCrop });
                  }}
                />
                <div className="confirmaition-links">
                  <div
                    className="button button-big"
                    role="button"
                    tabIndex={-1}
                    onKeyPress={() => { }}
                    onClick={this.recaptureCallback}
                  >
                    <Translate i18nKey="utility-bill.recapture" />
                  </div>
                  <div
                    className="button button-big"
                    role="button"
                    tabIndex={-1}
                    onKeyPress={() => { }}
                    onClick={this.continueCallback}
                  >
                    <Translate i18nKey="utility-bill.continue" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case Statuses.processing:
        content = (
          <div className="comp-utility-bill">
            <div className="captured-section">
              <div className="loader-section">
                <img
                  src={this.capturedImage}
                  alt="utility-bill-capture"
                />
                <div><Loader /></div>
              </div>
            </div>
          </div>
        );
        break;
      case Statuses.success:
        content = (
          <SuccessPictogram />
        );
        break;
      default:
        break;
    }
    return (
      <>
        {content}
      </>
    );
  }
}
