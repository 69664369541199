export default {
  tac: `
  <style>
    h2 {
      margin-block-start: 0.3em;
    }
    .tac-content a {
      color: #e9425e;
      margin-top: 30px;
    }
    .tac-content div {
      margin-bottom: 10px;
      margin-top: 5px;
    }
    .tac-content ol {
      list-style-position: inside;
      padding-inline-start: unset;
      counter-reset: item;
      margin-block-start: 5px;
      text-align: justify;
    }
    .tac-content {
      font-size: 0.8rem;
    }
    .tac-content .title {
      font-size: 1.1rem;
      font-weight: bold;
    }
    .tac-content ol.a > li::marker {
      font-size: 1.1rem;
      font-weight: bold;
    }
    .tac-content .last-update {
      font-size: 1rem;
      text-align: left;
      margin-top: 30px;
    }
  </style>
  <h2>PXL Vision AG Nutzungsvereinbarung für Endbenutzer der WebApp „Daego“</h2>
  <div class="tac-content">
    <ol class="a">
      <li>
        <span class="title">Dienstbeschreibung</span>
        <div>
          PXL Vision AG (nachstehend „PXL“, „wir“ oder „uns“ genannt) stellen Ihnen mit der WebApp
          „Daego“ (nachfolgend „Produkt“) eine Portallösung zur Nutzung auf Ihrem kompatiblen
          Endgerät sowie Serverdienstleistungen zur Verfügung, über die Sie Ihr von einer
          offiziellen Stelle ausgestelltes Personaldokument (z.B. Reisepass, Personalausweis,
          Ausländerausweis etc.) einscannen können und welches sodann die gescannten Daten mit
          einem über die WebApp erstellten Selfie abgleicht und im Erfolgsfall einen digitalen
          Identitätsnachweis zur Nutzung bei Transaktionspartnern generiert.
        </div>
        <div>
          Der digitale Identitätsnachweis ermöglicht es Ihnen, bei entsprechenden Anbietern
          (z.B. Mobilfunkanbieter, Banken, Web-Shops, etc. – im Folgenden „Transaktionspartner“
          genannt) eine Transaktion durchzuführen.
        </div>
        <div>
          Zur Durchführung einer Transaktion werden Sie von einem Transaktionspartner (z.B. beim
          Abschluss eines Onlinevertrages) aufgefordert, entweder einen von diesem mit einem
          Transaktionscode verbundenen Link auf die WebApp anzuklicken oder nach Direkteingabe
          der Webadresse der WebApp und dem nachfolgend beschriebenen Scan der Dokumente, einen
          von diesem Bereitgestellten Transaktionscode einzugeben oder QR Code zu scannen.
        </div>
        <div>
          Die WebApp weist Sie im Rahmen der Transaktion als Inhaber des gescannten offiziellen
          Ausweisdokumentes aus. Zur Verifikation durch den Transaktionspartner werden die
          erhobenen Daten bei Auslösung der Transaktion zusätzlich an den Transaktionspartner
          übermittelt, der diese gemäss seinen Datenschutzvorgaben speichert.
        </div>
        <div>
          Die Verifikation Ihres Ausweisdokumentes mit dem aufgenommenen Selfie erfolgt wie folgt:
        </div>
        <div>
          Indem Sie mit der Kamera des Endgerätes das Ausweisdokument fokussieren und auslösen,
          fotografiert und scannt die WebApp Ihr Ausweisdokument und identifiziert per
          Texterkennung die darin enthaltenen personenbezogenen Daten.
        </div>
        <div>
          Ausserdem wird das komplette Foto des Ausweisdokumentes mit den zusätzlichen
          Sicherheitsmerkmalen (wie Hologramm und Designelementen) erfasst.
        </div>
        <div>
          Nach dem Scan des Dokumentes werden Sie aufgefordert, ein kurzes Video über die mit dem
          Endgerät verbundene Kamera von sich selbst zu filmen.
        </div>
        <div>
          Die von der WebApp erhobenen Daten, Fotos und das Video werden von Ihrem Endgerät über
          eine gesicherte Verbindung in Echtzeit an die in der Schweiz befindlichen SOC 2
          zertifizierten Server übertragen und dort verarbeitet und gespeichert. Auf den Systemen
          von PXL werden dann die erhobenen Daten gegeneinander abgeglichen und das erhobene Foto
          wird mit dem Selfievideo in Bezug auf die biometrischen Daten überprüft. Ferner wird das
          Foto des Ausweisdokumentes überprüft. Nach Abschluss der Verifikation wird Ihre
          Einwilligung zur Übermittlung der erhobenen Identifkationsdaten und Bildddateien an den
          Transaktionspartner abgefragt und bei Zustimmung werden die Daten an den
          Transaktionspartner, der Ihnen den Link auf die WebApp bzw. die Transaktions-ID bzw. den
          Barcode zur Ausführung des Identifizierungsprozesses zur Verfügung gestellt hat, zur
          Bestätigung der Identität und ggf. zu Speicherung in Ihrem dortigen Kundenkonto
          übermittelt.
        </div>
        <div>
          Die vertragsgemässe Benutzung der WebApp und des Identifizierungsverfahrens ist für
          Sie kostenlos.
        </div>
        <div>
          Durch die die Nutzung der WebApp auf einem Mobiltelefon können
          Mobilfunk-Datenverbindungen entstehen, deren Berechnung bzw. Volumen-Anrechnung je
          nach Datentarif Ihres Netzbetreibers vorgenommen wird.
        </div>
      </li>
      <li>
        <span class="title">Nutzungsvoraussetzungen</span>
        <div>
          Voraussetzung für die Nutzung der Identifizierungs- und Verifikationsfunktionalität der
          WebApp ist die Zurverfügungstellung eines Transaktionscodes bzw. eines Links oder QR
          Codes durch einen Transaktionspartner.
        </div>
        <div>
          Die WebApp ist nicht mit der Begründung eines dauerhaften Nutzerkontos bei PXL
          verbunden, die Verifikation erfolgt nur einmalig für den zugeordneten Transaktionscode.
          Sollten Sie sich für einen anderen Dienst nochmals über die WebApp identifizieren, ist
          das Scan- und Verifikationsverfahren erneut zu durchlaufen.
        </div>
        <div>
          Sofern Sie PXL nicht explizit erlauben, die erhobenen Identifikationsdaten zu Zwecken
          der wissenschaftlichen Fortentwicklung und Verbesserung des dem
          Indentifikationsverfahren zugrundeliegenden Machine Learning Prozesse zu verwenden,
          werden Ihre Identifikationsdaten nach der Übertragung an den Transaktionspartner
          gelöscht. Der Transaktionspartner speichert diese Daten gem. seinen
          Datenschutzrichtlinien von denen Sie bitte separat Kenntnis nehmen.
        </div>
        <div>
          Wenn Sie mit diesen Vertragsbedingungen nicht vollumfänglich einverstanden sind, setzen
          Sie den Identifikationsprozess bitte nicht fort und fragen Sie den Transaktionspartner
          nach alternativen Identifikationsmöglichkeiten.
        </div>
      </li>
      <li>
        <span class="title">Rechte am Selfievideo</span>
        <div>
          Zur Nutzung des Produktes ist Ihrerseits die Zurverfügungstellung Ihrer
          personenbezogenen Daten sowie des von Ihnen erstellten Selfievideos erforderlich. Sie
          räumen hiermit PXL zur Erstellung und Aufrechterhaltung der digitalen Identität, zur
          Durchführung des Verifikationsprozesses und der Verbesserung der technischen Prozesse
          (sofern Sie hierin eingewilligt haben) ein zeitlich und örtlich unbegrenztes,
          kostenfreies Nutzungsrecht an dem von Ihnen erstellten Video ein und verzichten im
          Rahmen der notwendigen Verarbeitung der Bilddaten auf Ihre Rechte am eigenen Bild.
          Diese Zustimmung können sie jederzeit widerrufen indem Sie eine E-Mail an
          <a href="mailto:privacy@pxl-vision.com">privacy@pxl-vision.com</a> senden.
        </div>
      </li>
      <li>
        <span class="title">Gewährleistungsausschluss</span>
        <div>
          Die kostenfrei bereitgestellte WebApp und die zugehörigen Services werden „wie
          geliefert“ bereitgestellt, das heisst mit sämtlichen etwa vorhandenen Fehlern und unter
          Ausschluss jeglicher Art von Zusicherungen oder Zusagen. PXL schliesst hiermit jegliche
          Gewährleistung aus.
        </div>
        <div>
          Eine Kompatibilität mit den vom Nutzer verwendeten Betriebssystemen und Endgeräten
          wird zu keiner Zeit zugesichert.
        </div>
        <div>
          Die Nutzung der WebApp zur Verfizierung der Identität erfolgt demnach auf eigenes Risiko
          des Nutzers, ein erfolgreicher Einsatz der App zur Auslösung einer über die App
          angestossenen Transaktion wird ebenfalls nicht zugesichert.
        </div>
      </li>
      <li>
        <span class="title">Haftung von PXL</span>
        <div>
          PXL haftet für entstehende Schäden lediglich, soweit diese auf einer Verletzung einer
          wesentlichen Vertragspflicht oder auf einem vorsätzlichen oder grob fährlässigen
          Verhalten durch PXL, ihre gesetzlichen Vertreter oder Erfüllungsgehilfen beruht. Wird
          eine wesentliche Vertragspflicht leicht fahrlässig verletzt, so ist die Haftung von PXL
          auf den vorhersehbaren vertragstypischen Schaden begrenzt. Eine wesentliche
          Vertragspflicht ist bei Verpflichtungen gegeben, deren Erfüllung die ordnungsgemässe
          Durchführung des Vertrages erst möglich macht oder auf deren Einhaltung der Kunde
          vertraut hat und vertrauen durfte.
        </div>
        <div>
          Eine darüber hinausgehende Haftung, auch eine Haftung für indirekte Schäden,
          Mangelfolgeschäden und entgangenen Gewinn, ist ausgeschlossen.
        </div>
      </li>
      <li>
        <span class="title">Datenschutz</span>
        <div>
          PXL verpflichtet sich, Ihre Privatsphäre zu schützen. Es ist unser Ziel, Ihnen die
          Nutzung unserer Produkte und Serviceleistungen so nutzerfreundlich, zuverlässig und
          sicher wie möglich zu gestalten und Ihre personenbezogenen Daten, wie in der
          Datenschutzrichtlinie von PXL („Datenschutzrichtlinie“) definiert, dabei stets zu
          schützen. Informationen über unsere Datenschutzpraxis und unsere Datenschutzbestimmungen
          finden Sie in der <u>Datenschutzrichtlinie</u>.
        </div>
        <div>
          Vor der Nutzung des Verifikationsprozesses erfragen wir Ihr ausdrückliches
          Einverständnis, dass PXL personenbezogene Daten, einschliesslich besonderer Kategorien
          personenbezogener Daten im Sinne von Artikel 9 Absatz 1 der Datenschutzgrundverordnung
          bzw. des Art. 3 lit. c Schweizer Datenschutzgesetzes, über die WebApp erheben und
          verarbeiten darf, wie z.B. biometrische Daten.
        </div>
        <div>
          Wir erheben auch Daten über Identifikationsdokumente, die die Anwendung nicht lesen
          konnte, Fotos und Videos, bei denen die menschliche Verifizierung und die Anwendung
          unterschiedliche Ergebnisse liefern, sowie Daten über erfolgreiche Verifizierungen.
        </div>
        <div>
          Für weitere Informationen lesen Sie bitte die Datenschutzrichtlinie, die hiermit
          Bestandteil Ihrer Nutzungsvereinbarung ist.
        </div>
        <div>
          Bitte beachten Sie, dass für den Download der Software über die App Stores (z.B. Apple
          und Google) gesonderte Datenschutzbestimmungen gelten, über die Sie sich bitte im Rahmen
          der Nutzung des App Stores informieren. PXL übernimmt keine Verantwortung für die
          Datenverarbeitung dieser Anbieter.
        </div>
      </li>
      <li>
        <span class="title">Sonstige Bestimmungen.</span>
        <div>
          Auf diese Vereinbarung ist das schweizerische Recht unter Ausschluss des Übereinkommens
          der Vereinten Nationen über Verträge über den internationalen Warenverkauf vom
          11.4.1980 (UN-Kaufrecht) anzuwenden.
        </div>
        <div>
          Gerichtsstand für sämtliche Streitigkeiten zwischen PXL und seinen Nutzern ist Zürich.
        </div>
        <div>
          PXL kann indessen den Nutzer wahlweise auch an dessen Sitz oder Wohnort belangen.
        </div>
        <div>
          PXL darf ihre Rechte und Pflichten aus dem Vertrag ganz oder teilweise auf einen Dritten
          übertragen, wenn die Vertragserfüllung hierdurch nicht beeinträchtigt oder gefährdet
          wird und keine überwiegenden berechtigten Interessen des Nutzers entgegenstehen.
        </div>
        <div>
          Diese Nutzungsbedingungen gelten zum Zeitpunkt der jeweiligen Nutzung der WebApp in
          ihrer jeweils aktuellen Fassung.
        </div>
        <div>
          Die Verwendung der männlichen Form für Nutzer in diesem Dokument schließt weibliche
          und diverse Nuttzer ausdrücklich mit ein.
        </div>
        <div>
          Sind oder werden einzelne Bestimmungen dieser Vereinbarung unwirksam, so soll an deren
          Stelle eine Bestimmung treten, die dem wirtschaftlichen Zweck der unwirksamen Bestimmung
          möglichst nahe kommt. Die Wirksamkeit der übrigen Bestimmungen wird hierdurch nicht
          berührt.
      </li>
      <li>
        <span class="title">Kontakt zu PXL:</span>
        <p>Email:
          <a href="mailto:info@pxl-vision.com">info@pxl-vision.com</a> or
          <a href="mailto:support@pxl-vision.com">support@pxl-vision.com</a>
        </p>
        <p>Adresse: PXL Vision AG, Mühlebachstrasse 164, CH-8008 Zürich, Schweiz</p>
        <p>Kontakt Formular: <a href="https://pxl-vision.com/contactus/">https://pxl-vision.com/contactus/</a></p>
      </li>
    </ol>
    <p class="last-update">© PXL Vision AG, Stand 09/2020</p>
  </div>`,
  privacyPolicy: `
    <style>
    .terms-subtitle {
      font-size: 1.1rem;
      font-weight: bold;
    }
    .tac-content {
      font-size: 0.8rem;
    }
    .tac-content a {
      color: #e9425e;
      margin-top: 30px;
    }
    .tac-content div {
      margin-bottom: 10px;
    }
    .tac-content > ol> li >div:nth-child(2) {
      margin-top: 10px;
    }
    .tac-content ol {
      list-style-position: inside;
      padding-inline-start: unset;
      counter-reset: item;
      margin-block-start: 5px;
      text-align: justify;
      list-style-type: upper-roman;
    }
    .tac-content ol li::marker {
      font-weight: bold;
    }
    .tac-content ol.b {
      list-style-type: lower-alpha;
    }
    .tac-content ol.b > li,
    .tac-content ol.d > li,
    .tac-content ol.k > li {
      margin-left: 10px;
    }
    .tac-content li.n {
      text-align: start
    }
    .tac-content .lower-alpha {
      list-style-type: lower-alpha;
    }
    .tac-content .dash {
      list-style-type: none;
      padding: 0;
    }
    .tac-content .dash li {
      margin: 3px 0 3px 10px;
    }
    .tac-content .dash li::before {
      content: "- ";
      font-weight: bold;
    }
    .tac-content ol.d {
      list-style-type: decimal;
    }
    .tac-content ol.d .subheading {
      font-weight: bold;
    }
    .tac-content ol.d > li p {
      margin: 3px 0 3px 10px;
    }
    .tac-content .no-bullets {
      list-style-type: none;
      padding: 0;
    }
    .tac-content .no-bullets > li {
      padding: 3px 0 3px 10px;
    }
    .tac-content ol.k {
      list-style-type: none;
    }
    .tac-content ol.k li {
      margin-left: 10px;
    }
    .tac-content ol.k subheading {
      font-weight: bold;
    }
    .tac-content div {
      padding-left: 10px;
    }
    .tac-content.m > li {
      margin-bottom: 10px;
    }
    .tac-content ol.k > li:before {
      font-weight: bold;
      content: "(" counter(item) ") ";
      counter-increment: item;
    }
    .tac-content ol.a > li::marker,
    .title {
      font-weight: bold;
      font-size: 1.1rem;
      margin-bottom: 10px;
    }
    .tac-content .title {
      font-weight: bold;
      font-size: 1.1rem;
    }
    ol.b > span {
      margin-bottom: 10px;
    }
    .tac-content > ol > li {
      margin-bottom: 30px;
    }
    </style>
      <div class="tac-content">
      <div class="terms-subtitle">
        <p>
          Datenschutzbedingungen PXL Vision AG zur Nutzung der Daego® Mobile App und der
          Daego® WebApp
        </p>
      </div>
      <ol class="a">
        <li>
          <span class="title">Das Produkt</span>
          <div>
            PXL Vision stellt Ihnen mit dem Produkt Daego® eine Möglichkeit zur digitalen
            Identifizierung bei von PXL unabhängigen Transaktionspartnern zur Verfügung, bei denen
            die Überprüfung eines Ausweisdokumentes anhand dessen Sicherheitsmerkmalen und der
            darauf enthaltenen Daten sowie im Rahmen eines Abgleiches des auf dem Ausweisdokument
            enthaltenen Fotos mit einem vom Nutzer aufgenommenen Selfievideo ermöglicht wird. Das
            verifizierte Ergebnis kann dann bei Transaktionspartnern zur Verifikation der
            Identität bei einer geforderten Ausweiskontrolle genutzt werden.
          </div>
          <div>
            Daego® kann auf zwei verschiedene Weisen eingesetzt werden: Im Rahmen der Daego®
            Mobile App zur Installation aus einem Smartphone sowie über die Daego® WebApp zur
            Nutzung über den Internetbrowser.
          </div>
          <div>
            Im Rahmen der Mobile App ist die Identifizierung nur einmal nötig und die App kann zur
            Identifizierung bei mehreren Transaktionspartnern genutzt werden, weil die
            Identifikationsdaten und das Validierungsergebnis in der App gespeichert sind. Bei der
            WebApp ist jeweils für jede Transaktion ein Ausweisabgleich durchzuführen.
          </div>
        </li>
        <li>
          <span class="title">Die sichere Verarbeitung von Daten</span>
          <div>
            Die Verifikation von Ausweisdokumenten ist zwingend mit der Erhebung und Verarbeitung
            personenbezogener Daten aus dem gescannten Dokument sowie des Videos aus dem
            Identifikationsprozess verbunden. Dabei werden zur Durchführung des Abgleichs der
            Fotos auch besonders schützenswerte personenbezogene Daten (Biometriedaten des
            Gesichts des Nutzers) erhoben und verarbeitet.
          </div>
          <div>
            Die von PXL eingesetzten Prozesse zur Verarbeitung dieser Daten unterliegen strengen
            datenschutzrechtlichen Massgaben und werden durch entsprechend sichere technische und
            organsiatorische Massnahmen abgesichert. Die Server von PXL sind ausschliesslich in
            der Schweiz gelegen, eine Datenweitergabe an Dritte oder in andere Länder findet
            (mit Ausnahme der Übermittlung an die jeweils konkret bei der Identifizierung
            genannten Transaktionspartner) nicht statt.
          </div>
        </li>
        <li>
          <span class="title">
            Weiterverarbeitung zu anderen Zwecken (Entwicklungszwecken) gem. Art. 13 Abs. 4 DSGVO
            und Ihre gesonderte Einwilligung nach Art. 9 Abs. 2 DSGVO
          </span>
          <div>
            Die im Rahmen des Identifikations- und Verifikationsprozesses erhobenen und
            verarbeiteten Daten werden grundsätzlich nur zur Erbringung der Identifikation im
            jeweiligen Einzelfall verarbeitet und nicht zu anderen Zwecken verwendet oder gar an
            Dritte übermittelt oder verkauft.
          </div>
          <div>
            Der Verifikationsprozess basiert allerdings auf komplexen Analysealgorithmen, die
            einer ständigen Verbesserung bedürfen, um ein zuverlässiges Analyseergebnis zu
            ermöglichen. Die Verarbeitung dieser Daten zu diesem, von der reinen
            Identifikationsleistung abweichenden Zweck, ist gem. Datenschutzgesetzen vom
            ausdrücklichen Einverständnis des betroffenen Nutzers abhängig. PXL fragt daher dieses
            Einverständnis vor der Nutzung des Produktes vom Nutzer ab. Die erhobenen Daten werden
            dann im Falle des Einverständnisses des Nutzers auf separaten, ebenfalls
            ausschliesslich in der Schweiz gelegenen Servern gespeichert und dienen dann zur
            Verbesserung der Algorithmen. Auch hier gelten selbstverständlich strengte technische
            und organisatorische Schutzmassnahmen. Die mit der Analyse der Daten betrauten
            Mitarbeiter sind gesondert auf die strenge Einhaltung von Datenschutz und
            Vertraulichkeit verpflichtet.
          </div>
          <div>
            Zur Gewährleistung einer fairen und transparenten Verarbeitung informieren wir Sie
            hiermit gem. Art. 13 Abs. 2 DSGVO über die Verarbeitung dieser Daten im Rahmen
            dieses Zweckes:
            <ol class="b">
              <li>
                <span class="subheading">Speicherdauer und Kriterien für die Speicherdauer</span>
                <div>
                  Grundsätzlich erfordert der wirkungsvolle Einsatz von Machine Learning Prozessen
                  Algorithmen mit einer möglichst umfassenden Datenbasis. Daher ist die Vorhaltung
                  der Daten grundsätzlich notwendig und sinnvoll. Eine Löschung erfolgt, sofern
                  Sie bezüglich Ihrer Daten eine Löschung verlangen bzw. deren Nutzung
                  widersprechen, was eine unverzügliche Löschung zur Folge hat.
                </div>
              </li>
              <li>
                <span class="subheading">Jederzeitiges Widerspruchsrecht</span>
                <div>
                  Ihren Widerspruch zur Nutzung Ihrer Daten für die Machine Learning Prozesse
                  können Sie jederzeit unter
                  <a href="mailto:privacy@pxl-vision.com">privacy@pxl-vision.com</a>
                  anfordern. Dies berührt nicht die Rechtmässigkeit der Aufgrund der
                  Einwilligung bis zum Widerruf erfolgten Verarbeitung.
                </div>
              </li>
              <li>
                <span class="subheading">Beschwerderecht bei einer Aufsichtsbehörde</span>
                <div>
                  Sie haben das Recht, sich über die Verarbeitung der Daten bei einer
                  Aufsichtsbehörde zu beschweren, nähere Informationen finden Sie unten unter
                  Ziffer IV.9.d.
                </div>
              </li>
              <li>
                <span class="subheading">Gesetzlich oder vertraglich vorgeschrieben</span>
                <div>
                  Die Erhebung und Verarbeitung Ihrer Daten im Rahmen des Machine Learnings
                  erfolgt ausschliesslich aufgrund Ihrer freiwilligen Einwilligung.
                </div>
              </li>
              <li>
                <span class="subheading">Automatische Entscheidungsfindung und Profiling</span>
                <div>
                  Die Machine Learning Prozesse und die damit verbundene Auswertung der
                  biometrischen Daten zum Zwecke der Verbesserung der Identitätsprüfung dient
                  nicht zu Zwecken des Profilings oder über ein Profiling. Es wird anhand der
                  biometrischen und Identifikationsdaten von PXL keine Bewertung der persönlichen
                  Aspekte einer Person vorgenommen, die Sie als Betroffene in Bezug auf
                  persönliche Eigenschaften, Alter, Interessen, Gesundheit, wirtschaftliche Lage,
                  Aufenthaltsort der Ortswechsel oder Verhalten analysieren oder kategorisieren
                  würden und damit rechtliche Beeinträchtigungen für Sie hervorrufen könnten. Die
                  mit Ihrer Einwilligung erhobenen und verarbeiteten Daten dienen ausschliesslich
                  dem algorithmischen Abgleich Ihres Gesichts mit den im Ausweisdokument
                  vorgehaltenen Daten. Im Rahmen einer Identitätsprüfung über die App findet bei
                  positivem Abgleich eine automatische Entscheidung über die Verifikation statt,
                  die aber von Ihnen freiwillig beim Einsatz des Verifikationsprozesses zum
                  Einsatz bei einem von Ihnen gewählten Transaktionspartner initiiert wird. Gerade
                  um diese automatische Entscheidung so zuverlässig wie möglich ausfallen zu
                  lassen, ist die Verwendung der biometrischen Daten ausschliesslich zu diesem
                  Zweck im Rahmen der Machine Learning Prozesse von besonderer Bedeutung. Ihre
                  Daten werden zu keinem anderen Zweck verarbeitet und die automatische
                  Entscheidung bei der Verifikation beruht ausschliesslich auf dem algorithmischen
                  Abgleich Ihrer biometrischen Gesichtsdaten ohne jegliche sonstige Bewertung
                  derselben.
                </div>
              </li>
            </ol>
          </div>
        </li>
        <div>
          <b>
            PXL dankt Ihnen für Ihre Bereitschaft, mit Ihrer Einwilligung die Verbesserung der
            Prozesse zu unterstützen. Selbstverständlich können Sie Ihre Einwilligung per E-Mail an
            <a href="mailto:privacy@pxl-vision.com"> privacy@pxl-vision.com</a>
             jederzeit widersprechen.
          </b>
          Die Daten werden dann unverzüglich gelöscht.
        </div>
        <li class="n">
          <span class="title">Datenschutzrechtliche Informationspflichten</span>
          <div>
            <ol class="d">
              <li>
                <span class="subheading">Datenschutzrechtlich verantwortlich verarbeitende Stelle</span>
                <ul class="no-bullets">
                  <li>PXL Vision AG</li>
                  <li>Mühlebachstrasse 164</li>
                  <li>8008 Zürich, Schweiz</li>
                </ul>
              </li>
              <li>
                <span class="subheading">Kontaktdaten des Datenschutzbeauftragten</span>
                <p>
                  <a href="mailto:privacy@pxl-vision.com">privacy@pxl-vision.com</a>
                </p>
              </li>
              <li>
                <span class="subheading">
                  Kategorien personenbezogener Daten, die verarbeitet werden
                </span>
                <ol class="lower-alpha">
                  <li>
                    <span class="subheading">
                      Bei Aufruf der Mobile App erheben und verarbeiten wir folgende Nutzungsdaten:
                    </span>
                    <ul class="dash">
                      <li>Die Identifkationsnummer Ihres Endgerätes (UDID),</li>
                      <li>Endgerätetyp, </li>
                      <li>Betriebssystem des Endgerätes</li>
                      <li>Browserversion</li>
                    </ul>
                  </li>
                  <li>
                    <span class="subheading">
                      Bei Aufruf der WebApp erheben und verarbeiten wir folgende Nutzungsdaten:
                    </span>
                    <ul class="dash">
                      <li>Die Identifkationsnummer Ihres Endgerätes (UDID),</li>
                      <li>Endgerätetyp,</li>
                      <li>Betriebssystem des Endgerätes</li>
                      <li>Browserversion</li>
                      <li>Sitzungscookie (für  load balancing)</li>
                    </ul>
                  </li>
                  <li>
                    <span class="subheading">
                      Wenn Sie einen Verifikationsvorgang einleiten und durchführen erheben und
                      verarbeiten wir folgende personenbezogenen Daten (Identifikationsdaten):
                    </span>
                    <ol class="k">
                      <li>
                        <span class="subheading">Stammdaten: Personalausweisdaten</span>
                        <div>
                          Familienname, Geburtsname, Vornamen, Doktorgrad, Tag der Geburt, Ort der
                          Geburt, Anschrift, Staatsangehörigkeit, Dokumentenart, letzter Tag der
                          Gültigkeitsdauer, dienste- und kartenspezifisches Kennzeichen,
                          Länderkürzel, Angabe, ob ein bestimmtes Alter über- oder unterschritten
                          wird, Angabe, ob ein Wohnort dem abgefragten Wohnort entspricht, und
                          Ordensname, Künstlername, Ausweisnummer
                        </div>
                      </li>
                      <li>
                        <span class="subheading">Bilddateien</span>
                        <div>
                          Wir erheben sowohl ein Foto beider Seiten ihres Ausweisdokumentes, Ihr
                          Personenfoto vom Ausweis bzw. (falls anwendbar und verfügbar) aus den
                          NFC Daten Ihres ID-Dokuments und das Selfievideo Ihres Gesichts
                        </div>
                      </li>
                      <li>
                        <span class="subheading">Besondere Arten personenbezogener Daten</span>
                        <div>
                          Aus den Bilddaten werden biometrische Daten analysiert.
                        </div>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <span class="subheading">
                      Wenn Sie sich Im Rahmen einer Nutzeranfrage an die in der Mobile
                      App/Web App angegebene Kontaktadresse wenden:
                    </span>
                    <ol class="k">
                      <li>
                        <span class="subheading">Nutzerdaten:</span>
                        <div>
                          Ihr Name und Ihre Adresse, sowie ggf. E-Mail Adresse oder Telefonnummer,
                          die Sie im Rahmen der Nutzeranfrage angeben.
                        </div>
                      </li>
                      <li>
                        <span class="subheading">Ggf. oben angegebene Indentifikationsdaten,</span>
                        <div>wenn dies zur Bearbeitung der Nutzeranfrage erforderlich ist.</div>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <span class="subheading">Sonstiges</span>
                    <div>
                      Die App nimmt im Falle von Fehlfunktionen Kontakt zu einem
                      Fehler-Reportingtool auf um die Fehler zu beheben und die Nutzererfahrung
                      zu verbessern. Hierbei können Daten des Endgerätes (Browser, Betreibssystem)
                      übermittelt werden.
                    </div>
                  </li>
                </ol>
              </li>
              <li>
                <span class="subheading">
                  Zwecke, für die die personenbezogenen Daten verarbeitet werden sollen, sowie die
                  Rechtsgrundlage für die Verarbeitung
                </span>
                <ol class="lower-alpha">
                  <li>
                    <span class="subheading">
                      In den folgenden Fällen erheben und verarbeiten wir Daten im Rahmen eines
                      berechtigten Interesses nach Art. 6 Abs. 1 Lit f) DSGVO:
                    </span>
                    <ol class="k m">
                      <li>
                        <span>
                          Nutzungs- / Metadaten zur Verbesserung der Nutzungserfahrung der App/WebApp
                        </span>
                      </li>
                      <li>
                        <span>
                          Nutzungsdaten zur Behebung von Fehlern an der Software oder den Prozessen
                        </span>
                      </li>
                      <li>
                        <span>
                          Anonymisierung der Nutzungsdaten zu statistischen Zwecken
                        </span>
                      </li>
                      <li>
                        <span>
                          Identifikationsdaten und Fotodateien zum Zwecke der Fehlerüberprüfung
                          nach einer abgeschlossenen Verifikation und Transaktion. Hierbei werden
                          auch Daten im Falle einer erfolglosen Verifikation gespeichert um ggf.
                          nachträglichen Fehlern oder Beschwerden nachkommen zu können.
                        </span>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <span class="subheading">
                      In den folgenden Fällen erheben und verarbeiten wir Daten im Rahmen einer
                      Einwilligung  gem. Artikel 6 Abs. 1 Lit. a) oder Artikel 9 Abs. 2 Lit. a)
                      DSGVO
                    </span>
                    <ol class="k m">
                      <li>
                        <span>
                          Identifikationsdaten und biometrische, aus Ausweisfoto und Selfievideo
                          generierte Daten zur Durchführung des Identifikations- und
                          Verifikationsprozesses
                        </span>
                      </li>
                      <li>
                        <span>
                          Identifikationsdaten und biometrische, aus Ausweisfoto und Selfievideo
                          generierte Daten zur Verbesserung der Identifikationsverfahren im Rahmen
                          des Machine Learnings und Prozessen künstlicher Intelligenz. Nähere
                          massgebliche Informationen zur Weiterverarbeitung zu diesem Zweck
                          entnehmen Sie bitte der ausführlichen Erklärung oben in Ziffer III.
                        </span>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <span class="subheading">
                      In den folgenden Fällen erheben und verarbeiten wir Daten zur Erbringung der
                      vertraglich vereinbarten Leistung gem. Art. 6 Abs. 1 Lit. b)
                    </span>
                    <ol class="k m">
                      <li>
                        <span>
                          Erhebung, Verarbeitung und Übermittlung der Identifikationsdaten zum
                          Abgleich mit dem beim Transaktionspartner vorhandenen Datensatz. Bei
                          erfolgreicher Verifizierung erfolgt eine Übermittlung an den
                          Transaktionspartner über eine gesicherte Verbindung.
                        </span>
                      </li>
                      <li>
                        <span>
                          Erhebung, Verarbeitung und Übermittlung der Fotos und des Selfievideos
                          zur Durchführung des Abgleichs von Ausweisfoto und bei der Nutzung
                          aufgenommener Person sowie zur Bereitstellung dieser Daten an den
                          Transaktionspartner zum durch diesen selbst zu bestätigendem Abgleich
                          mit seinen eigenen Daten. Nach Abschluss des Identifikations
                          Verifikationsprozesses erfolgt eine Zurverfügungstellung der Daten an
                          den Transaktionspartner über eine gesicherte Verbindung. Dies erfolgt
                          auch in dem Fall, dass eine Verifikation sich nicht als erfolgreich
                          herausgestellt hat
                        </span>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <span class="subheading">
                  Empfänger oder Kategorien von Empfängern der personenbezogenen Daten
                </span>
                <ol class="lower-alpha">
                  <li>
                    <span class="subheading">Keine Übermittlung im Rahmen des Verifikationsprozesses an Dritte</span>
                    <div>
                      Im Rahmen des Verifikationsprozesses werden die Daten nicht an Dritte
                      übermittelt, sondern verbleiben bis zur erfolgreich beendeten Verifikation
                      der Identität ausschliesslich auf den Servern von PXL in der Schweiz.
                    </div>
                  </li>
                  <li>
                    <span class="subheading">Übermittlung an Transaktionspartner</span>
                    <div>
                      Nach jeder erfolgreich durchgeführten Verifikation werden Sie von der App
                      bzw. der WebApp ausdrücklich um Ihre Einwilligung gefragt, ob das Ergebnis
                      der Verifikation sowie die Übertragung der erhobenen Daten zum Abgleich und
                      ggf. weiteren Speicherung und Verarbeitung durch den Transaktionspartner and
                      diesen Ihnen bereits bekannten und im Rahmen des Verifikationsprozesses
                      nochmals ausdrücklich und konkret benannten Transaktionspartner übermittelt
                      werden darf. Nach erfolgter Einwilligung übermittelt PXL diese Daten an den
                      benannten Transaktionspartner über eine gesicherte Verbindung. Für die
                      weitere Verarbeitung ist ab diesem Zeitpunkt ausschliesslich der
                      Transaktionspartner Ihnen gegenüber verantwortlich. Bitte informieren Sie
                      sich vor der Einwilligung über dessen Datenschutzbestimmungen.
                    </div>
                  </li>
                </ol>
              </li>
              <li>
                <span class="subheading">
                  Ort der Datenverarbeitung / Übermittlung von Personenbezogenen Daten in ein Drittland
                </span>
                <div>
                  PXL verarbeitet Ihre personenbezogenen Daten ausschliesslich auf Servern in der
                  Schweiz, die mit dem Stand der Technik entsprechenden technischen und
                  organisatorischen Massnahmen geschützt sind und dem SOC 2 Standard entsprechen.
                </div>
                <div>
                  PXL Vision übermittelt Ihre Daten grundsätzlich nicht in Drittländer, sofern Sie
                  dem nicht ausdrücklich im Rahmen einer Transaktion zugestimmt haben, mit
                  Ausnahme der im nächsten Absatz beschriebenen vertraglich an PXL gebundenen
                  Dienstleister. Wenn ein Transaktionspartner in einem Drittland ansässig sein
                  sollte, welches nicht dem Europäischen Wirtschaftsraum zugehörig sein sollte,
                  bzw. welches nicht hinreichend anerkannte Datenschutzbestimmungen einhält,
                  obliegt es Ihrer Entscheidung, ob Sie mit den vom konkreten Transaktionspartner
                  angegebenen Datenschutzinformationen einverstanden sind und der Übermittlung
                  durch PXL zustimmen.
                </div>
                <div>
                  Dienstleister, die in unserem Auftrag ausserhalb der Europäischen Union
                  (sogenannte Drittländer) personenbezogene Daten verarbeiten, werden nur
                  eingesetzt, wenn für dieses Drittland ein „Angemessenheitsbeschluss“ der
                  Europäischen Kommission (Art. 45 DS-GVO) besteht, „geeignete Garantien“
                  (Art. 46 DS-GVO) oder „interne Datenschutzvorschriften“ (Art. 47 DS-GVO)
                  beim Empfänger vorliegen. Allgemeine Informationen zu den
                  Angemessenheitsbeschlüssen können Sie unter
                  <a href="https://ec.europa.eu/info/law/lawtopic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eucountries_de">
                    https://ec.europa.eu/info/law/lawtopic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eucountries_de</a>, zu
                    den vorliegenden geeigneten Garantien unter
                  <a href="https://ec.europa.eu/info/law/lawtopic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_de">
                    https://ec.europa.eu/info/law/lawtopic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_de</a> und
                    zu den internen Datenschutzvorschriften unter
                  <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/bindingcorporate-rules_de">
                    https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/bindingcorporate-rules_de</a> abrufen. Für weitere Informationen können Sie sich an unseren
                  Datenschutzbeauftragten wenden. Im Übrigen werden Ihre personenbezogenen
                  Daten in Drittländern verarbeitet, soweit es zur Erfüllung des Vertrages
                  erforderlich ist, Sie eingewilligt haben oder eine gesetzliche
                  Verpflichtung besteht.
                </div>
                <div>
                  Mitarbeiter unseres Unternehmens und Dienstleister, die uns bei der
                  Datenverarbeitung im Rahmen der Auftragsverarbeitung unterstützen
                  (Dienstleister für IT-Betrieb, Kundenservice), haben zur Erfüllung der
                  unten genannten Zwecke im erforderlichen Umfang Zugriff auf Ihre
                  personenbezogenen Daten. Ggf. bestehende Abweichungen sind im Folgenden
                  ausdrücklich bezeichnet. Gesetzlich sind wir im Einzelfall verpflichtet,
                  personenbezogenen Daten an Behörden (z.B. Auskunftsersuchen von
                  Ermittlungsbehörden) oder natürliche/juristische Personen (z.B. zur
                  Geltendmachung von Ansprüchen aus dem Urheberrechtsgesetz) zu übermitteln.
                </div>
              </li>
              <li>
                <span class="subheading">Dauer, für die die personenbezogenen Daten gespeichert werden</span>
                <ol class="lower-alpha">
                  <li>
                    <span class="subheading">Operatives System</span>
                    <div>
                      Nach Abschluss einer Transaktion über die MobileApp oder WebApp werden die
                      Daten im operativen System von PXL nach Abruf durch den Transaktionspartner
                      sofort wieder gelöscht.
                    </div>
                  </li>
                  <li>
                    <span class="subheading">Speicherung in der Mobile App</span>
                    <div>
                      Die im Rahmen der Mobile App erhobenen Daten werden ansonsten
                      ausschliesslich auf dem Endgerät des Nutzers so lange gespeichert, wie
                      dieser die App installiert hat bzw. er diese nicht selbst in der App
                      gelöscht hat. Die Daten werden nicht gleichzeitig auch bei PXL weiter
                      vorgehalten, sondern sind nur auf dem Endgerät vorhanden.
                    </div>
                  </li>
                  <li>
                    <span class="subheading">Speicherung für Machine Learning</span>
                    <div>
                      Bezüglichd er von Ihnen eingewilligten Speicherung zu Zwecken
                      von Machine Learning lesen Sie bitte Ziffer III.
                    </div>
                  </li>
                </ol>
              </li>
              <li>
                <span class="subheading">Automatisierte Entscheidungsfindung</span>
                <div>
                  Bei erfolgreichem Abgleich der Identifikations- und biometrischen Daten erfolgt
                  eine automatische Entscheidung über die Identität des Nutzers mit den von ihm
                  gescannten Dokumenten. Diese Entscheidung beruht nicht auf persönlichen
                  Eigenschaften des Nutzers, wie Alter, Geschlecht, Interessen, Kenntnissen etc.,
                  sondern allein auf dem algorithmischen Abgleich der Bilddateien und dem Abgleich
                  der Identifikationsdaten. Ein Profiling findet nicht statt.
                </div>
              </li>
              <li>
                <span class="subheading">Ihre Rechte</span>
                <ol class="lower-alpha">
                  <li>
                    <span class="subheading">
                      Bestehen eines Rechts auf Berichtigung oder Löschung der sie
                      betreffenden personenbezogenen Daten
                    </span>
                    <div>
                      In Bezug auf die personenbezogenen Daten, deren Verwendung Sie zu Machine
                      Learning Zwecken zugestimmt haben, haben Sie ein jederzeitiges
                      Widerspruchs- und Löschungsrecht, welches Sie mit einer E-Mail an
                      <a href="mailto:privacy@pxl-vision.com">privacy@pxl-vision.com</a>
                      ausüben können. Dies berührt nicht die Rechtmässigkeit der Aufgrund
                      der Einwilligung bis zum Widerruf erfolgten Verarbeitung.
                    </div>
                    <div>
                      Sollte Ihnen im Rahmen des Verifikationsprozesses über die App oder die
                      WebApp ein Fehler bei den erhobenen Daten auffallen, sollten Sie den
                      Verifikationsprozess abbrechen und uns über die E-Mailadresse
                      <a href="mailto:support@pxl-vision.com">support@pxl-vision.com</a>
                      hierüber informieren.
                    </div>
                    <div>
                      Nach einer erfolgreichen Transaktion werden die Daten auf den operativen
                      Systemen von PXL gelöscht und eine Korrektur ist nicht mehr möglich.
                      Bezüglich der zu Revisionszwecken gem. Ziffer IV.7.b gespeicherten Daten
                      (auch im Falle einer erfolglosen Verifikation oder des Abbruchs des
                      Verifikationsprozesses) wenden Sie sich bitte ebenfalls an die o.a.
                      Kontaktdaten.
                    </div>
                    <div>
                      ANach der Übermittlung an den Transaktionspartner nach Ihrer Einwilligung,
                      wenden Sie sich bezüglich der Korrektur der an diesen übermittelten Daten
                      bitte direkt an diesen. PXL hat auf diese Daten keinen Zugriff mehr.
                    </div>
                  </li>
                  <li>
                    <span class="subheading">
                      Bestehen eines Rechts auf Auskunft seitens des Verantwortlichen über
                      die betreffenden personenbezogenen Daten
                    </span>
                    <div>
                      Sie können jederzeit gem. Art. 15 DSGVO bei PXL über die oben in Ziffer
                      IV.1 und IV.2 angegebenen Kontaktdaten Auskunft über die Verarbeitung Ihrer
                      personenbezogenen Daten verlangen. Wir bitten um Verständnis, dass wir in
                      diesem Fall geeignete Massnahmen zur Sicherstellung Ihrer Identität als
                      richtigem Empfänger der Datenauskunft sicherstellen werden.
                    </div>
                  </li>
                  <li>
                    <span class="subheading">
                      Widerspruchsrecht gegen die Verarbeitung sowie des Rechts auf Datenübertragbarkeit
                    </span>
                    <div>
                      Sie können jederzeit bei PXL über die oben in Ziffer IV.1 und IV.2
                      angegebenen Kontaktdaten Widerspruch gegen die Verarbeitung Ihrer
                      Daten einlegen. Nach erfolgter Transaktion findet keine Übertragung
                      an Dritte mehr statt.
                    </div>
                  </li>
                  <li>
                    <span class="subheading">Beschwerderecht bei der Aufsichtsbehörde</span>
                    <div>
                      Sie haben überdies das Recht auf Beschwerde bei einer Aufsichtsbehörde
                      (Art. 77 DS-GVO). Sie können sich hierzu an die
                      Datenschutzaufsichtsbehörde wenden.
                    </div>
                  </li>
                  <li>
                    <span class="subheading">Datenauszug</span>
                    <div>
                      Sie haben ein Recht auf Erhalt oder Übertragung der Sie betreffenden
                      personenbezogenen Daten (Art. 20 DS-GVO). Wenden Sie sich hierzu an
                      die o.a. Kontaktdaten.
                    </div>
                  </li>
                </ol>
              </li>
              <li>
                <span class="subheading">Sichere Kommunikation</span>
                <div>
                  Für die Übermittlung vertraulicher Informationen empfehlen wir Ihnen die
                  Kontaktaufnahme per Telefon, Post oder verschlüsseltem Kontaktformular zu
                  wählen. Sollten Sie z.B. per E-Mail, Social Media, Messenger Diensten
                  (wie WhatsApp) oder auf anderen Wegen mit uns in Kontakt treten, so kann die
                  vollständige Datensicherheit nicht gewährleistet werden.
                </div>
              </li>
              <li>
                <span class="subheading">Änderungen der Datenschutzbestimmungen</span>
                <div>
                  Da Gesetzesänderungen oder Änderungen unserer unternehmensinterner Prozesse
                  eine Anpassung dieser Datenschutzbestimmungen erforderlich machen können, die
                  wir uns entsprechend vorbehalten, können Sie die aktuelle Version ebenso wie
                  altere Versionen der Datenschutzhinweise unter pxl-vision/privacy abrufen.
                </div>
              </li>
            </ol>
            <p>© PXL-Vision AG, Stand Oktober 2020</p>
          </div>
        </li>
      </ol>
    </div>`
};
