import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import SuccessPictogram from 'components/successPictogram/SuccessPictogram';
import { ReactComponent as Frame } from 'assets/images/frame-rect.svg';
import { ReactComponent as FailPic } from 'assets/images/fail-icon.svg';

type Props = {
  message: number,
  messageCallback?: any,
  isActionTimeouted?: boolean,
};

type State = {
  overlay: boolean
};

const Actions = {
  resume: 'resume',
  exit: 'exit',
};

export default class QrCodeMessage extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      overlay: false,
    };
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.message === 2) {
      setTimeout(() => {
        nextProps.messageCallback();
      }, 1000);
    }

    if ([3, 4].includes(nextProps.message)) {
      return {
        overlay: true,
      };
    }

    return prevState;
  }

  render() {
    const { message, messageCallback } = this.props;
    const { overlay } = this.state;
    const innerFrameStyle: any = {};

    if (!overlay) innerFrameStyle.display = 'none';

    let frameColor = '';
    let messageHTML: any;
    switch (message) {
      case 2:
        messageHTML = <SuccessPictogram />;
        frameColor = 'success-frame';
        break;
      case 3:
        messageHTML = (
          <div className="inner-frame" style={innerFrameStyle}>
            <div>
              <div><FailPic className="fail-pic" /></div>
              <div className="text"><Translate i18nKey="qrcode-scanner.errorMessage" /></div>
              <div
                className="button button-big"
                role="button"
                tabIndex={-1}
                onClick={
                  () => {
                    this.setState({
                      overlay: false,
                    }, () => {
                      messageCallback(Actions.resume);
                    });
                  }
                }
                onKeyPress={() => { }}
              >
                <Translate i18nKey="qrcode-scanner.btn.resume" />
              </div>
              <div
                className="button button-link"
                role="button"
                tabIndex={-1}
                onClick={
                  () => {
                    this.setState({
                      overlay: false,
                    }, () => {
                      messageCallback(Actions.exit);
                    });
                  }
                }
                onKeyPress={() => { }}
              >
                <Translate i18nKey="qrcode-scanner.btn.exit" />
              </div>
            </div>
          </div>
        );
        frameColor = 'error-frame';
        break;
      case 4:
        messageHTML = (
          <div className="inner-frame" style={innerFrameStyle}>
            <div>
              <div><FailPic className="fail-pic" /></div>
              <div className="text"><Translate i18nKey="qrcode-scanner.timeOutMessage" /></div>
              <div
                className="button button-big"
                role="button"
                tabIndex={-1}
                onClick={
                  () => {
                    this.setState({
                      overlay: false,
                    }, () => {
                      messageCallback(Actions.resume);
                    });
                  }
                }
                onKeyPress={() => { }}
              >
                <Translate i18nKey="qrcode-scanner.btn.resume" />
              </div>
              <div
                className="button button-link"
                role="button"
                tabIndex={-1}
                onClick={
                  () => {
                    this.setState({
                      overlay: false,
                    }, () => {
                      messageCallback(Actions.exit);
                    });
                  }
                }
                onKeyPress={() => { }}
              >
                <Translate i18nKey="qrcode-scanner.btn.exit" />
              </div>
            </div>
          </div>
        );
        frameColor = 'error-frame';
        break;
      default:
        break;
    }

    return (
      <div className="comp-qrcode-message">
        <div className="frame">
          <Frame className={`qrcode-frame  ${message === 1 ? 'animatable' : ''} ${frameColor}`} />
          <div className="spacer">
            {messageHTML}
          </div>
        </div>
      </div>
    );
  }
}
