import React from 'react';

export default React.createContext({
  history: {},
  lang: {},
  setLang: (() => { }) as any,
  urlParams: {},
  leaveApp: (() => { }) as any,
  closeButtonOnErrorScreens: false,
});
