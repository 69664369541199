import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import LangSwitch from 'components/langSwitch/LangSwitch';
import { LogoPosition } from 'components/header/types.d';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as Back } from 'assets/images/arrow-left.svg';
import AppContext from 'modules/AppContext';

interface IProps {
  icons?: string,
  goBack?: () => void,
  logoPosition?: LogoPosition,
  loading?: boolean,
}

class Header extends Component<IProps> {
  goBack = (): void => {
    const { loading } = this.props;
    if (loading) return;

    const { goBack } = this.props;
    if (goBack && typeof goBack === 'function') {
      goBack();
    } else {
      const { history, setView } = this.context;
      const { prevView, viewsList } = history;
      if (prevView === viewsList[0]) {
        window.history.back();
      } else {
        setView(prevView);
      }
    }
  };

  render(): React.ReactNode {
    let { icons, logoPosition } = this.props;
    if (icons === undefined) icons = '';
    if (logoPosition === undefined) logoPosition = LogoPosition.center;
    let logoCenterClass = (logoPosition === LogoPosition.center) ? 'logo-center' : '';
    if (icons.includes('back')) logoCenterClass = '';
    return (
      <header className={logoCenterClass}>
        <Logo className="logo" />
        {icons === 'back' && (
          <div className="progress-icons">
            <span
              className="back-button"
              role="button"
              onClick={this.goBack}
              onKeyPress={this.goBack}
              tabIndex={-1}
            >
              <Back className="icon-back" />
              {' '}
              <Translate i18nKey="header.btn.back" />
            </span>
          </div>
        )}
        <LangSwitch />
      </header>
    );
  }
}
Header.contextType = AppContext;

export default Header;
