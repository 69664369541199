import React, { Component } from 'react';
import Translate from 'components/translate/Translate';

type Props = {
  onClickHandler: any
};

type State = Record<string, never>;

export default class IdDocumentScanInstructionsManual extends Component<Props, State> {
  render(): React.ReactNode {
    const { onClickHandler } = this.props;

    return (
      <div className="inner-frame">
        <div>
          <div className="text"><Translate i18nKey="dv.manual-capture.message" /></div>
          <div
            className="button button-big overlay-button"
            role="button"
            tabIndex={-1}
            onKeyPress={() => { }}
            onClick={onClickHandler}
          >
            <Translate i18nKey="dv.btn.ok" />
          </div>
        </div>
      </div>
    );
  }
}
