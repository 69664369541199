import React, { Component } from 'react';
import Translate from 'components/translate/Translate';

type Props = {
  onCompatibilityNotesTextClick: any
};
export default class StartText extends Component<Props> {
  render() {
    const { onCompatibilityNotesTextClick } = this.props;

    return (
      <div className="comp-start-text">
        <div className="text-caption">
          <Translate i18nKey="start.welcome-text" />
        </div>
        <div className="button-container">
          <div>
            <button
              className="button-link"
              type="button"
              onKeyUp={() => onCompatibilityNotesTextClick()}
              onClick={() => onCompatibilityNotesTextClick()}
            >
              <Translate i18nKey="start.compatibility-notes" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
