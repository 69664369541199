import React, { Component } from 'react';
import { ReactComponent as Logo } from 'assets/images/powered-by-logo.svg';
/* eslint-disable no-unused-vars */
type Props = {
  changePositionOfPoweredByLogo?: boolean,
  toggleTextColor?: boolean,
};

export default class PoweredByLogo extends Component<Props> {
  render() {
    const { changePositionOfPoweredByLogo, toggleTextColor } = this.props;
    const poweredText: string = toggleTextColor ? 'powered-text' : 'text';
    return (
      <div className={`comp-poweredby-logo ${changePositionOfPoweredByLogo ? 'powered-element' : ''}`}>
        <Logo className={poweredText} />
        <div>
          <div className={poweredText}>Powered by</div>
          <div className={poweredText}>PXL Vision</div>
        </div>
      </div>
    );
  }
}
