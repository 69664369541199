import React, { Component } from 'react';
import StarPic from 'assets/images/star.svg';
import StarPicActive from 'assets/images/star-active.svg';
import { generateKey } from 'utils';

type Props = {
  rating: number,
  changeRating: any
};

type State = null;

const amount = 7;

export default class RaitingItem extends Component<Props, State> {
  render() {
    const { rating, changeRating } = this.props;
    const stars: number[] = [];
    for (let i = 0; i < amount; i += 1) stars.push(i);
    return (
      <div className="comp-rating-item">
        {stars.map((i: number) => {
          const imgSrc: string = i < rating ? StarPicActive : StarPic;
          return (
            <img
              src={imgSrc}
              alt=""
              key={generateKey()}
              className="star"
              onClick={() => {
                if (i === 0 && rating === 1) {
                  changeRating(0);
                } else {
                  changeRating(i + 1);
                }
              }}
            />
          );
        })}
      </div>
    );
  }
}
