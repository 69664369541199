/* eslint-disable @typescript-eslint/no-unused-vars, no-unused-vars */
import { UA, IVibrateArgs, ModelDeviceInfo, ModelDeviceInfoDB, IPhoneModelKey, UserAgentData } from 'types/index.d';
import { Orientation, VibrationTunes, IPhoneModel } from 'types/enum';
/* eslint-enable no-unused-vars */



const safeMethods = ['POST', 'DELETE', 'UPDATE'];

const handleFetchErrors = async (resp: any) => {
  if (!resp.ok) {
    const resJson = await resp.json();
    if (resJson === 'wrong_time') {
      throw Error('Wrong time');
    }
    throw Error(resp.statusText);
  }
  return resp;
};

const fetch = (path: string, userOptions: any = {}) => {
  const defaultOptions = {
    credentials: 'same-origin',
  };
  const defaultHeaders = {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  };

  // Add condition to get information from meta tag.
  const metaTagElement = document.querySelector('meta[name=csrf-token]') as HTMLMetaElement;
  const csrfHeader = safeMethods.includes(userOptions.method) ?  { 'x-csrf-token': metaTagElement.content } : { };

  const headers = { ...defaultHeaders, ...(userOptions.headers && { ...userOptions.headers }), ... csrfHeader };
  const options = { ...defaultOptions, ...userOptions };

  options.headers = headers;

  // Contet-Type should be removed for requests which are sending FormData as body
  if (options.body instanceof FormData) {
    const contentTypeKey = Object.keys(options.headers)
      .find((key) => key.toLowerCase() === 'content-type');
    if (contentTypeKey) {
      delete options.headers[contentTypeKey];
    }
  }

  return window.fetch(`/api/v1${path}`, options)
    .then(handleFetchErrors);
};

const generateKey = () => Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);

const getCustomerForwardURL = (serviceProviderData: any): string => {
  if (!serviceProviderData || !serviceProviderData.data) {
    return '';
  }

  const { transaction, customer } = serviceProviderData.data;
  if (!transaction || !customer) {
    return '';
  }

  const { code } = transaction;
  const { forward_url: forwardURL } = customer;
  if (!code || !forwardURL) {
    return '';
  }

  const url: URL = new URL(forwardURL);
  url.searchParams.append('tc', code);
  return url.href;
};

const mergedObjects = (obj1: any, obj2: any, mergeDepth: number, maxDepth = 10) => {
  let depth = mergeDepth;
  const target: any = {};
  // Merge the object into the target object
  const merger = (obj: any) => {
    if (!obj) return;

    Object.keys(obj).forEach((prop: any) => {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (
          (depth !== maxDepth)
          && (Object.prototype.toString.call(obj[prop]) === '[object Object]')
        ) {
          // If the property is an object and max depth is not increased
          depth += 1;
          target[prop] = mergedObjects(target[prop], obj[prop], depth);
        } else {
          // Otherwise, do a regular merge
          target[prop] = obj[prop];
        }
      }
    });
  };

  merger(obj1);
  merger(obj2);
  return target;
};

/* eslint max-len: "off" */
const deepMerge = (obj1: any, obj2: any, maxDepth = 10) => mergedObjects(obj1, obj2, 0, maxDepth);

const mobileAndTabletCheck = () => {
  let check = false;
  (function checkUserAgend(a) {
    // eslint-disable-line no-useless-escape
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a)
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true; // eslint-disable-line no-useless-escape
  }(navigator.userAgent || navigator.vendor || (window as any).opera));
  return check;
};

const requestFullscreen = () => {
  if (window.document.documentElement.requestFullscreen) {
    window.document.documentElement.requestFullscreen()
      .catch(() => {
        // This function requires user action before it calls
        // If application starts from page that requires fullscreen then the call will fail
        // Ex: Document Verification page.
      });
  }
};

const closeFullscreen = () => {
  const doc = (window as any).document;
  if (!(doc.isFullScreen || doc.webkitIsFullScreen)) return;
  if (doc.exitFullscreen !== undefined) {
    doc.exitFullscreen();
  } else if (doc.mozCancelFullScreen !== undefined) { /* Firefox */
    doc.mozCancelFullScreen();
  } else if (doc.webkitExitFullscreen !== undefined) { /* Chrome, Safari and Opera */
    doc.webkitExitFullscreen();
  } else if (doc.msExitFullscreen !== undefined) { /* IE/Edge */
    doc.msExitFullscreen();
  }
};

const getUA = (): UA => {
  if ((navigator as any).userAgentData && (navigator as any).userAgentData.platform) {
    const { mobile: isMobile, brands, platform } = (navigator as any).userAgentData as UserAgentData;
    const isDesktop = !isMobile;
    const isApple = brands.some(({ brand }) => (/iPhone|iPad|iPod|Mac/i.test(brand)));
    const isIPhone = brands.some(({ brand }) => (/iPhone/i.test(brand)));
    const isChrome = brands.some(({ brand }) => (/Chrome/i.test(brand)));
    const isSafari = brands.some(({ brand }) => (/Safari/i.test(brand)) && !(/Chrome/i.test(brand)) && !(/CriOS/i.test(brand)));
    const isMiUiBrowser = brands.some(({ brand }) => (/MiuiBrowser/i.test(brand)));
    return {
      isDesktop, isMobile, isApple, isIPhone, isChrome, isSafari, isMiUiBrowser, platform,
    };
  }
  const ua = navigator.userAgent;
  const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua));
  const isApple = (/iPhone|iPad|iPod|Mac/i.test(ua));
  const isIPhone = (/iPhone/i.test(ua));
  const isChrome = (/Chrome/i.test(ua));
  const isSafari = (/Safari/i.test(ua)) && !(/Chrome/i.test(ua)) && !(/CriOS/i.test(ua));
  const isMiUiBrowser = (/MiuiBrowser/i.test(ua));
  const platform = ua.match(/\((.*?)\)/)?.[1] || '';
  const isDesktop = !isMobile;
  return {
    isDesktop, isMobile, isApple, isIPhone, isChrome, isSafari, isMiUiBrowser, platform,
  };
};

const getOrientation = (): Orientation => {
  const { isApple } = getUA();
  let orientation: Orientation;
  if (isApple) {
    const orientationN = window.orientation;
    if (orientationN === 180) {
      orientation = Orientation.portraitSecondary;
    } else if (orientationN === 0) {
      orientation = Orientation.portraitPrimary;
    } else if (orientationN === -90) {
      orientation = Orientation.landscapeSecondary;
    } else {
      orientation = Orientation.landscapePrimary;
    }
  } else {
    const screen: any = window.screen; // eslint-disable-line
    const orientationS: string = screen.msOrientation || screen.mozOrientation
      || (screen.orientation && screen.orientation.type) || 'landscape-primary';
    if (Orientation.portraitSecondary === orientationS) {
      orientation = Orientation.portraitSecondary;
    } else if (Orientation.portraitPrimary === orientationS) {
      orientation = Orientation.portraitPrimary;
    } else if (Orientation.landscapeSecondary === orientationS) {
      orientation = Orientation.landscapeSecondary;
    } else {
      orientation = Orientation.landscapePrimary;
    }
  }
  return orientation;
};

const hasGetUserMedia = (): boolean => !!(navigator.mediaDevices
  && navigator.mediaDevices.getUserMedia);

const deviceIsSupported = (): boolean => {
  const {
    isMiUiBrowser,
    isApple,
    isSafari,
    isMobile,
  } = getUA();
  return hasGetUserMedia()
    // For more details please see DGW-238 issue
    && !isMiUiBrowser
    && !(isMobile && isApple && !isSafari);
};

const desktopIsNotSupported = (isTranslateMode: boolean): boolean => {
  const {
    isMobile,
  } = getUA();
  return !isMobile && !isTranslateMode;
};

const defaultVibrationPatterns: IVibrateArgs['vibrationPatterns'] = {
  fail: [200, 100, 200, 100, 300],
  success: [200, 100, 300],
};

const vibrate = (tunes: VibrationTunes | number[]) => {
  if (!navigator.vibrate) return;

  let vibrationPattern: number[];
  if (tunes instanceof Array) vibrationPattern = tunes;
  else vibrationPattern = defaultVibrationPatterns[tunes];

  navigator.vibrate(vibrationPattern);
};

const isLandscapeOrientation = (val: any): val is (Orientation.landscapePrimary | Orientation.landscapeSecondary) => (
  val === Orientation.landscapePrimary
  || val === Orientation.landscapeSecondary
);

const iPhoneDb : ModelDeviceInfoDB = {
  ProMax14: {
    'screenWidth' : 430,
    'screenHeight': 932,
    'screenPixelRatio': 3
  },
  ProMax12ProMax13Plus14: {
    'screenWidth' : 428,
    'screenHeight': 926,
    'screenPixelRatio': 3
  },
  ProMax11XSMax: {
    'screenWidth' : 414,
    'screenHeight': 896,
    'screenPixelRatio': 3
  },
  Pro14: {
    'screenWidth' : 393,
    'screenHeight': 852,
    'screenPixelRatio': 3
  },
  IPhone12IPhone13IPhone14Pro12Pro13: {
    'screenWidth' : 390,
    'screenHeight': 844,
    'screenPixelRatio': 3
  },
  Pro11XSMini12Mini13IPhoneX: {
    'screenWidth' : 375,
    'screenHeight': 812,
    'screenPixelRatio': 3
  },
  IPhoneX: {
    'screenWidth' : 320,
    'screenHeight': 693,
    'screenPixelRatio': 3
  },
  IPhone11XR: {
    'screenWidth' : 414,
    'screenHeight': 896,
    'screenPixelRatio': 2
  },
  IPhone8SE: {
    'screenWidth' : 375,
    'screenHeight': 667,
    'screenPixelRatio': 2
  },
  Plus8: {
    'screenWidth' : 414,
    'screenHeight': 736,
    'screenPixelRatio': 2.6
  },
  Other: {
    'screenWidth' : -1,
    'screenHeight': -1,
    'screenPixelRatio': -1
  },
};

const getIphoneModel = (): IPhoneModelKey => {
  const deviceInfo : ModelDeviceInfo = {
    'screenWidth' : (window.screen.width > window.screen.height) ? window.screen.height : window.screen.width,
    'screenHeight': (window.screen.width > window.screen.height) ? window.screen.width : window.screen.height,
    'screenPixelRatio' : window.devicePixelRatio
  };

  for (const model in iPhoneDb) {
    const keymodel = model as IPhoneModelKey;
    const info = iPhoneDb[keymodel];

    if (info && 
      info.screenHeight == deviceInfo.screenHeight &&
      info.screenWidth == deviceInfo.screenWidth &&
      info.screenPixelRatio == deviceInfo.screenPixelRatio) {
      return keymodel;
    }
  }

  return IPhoneModel.Other;
};

export {
  generateKey,
  getCustomerForwardURL,
  fetch,
  handleFetchErrors,
  mergedObjects,
  deepMerge,
  mobileAndTabletCheck,
  requestFullscreen,
  closeFullscreen,
  getOrientation,
  getUA,
  deviceIsSupported,
  desktopIsNotSupported,
  vibrate,
  isLandscapeOrientation,
  getIphoneModel,
};
