import React, { Component } from 'react';
import Translate from 'components/translate/Translate';

import { fetch } from 'utils';
import Header from 'components/header/Header';
import Loader from 'components/loader/Loader';
import { SignStatus } from 'types/enum';
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { IMtan, ISignedContract } from 'types/index.d';
/* eslint-eanble no-unused-vars, @typescript-eslint/no-unused-vars */

/// TODO: remove after traansaction functionality implementation
import dummyFile from 'resources/dummy.json';

interface IProps {
  onDone: (data: any) => void;
  transactionCode: string;
  processId: string;
  mtan: IMtan,
  firstName: string;
  lastName: string;
}

interface IState {
  loading: boolean;
  signStatus: SignStatus;
  contract?: ISignedContract;
}

interface IContent {
  content?: React.ReactElement;
  button?: React.ReactElement;
  disabled: boolean;
}

const JOB_RUN_COUNT = 20;
const JOB_RUN_INTERVAL = 10000;

class SignForm extends Component<IProps, IState> {
  _counter = 0;

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      signStatus: SignStatus.notRequested,
    };
  }

  startJob = () => {
    const { transactionCode } = this.props;
    setTimeout(async () => {
      if (this._counter >= JOB_RUN_COUNT) {
        this._counter = 0;
        this.setState({ signStatus: SignStatus.rejected, loading: false });
        return;
      }
      this._counter++;
      const transaction = await fetch(`/sign/${transactionCode}`)
        .then((res) => res.json());
      if (transaction.status === SignStatus.pending) {
        this.startJob();
        return;
      }
      this.setState({ signStatus: transaction.status, contract: transaction, loading: false });
    }, JOB_RUN_INTERVAL);
  };

  sign = async () => {
    const { signStatus, contract } = this.state;
    const {
      transactionCode,
      processId,
      mtan: { phone, email, countryCode },
      firstName,
      lastName,
      onDone,
    } = this.props;
    if (signStatus === SignStatus.signed) {
      onDone(contract);
      return;
    }
    const config = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        transactionCode,
        processId: processId || 1,
        firstName,
        lastName,
        phone,
        email,
        countryCode,
        ...dummyFile,
      }),
    };
    this.setState({ loading: true }, async () => {
      try {
        const signData = await fetch('/sign', config).then((res) => res.json());
        if (signData.uploaded_file_url) {
          window.open(signData.uploaded_file_url, '_blank');
        }
        this.setState({
          signStatus: signData.status,
          loading: signData.status === SignStatus.pending
        }, () => {
          if (signData.status === SignStatus.pending) {
            this.startJob();
          }
        });
      } catch (e: any) {
        this.setState({ signStatus: SignStatus.rejected, loading: false });
      }
    });
  };

  getContent = (): IContent => {
    const { signStatus } = this.state;
    const texts:IContent = {
      disabled: false,
    };
    switch (signStatus) {
      case SignStatus.pending:
        texts.content = <Translate i18nKey="contract.pending-text" />;
        texts.button = <Translate i18nKey="contract.sign" />;
        texts.disabled = true;
        break;
      case SignStatus.signed:
        texts.content = <Translate i18nKey="contract.signed-text" />;
        texts.button = <Translate i18nKey="contract.continue" />;
        break;
      case SignStatus.rejected:
        texts.content = <Translate i18nKey="contract.rejected-text" />;
        texts.button = <Translate i18nKey="contract.retry" />;
        break;
      case SignStatus.notRequested:
      default:
        texts.content = <Translate i18nKey="contract.default-text" />;
        texts.button = <Translate i18nKey="contract.sign" />;
    }
    return texts;
  };

  render() {
    const { loading } = this.state;
    const contents = this.getContent();
    const inactive: string = (loading || contents.disabled) ? 'inactive' : 'active';
    return (
      <div className="comp-contract">
        <Header />
        <div className="card comp-contract-sign-page">
          <div className="card-header">
            <h1><Translate i18nKey="contract.title" /></h1>
            <hr />
          </div>
          <div className="card-body">
            <div className="content-text">{contents.content}</div>
            <div className="button-with-loader">
              <div
                className={`button-big ${inactive}`}
                role="button"
                onClick={() => !(loading || contents.disabled) && this.sign()}
                onKeyPress={() => !(loading || contents.disabled) && this.sign()}
                tabIndex={-1}
              >
                <span>{contents.button}</span>
                {loading && <Loader />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignForm;
