import React, { Component } from 'react';
import ReactCodeInput from 'react-code-input';
import Translate from 'components/translate/Translate';
import Header from 'components/header/Header';
import Loader from 'components/loader/Loader';

/// TODO: use email and phone to send verification code
interface IProps {
  transactionData: any;
  onClick: (isValidCode: boolean) => void;
  notReceivedData: () => void;
  goBack: () => void;
  verify: (code: string) => void;
}

interface IState {
  verificationCode: string;
  isValidCode: boolean;
  codeLoading: boolean;
}

export default class VerifyCode extends Component<IProps, IState> {
  private codeLength: number;

  constructor(props: IProps) {
    super(props);

    this.codeLength = 6;
    this.state = {
      verificationCode: '',
      isValidCode: true,
      codeLoading: false,
    };
  }

  onChange = async (code: string) => {
    this.setState({ verificationCode: code });

    if (code.length < this.codeLength) return;

    this.setState({
      codeLoading: true,
      isValidCode: true,
    });
    const { verify } = this.props;
    try {
      await verify(code);
    } catch (e: any) {
      this.setState({
        isValidCode: false,
        codeLoading: false,
      });
    }
  };

  render() {
    const {
      verificationCode,
      isValidCode,
      codeLoading,
    } = this.state;
    const isActive = verificationCode.length === this.codeLength && !codeLoading;
    const inactiveClass = isActive ? 'active' : 'inactive';
    const {
      transactionData,
      notReceivedData,
      goBack,
      onClick,
    } = this.props;
    const allowedOptionLength = transactionData.mtan.allowed.length;

    return (
      <div className="comp-verify-code-verify">
        <Header icons="back" goBack={goBack} />
        <div className="card">
          <div>
            <h1 className="welcome verify-header">
              <Translate i18nKey="verify-code.verify-code-title" />
            </h1>
            <div>
              <div className="transaction-code-with-loader">
                <div className="tc-loader">
                  <ReactCodeInput
                    name="verificationCode"
                    inputMode="numeric"
                    type="number"
                    fields={this.codeLength}
                    autoFocus={true}
                    value={verificationCode}
                    isValid={isValidCode}
                    onChange={(code) => this.onChange(code)}
                  />
                  {codeLoading && <div className='loader-content'><Loader /></div>}
                </div>
              </div>
              {!isValidCode && <p className="error"><Translate i18nKey="verify-error-msg" /></p>}
            </div>
          </div>
          <div>
            <div
              className={`button-big ${inactiveClass}`}
              role="button"
              tabIndex={-1}
              onKeyUp={() => isActive && onClick(isValidCode)}
              onClick={() => isActive && onClick(isValidCode)}
            >
              <span>
                <Translate
                  i18nKey={!isValidCode ? 'verify-code.try-again-button' : 'verify-code.button-continue'}
                />
              </span>
            </div>
            <div
              className="make-sure"
              role="link"
              tabIndex={-1}
              onClick={() => notReceivedData()}
              onKeyPress={() => notReceivedData()}
            >
              {allowedOptionLength > 1 && <Translate i18nKey="verify-code.not-receive-link" />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
