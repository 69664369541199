import React, { Component } from 'react';
import Select from 'react-select';
import AppContext from 'modules/AppContext';

class LangSwitch extends Component {
  render() {
    const { urlParams = {}, lang: langConfig, setLang } = this.context;
    const { translate = false } = urlParams;

    if (process.env.REACT_APP_ENV === 'CROWDIN' && translate) return null;

    const { switchable, langs, currentLang, default: defaultLang } = langConfig;
    const lang = currentLang || defaultLang;

    if (!switchable) return null;

    return (
      <div>
        <div className="comp-lang-switch">
          <Select
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              IndicatorsContainer: () => null,
            }}
            classNamePrefix={'lang-switch'}
            value={{ value: lang, label: lang ? lang.toUpperCase() : lang }}
            placeholder={lang}
            onChange={({ value }) => setLang(value)}
            options={langs.map((langId: string) => ({
              value: langId,
              label: langId.toUpperCase(),
            }))}
            isSearchable={false}
          />
        </div>
      </div>
    );
  }
}

LangSwitch.contextType = AppContext;

export default LangSwitch;
