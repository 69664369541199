import React, { Component } from 'react';
import platform from 'platform';
import PoweredByLogo from 'components/poweredByLogo/PoweredByLogo';
import Frame from 'components/frame/Frame';
import Translate from 'components/translate/Translate';
import { getUA, getIphoneModel } from 'utils';
import { error, debug } from 'utils/log';
import {
  FacingModes,
  VideoStreamStatus,
  WhatToCapture,
} from 'resources/enums';
import { IPhoneModelKey, UserAgentData } from 'types/index.d';
import AppContext from 'modules/AppContext';

/* eslint-disable no-unused-vars */
enum CameraPermissions {
  granted = 'granted',
  denied = 'denied',
  prompt = 'prompt',
}

enum CameraCaptureType {
  landscape = 'landscape',
  portrait = 'portrait',
  portraitRotated = 'portrait-rotated',
}

enum Orientation {
  landscapePrimary = 'landscape-primary',
  landscapeSecondary = 'landscape-secondary',
  portraitPrimary = 'portrait-primary',
  portraitSecondary = 'portrait-secondary'
}
/* eslint-enable no-unused-vars */

export interface IAppearance {
  canvasClassNames?: string,
  videoClassNames?: string,
  videoStyle?: any,
  poweredByLogo?: any,
}

interface ICapturedImage {
  capturedImg: string;
  previewImage: string;
}

interface ICaptureSettings {
  sx: number,
  sy: number,
  sWidth: number,
  sHeight: number,
  cWidth: number,
  cHeight: number,
}

type Props = {
  appState: any,
  facingMode?: FacingModes,
  appearanceProps?: IAppearance,
  allowHQ: boolean,
  children?: any,
};

type State = {
  status: VideoStreamStatus,
  appearance: IAppearance,
};

const iphoneMacroModels: IPhoneModelKey[] = [
  'ProMax14',
  //NOTE: this includes IPhone 14 Plus, there is no way to separate it from the other models
  'ProMax12ProMax13Plus14',
  'Pro14',
];

const ROTATE_ANGLE = -Math.PI / 2.0; // -90 deg rad
const FHD_WIDTH = 1920;
const FHD_HEIGHT = 1080;
const FORCE_SDK_FULL_HD_RESOLUTION = false;
const ZOOM_IMAGE_FACTOR = 2.0;

class VideoStream extends Component<Props, State> {
  
  private videoRef = React.createRef<HTMLVideoElement>();

  private videoCanvasRef = React.createRef<HTMLCanvasElement>();

  private video: any;

  private canvas = document.createElement('canvas');

  private streams: any[] = [];

  private deviceId: string | null = null;

  private shouldStopVideoRecording = false;

  private cameraNotAllowedError = false;

  private permissionDeniedErrors: string[] = [
    'PermissionDeniedError',
    'NotAllowedError',
    'OverconstrainedError',
    'NotFoundError',
  ];

  /**
   * enable 4k video (if available)
   */
  private isHQVideo = false;

  /**
   * zoom the video on screen
   */
  private isVideoZoom = false;

  /**
   * capture zoomed-in image for sdk/cloud
   */
  private isCaptureImageZoom = false;

  /**
   * capture zoomed-in image for preview
   */
  private isImagePreviewZoom = false;

  constructor(props: any) {
    super(props);
    const { appearanceProps: appearance = {} } = this.props;
    this.state = {
      status: VideoStreamStatus.init,
      appearance,
    };
  }

  async componentDidMount() {
    await this.setDeviceCamera();
    await this.ensureCameraPermission();
    const { allowHQ = true } = this.props;
    this.setImageCaptureQuality(allowHQ);

    const { status } = this.state;
    if (status !== VideoStreamStatus.cameraPermissionDenied) {
      this.setState({ status: VideoStreamStatus.ready });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.startVideoStream);
    window.removeEventListener('blur', this.stopVideoStream);
    this.stopVideoStream();
  }

  setDeviceCamera = async () => {
    const { appState, facingMode = FacingModes.environment } = this.props;
    const { session = {} } = appState;
    const { cameras = [] } = session;
    const cameraIndex: number = cameras[0];
    if (!cameras.length || facingMode === FacingModes.user) return;

    try {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = mediaDevices.filter((device) => device.kind === 'videoinput');
      const device = videoDevices[cameraIndex - 1];
      if (device) this.deviceId = device.deviceId;
    } catch (err) {
      const { message, stack } = (err || {}) as any;
      error(`Error when setting device camera: message: ${message} stack: ${stack}`);
    }
  };

  setImageCaptureQuality = (allowHQ : boolean) => {
    const { isIPhone } = getUA();
    if (allowHQ && isIPhone) {
      const imodel = getIphoneModel();
      debug('detected iphone model', imodel);
      if (iphoneMacroModels.includes(imodel)) {
        //enable 4k for all iphones pro max only
        this.isHQVideo = true;
        this.isVideoZoom = true;
        //TBD if this gives better results
        this.isCaptureImageZoom = true;
        this.isImagePreviewZoom = true;
      }
    }
    debug('video features', {
      isHQVideo: this.isHQVideo,
      isVideoZoom: this.isVideoZoom,
      isCaptureImageZoom: this.isCaptureImageZoom,
      isImagePreviewZoom: this.isImagePreviewZoom
    });
  };

  ensureCameraPermission = () => {
    // Nothing to do for else case, it assumes that errors will be handled in the further steps
    if (navigator && (navigator as any).permissions) {
      return (navigator as any).permissions.query({ name: 'camera' })
        .then((permissionStatus: any) => {
          const { state } = permissionStatus;
          if (state === CameraPermissions.denied) {
            this.setState({ status: VideoStreamStatus.cameraPermissionDenied });
          }
          permissionStatus.onchange = () => { // eslint-disable-line
            if (state === CameraPermissions.denied) {
              this.setState({ status: VideoStreamStatus.cameraPermissionDenied });
            }
          };
        })
        .catch(() => {
          // Nothing to do here, it assumes that errors will be handled in the further steps
        });
    }
    return Promise.resolve(null);
  };

  private getVideoConstraints = () : MediaTrackConstraints => {
    const { facingMode = FacingModes.environment } = this.props;
    const video: MediaTrackConstraints = {
      width: { min: 1280, ideal: 1920, max: 1920 },
      height: { min: 720, ideal: 1080, max: 1080 },
      facingMode,
    };

    if (this.isHQVideo) {
      video.width = { min: 1280, ideal: 3840, max: 4096 };
      video.height = { min: 720, ideal: 2160, max: 2160 };
    }

    if (this.deviceId) video.deviceId = { exact: this.deviceId };

    return video;
  };

  startVideoStream = async () => {
  
    try {
      const video = this.getVideoConstraints();
      const stream = await navigator.mediaDevices.getUserMedia({
        video,
        audio: false,
      });
      if (stream === null) return;
      const streamSettings = stream.getVideoTracks()[0].getSettings();
      debug('got stream', streamSettings);
      
      this.streams.push(stream);
      this.video.srcObject = stream;
      this.video.play();
      this.cameraNotAllowedError = false;
      this.setState({ status: VideoStreamStatus.play });
    } catch (err) {
      const { name, message, stack } = (err || {}) as any;
      if (name === 'NotAllowedError' && !this.cameraNotAllowedError) {
        this.cameraNotAllowedError = true;
        this.startVideoStream();
        return;
      } else {
        if (this.permissionDeniedErrors.includes(name)) {
          this.setState({ status: VideoStreamStatus.cameraPermissionDenied });
        }
        error(`Start video stream error: name: ${name} message: ${message} stack: ${stack}`);
      }
    }
  };

  stopVideoStream = (): void => {
    if (!this.video || !this.video.srcObject) return;
    try {
      this.streams.forEach((stream: any) => {
        stream.getTracks().forEach((a: any) => {
          a.stop();
          stream.removeTrack(a);
        });
      });
      this.video.srcObject = null;
      this.streams = [];
      this.setState({ status: VideoStreamStatus.stop });
    } catch (err) {
      const { message, stack } = (err || {}) as any;
      error(`Stop Video error: message: ${message} stack: ${stack}`);
    }
  };

  private getCaptureType(whatToCapture: WhatToCapture, isLandscape: boolean) {
    let capturetype: CameraCaptureType = CameraCaptureType.portrait;

    //decide how to capture image
    switch (whatToCapture) {
      case WhatToCapture.idDocument:
        if (isLandscape) {
          capturetype = CameraCaptureType.landscape;
        } else {
          capturetype = CameraCaptureType.portraitRotated;
        }
        break;
      case WhatToCapture.selfie:
        capturetype = CameraCaptureType.portrait;
        break;
      case WhatToCapture.additionalDoc:
      case WhatToCapture.docBackside:
      case WhatToCapture.utilityBill:
        if (isLandscape) {
          capturetype = CameraCaptureType.landscape;
        } else {
          capturetype = CameraCaptureType.portrait;
        }
        break;
    }
    return capturetype;
  }

  private calcCaptureSettings(videoWidth: number, videoHeight: number, isLandscape: boolean, videoZoomFactor: number) : ICaptureSettings {
    //const videoAspectRatio = videoWidth / videoHeight;

    //zoom height & width
    let zHeight = videoHeight / videoZoomFactor;
    let zWidth = videoWidth / videoZoomFactor;

    //source height & width
    const sHeight = Math.round(zHeight);
    const sWidth = Math.round(zWidth);

    //always capture center of source video
    const sx = Math.floor((videoWidth - sWidth) / 2);
    const sy = Math.floor((videoHeight - sHeight) / 2);

    const minSdkHeight = isLandscape ? FHD_HEIGHT : FHD_WIDTH;
    const minSdkWidth = isLandscape ?  FHD_WIDTH : FHD_HEIGHT;
    //ensure minimum size (upscale)
    if (zHeight < minSdkHeight) {
      const scaleUpRatio = minSdkHeight / zHeight;
      zHeight = minSdkHeight;
      zWidth = zWidth * scaleUpRatio;
    }

    if (zWidth < minSdkWidth) {
      const scaleUpRatio = minSdkWidth / zWidth;
      zWidth = minSdkWidth;
      zHeight = zHeight * scaleUpRatio;
    }

    //canvas height & width
    let cHeight = Math.round(zHeight);
    let cWidth = Math.round(zWidth);
    
    if (FORCE_SDK_FULL_HD_RESOLUTION) {
      //TODO finish implementation
      //this is quite complicated since aspect ratio might be different then source!
      cHeight = isLandscape ? FHD_HEIGHT : FHD_WIDTH;
      cWidth = isLandscape ?  FHD_WIDTH : FHD_HEIGHT;
      //let imageResizeRatio = Math.min(longEdge / videoWidth, shortEdge / videoHeight);
      //let nW = videoWidth * imageResizeRatio;
      //let nH = videoHeight * imageResizeRatio;
    }
    
    return { sx, sy, sWidth, sHeight, cWidth, cHeight };
  }

  private getImageData(captureSettings : ICaptureSettings, capturetype : CameraCaptureType, ctx : CanvasRenderingContext2D): string {

    //capture image
    switch (capturetype) {
      case CameraCaptureType.landscape:
      case CameraCaptureType.portrait:
        this.canvas.width = captureSettings.cWidth;
        this.canvas.height = captureSettings.cHeight;
        ctx.drawImage(this.video, captureSettings.sx, captureSettings.sy, captureSettings.sWidth, captureSettings.sHeight, 0, 0, captureSettings.cWidth, captureSettings.cHeight);
        break;
      case CameraCaptureType.portraitRotated:
        // rotate, because document must be facing up for PXL SDK
        this.canvas.width = captureSettings.cHeight;
        this.canvas.height = captureSettings.cWidth;
        ctx.translate(0, captureSettings.cWidth);
        ctx.rotate(ROTATE_ANGLE);
        ctx.drawImage(this.video, captureSettings.sx, captureSettings.sy, captureSettings.sWidth, captureSettings.sHeight, 0, 0, captureSettings.cWidth, captureSettings.cHeight);
        ctx.resetTransform();
    }

    return this.canvas.toDataURL('image/jpeg'); // can also use 'image/png'
  }

  /**
     * - Maintain correct aspect ratio - no streching of images even if aspect ratio of video and result image do not match
     * - Conform to sdk minimum image size
     * - Try and get highest quality possible
     * - Optional: Scale center of image according to zoomfactor
     */
  captureImage = (whatToCapture: WhatToCapture): ICapturedImage => {
    const stream = this.streams[0];
    if (!stream) return { capturedImg: '', previewImage: '' };

    const streamSettings = stream.getVideoTracks()[0].getSettings();
    debug('stream settings', streamSettings);

    const isLandscape = this.isLandscapeOrientation();
    const videoWidth = streamSettings.width;
    const videoHeight = streamSettings.height;
    const videoZoomFactor =  this.isCaptureImageZoom ? ZOOM_IMAGE_FACTOR : 1.0;
    
    const captureSettings = this.calcCaptureSettings(videoWidth, videoHeight, isLandscape, videoZoomFactor);
    const capturetype: CameraCaptureType = this.getCaptureType(whatToCapture, isLandscape);
    const ctx = this.canvas.getContext('2d');
    const dataURI = this.getImageData(captureSettings, capturetype, ctx);

    debug({ 
      capturetype,
      videoWidth,
      videoHeight,
      width: this.canvas.width,
      height: this.canvas.height,
      captureSettings
    });
    
    //capture preview
    let previewImage = '';
    if (this.isImagePreviewZoom !== this.isCaptureImageZoom || capturetype === CameraCaptureType.portraitRotated) {
      const previewZoomFactor =  this.isImagePreviewZoom ? ZOOM_IMAGE_FACTOR : 1.0;
      const previewSettings = this.calcCaptureSettings(videoWidth, videoHeight, isLandscape, previewZoomFactor);
      previewImage = this.getImageData(previewSettings, CameraCaptureType.portrait, ctx);
    }

    return { capturedImg: dataURI, previewImage };
  };

  getUserAgentData = () => {
    if ((navigator as any).userAgentData) {
      return (navigator as any).userAgentData.getHighEntropyValues([
        'model',
        'platform',
        'platformVersion',
      ])
        .then((ua: UserAgentData) => {
          return {
            model: ua.model,
            platform: ua.platform,
            platformVersion: ua.platformVersion,
          };
        });
    }
  };

  recordVideo = (duration: number, preDefinedMimeType = ''): Promise<any> => (
    new Promise((resolve, reject) => {
      try {
        const stream = this.video.srcObject;
        let mimeType;
        if (this.isIos()) {
          mimeType = 'video/mp4';
        } else {
          mimeType = preDefinedMimeType || 'video/webm';
        }
        const options = { mimeType };
        const recordedChunks: string[] = [];
        let interruptionChecker: any = null;
        let stopTimeout: any = null;
        let clearCallbacks: any = null;
        let dataAvailableCallback: any = null;
        let stopCallback: any = null;
        let interruptVideoRecording: any = null;
        const mediaRecorder = new (window as any).MediaRecorder(stream, options);

        dataAvailableCallback = (e: any) => {
          if (e.data.size > 0) recordedChunks.push(e.data);
        };

        stopCallback = async () => {
          clearCallbacks();
          if (recordedChunks.length || preDefinedMimeType) {
            resolve(new Blob(recordedChunks, { type: options.mimeType }));
          } else {
            // This is special case when for some devices no recording happened due to default chosen codecs.
            // Known case H264 codec
            // In this case we manually switch to VP8 codec.
            // For more information see https://pxlvision.atlassian.net/browse/DGW-1442
            const { model, platform: platformInfo, platformVersion } = await this.getUserAgentData();
            this.recordVideo(duration, 'video/webm;codecs=VP8')
              .then((data) => {
                error(`Mimetype changed for ${model} (${platformInfo} ${platformVersion}) to \'video/webm;codecs=VP8\' `);
                resolve(data);
              }).catch((err) => {
                error(`Error for ${model} (${platformInfo} ${platformVersion}) when mimetype changed to \'video/webm;codecs=VP8\' `);
                reject(err);
              });
          }
        };

        clearCallbacks = () => {
          window.clearInterval(interruptionChecker);
          window.clearTimeout(stopTimeout);
          mediaRecorder.removeEventListener('dataavailable', dataAvailableCallback);
          mediaRecorder.removeEventListener('stop', stopCallback);
        };

        interruptVideoRecording = () => {
          clearCallbacks();
          // For case of bluring the page video vill be inactive so stop will not work
          try {
            mediaRecorder.stop();
          } catch {
            // If stop failing it meas that no video present so nothing to do
          }
          this.shouldStopVideoRecording = false;
          resolve(null);
        };

        // Collect video chunks
        mediaRecorder.addEventListener('dataavailable', dataAvailableCallback);

        // Final resolve of the Promise
        mediaRecorder.addEventListener('stop', stopCallback);

        mediaRecorder.start();
        stopTimeout = window.setTimeout(() => {
          mediaRecorder.stop();
        }, duration);

        interruptionChecker = window.setInterval(() => {
          const { status } = this.state;
          const shouldInterrupt: boolean = status !== VideoStreamStatus.play
            || this.shouldStopVideoRecording;
          if (shouldInterrupt) {
            interruptVideoRecording();
          }
        }, 100);
      } catch (err) {
        error('mediaRecorder error', err);
        reject(err);
      }
    })
  );

  collectImagesForVideo = (count: number): Promise<string[]> => {
    let counter: number = count;
    const images: string[] = [this.captureImage(WhatToCapture.selfie).capturedImg];
    counter -= 1;
    return new Promise((resolve, reject) => {
      let interval = setInterval(() => {
        const { status } = this.state;
        if (status !== VideoStreamStatus.play || this.shouldStopVideoRecording) {
          this.shouldStopVideoRecording = false;
          return reject();
        }

        images.push(this.captureImage(WhatToCapture.selfie).capturedImg);
        counter -= 1;
        if (counter === 0) {
          clearInterval(interval);
          interval = null;
          resolve(images);
        }
        return null;
      }, 100);
    });
  };

  stopVideoRecording = () => {
    this.shouldStopVideoRecording = true;
  };

  isLandscapeOrientation = (): boolean => {
    const { appState } = this.props;
    const { orientation } = appState;
    return orientation === Orientation.landscapePrimary
      || orientation === Orientation.landscapeSecondary;
  };

  isIos = () => {
    const platformInfo = platform.parse(navigator.userAgent);
    const { os = {} } = platformInfo;
    const { family = '' } = os;
    return family.toLowerCase() === 'ios';
  };

  ensureVideoStream = async () => {
    this.video = this.videoRef.current as any;
    try {
      await this.startVideoStream();
      window.addEventListener('focus', this.startVideoStream);
      window.addEventListener('blur', this.stopVideoStream);
    } catch (err) {
      const { message, stack } = (err || {}) as any;
      error(`Ensure video stream error: message: ${message} stack: ${stack}`);
    }
  };

  updateAppearance = (newAppearance: IAppearance) => {
    const { appearance } = this.state;
    // TOOD: Revise this functionality. Not properly updates
    this.setState({ appearance: { ...appearance, ...newAppearance } });
  };

  render() {
    const { status } = this.state;
    const {
      appState,
      facingMode,
      children,
    } = this.props;
    const { closeButtonOnErrorScreens, leaveApp } = this.context;
    const {
      appearance: {
        canvasClassNames = '',
        videoClassNames = '',
        videoStyle = {},
        poweredByLogo = {},
      },
    } = this.state;
    const { config } = appState;
    const { poweredByLogo: poweredByLogoEnabled } = config;
    const showPoweredByLogo = poweredByLogoEnabled && !poweredByLogo.hide;

    const finalVideoClassNames = this.isVideoZoom ? videoClassNames + ' video-zoom' : videoClassNames;

    let content: any;
    switch (status) {
      case VideoStreamStatus.init:
        content = '';
        break;
      case VideoStreamStatus.cameraPermissionDenied:
        content = (
          <Frame>
            <div className="inner-frame">
              <div>
                <div className="text">
                  <Translate i18nKey="dv.no-permission-message1" />
                  {' '}
                </div>
                <div className="text"><Translate i18nKey="dv.no-permission-message2" /></div>
                <div className="text"><Translate i18nKey="dv.access-camera-message" /></div>
                {
                  closeButtonOnErrorScreens
                  && (
                    <div
                      className="button button-big"
                      role="button"
                      onKeyPress={() => leaveApp(true)}
                      onClick={() => leaveApp(true)}
                      tabIndex={-1}
                    >
                      <Translate i18nKey="error-pages.close" />
                    </div>
                  )
                }
              </div>
            </div>
          </Frame>
        );
        break;
      default: {
        const sharedMembers = {
          status,
          isLandscapeOrientation: this.isLandscapeOrientation(),
          ensureVideoStream: this.ensureVideoStream,
          captureImage: this.captureImage,
          recordVideo: this.recordVideo,
          collectImagesForVideo: this.collectImagesForVideo,
          stopVideoRecording: this.stopVideoRecording,
          updateAppearance: this.updateAppearance,
          startVideoStream: this.startVideoStream,
          stopVideoStream: this.stopVideoStream,
        };
        content = React.cloneElement(
          children as React.ReactElement<any>, { videoStream: sharedMembers },
        );
      } break;
    }

    return (
      <div className="card">
        <canvas ref={this.videoCanvasRef} className={`${facingMode === FacingModes.user ? 'flip' : ''} ${canvasClassNames}`} />
        <div className={`video-container ${finalVideoClassNames}`} style={videoStyle}>
          <video
            ref={this.videoRef}
            muted
            playsInline
            className={`${facingMode === FacingModes.user ? 'mirrored' : ''}`}
          />
        </div>
        {showPoweredByLogo && (
          <PoweredByLogo
            changePositionOfPoweredByLogo={poweredByLogo.changePosition}
            toggleTextColor={poweredByLogo.toggleTextColor}
          />
        )}
        {content}
      </div>
    );
  }
}

VideoStream.contextType = AppContext;

export default VideoStream;

