import React, { Component } from 'react';
import Translate from 'components/translate/Translate';

/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { IAddress } from 'types/index.d';
import SelectBox from 'components/inputs/SelectBox';
import countryCodes from 'resources/data/countryCodes';
import i18n from 'configs/i18n.config';
import SimpleInput from 'components/inputs/SimpleInput';

interface IProps {
  data: IAddress;
  changeState: (obj: { address: any }) => void;
  isValidKey: (vd: any, address: string) => boolean;
  validationData: any;
}

export default class FormAddress extends Component<IProps> {
  getLabel = (item: string): React.ReactNode => <Translate i18nKey={item} />;

  onInputChange = (value: any): void => {
    const {
      data,
      changeState,
    } = this.props;

    changeState({
      address: {
        ...data,
        ...value,
      },
    });
  };

  render(): React.ReactNode {
    const {
      data,
      changeState,
      isValidKey,
      validationData,
    } = this.props;
    return (
      <section className="form-address">
        <div className="section-container form-address-container">
          <label><Translate i18nKey="personal-details.address" /></label>
          <div className="inputs-horizontal-block">
            <SimpleInput
              label="personal-details.street"
              name="street"
              type="text"
              value={data.street}
              changeState={this.onInputChange}
              error={!isValidKey(validationData, 'street')}
              errorText="personal-details.street-error"
              className="street-line"
            />
            <SimpleInput
              label="personal-details.number"
              name="streetNumber"
              type="number"
              value={data.streetNumber}
              changeState={this.onInputChange}
              error={!isValidKey(validationData, 'streetNumber')}
              errorText="personal-details.number-error"
              className="street-number-line"
            />
          </div>
          <div className="inputs-horizontal-block">
            <SimpleInput
              label="personal-details.city"
              name="city"
              type="text"
              value={data.city}
              changeState={this.onInputChange}
              error={!isValidKey(validationData, 'city')}
              errorText="personal-details.city-error"
              className="city-line"
            />
            <SimpleInput
              label="personal-details.city-code"
              name="cityCode"
              type="number"
              value={data.cityCode}
              changeState={this.onInputChange}
              error={!isValidKey(validationData, 'cityCode')}
              errorText="personal-details.city-code-error"
              className="city-code-line"
            />
          </div>
          <SelectBox
            label="personal-details.country"
            name="country"
            options={countryCodes.map((code) => ({
              value: code,
              label: i18n.t([`countries.${code}`]),
            }))}
            changeState={({ country }: any) => changeState({
              address: {
                ...data,
                country,
              },
            })}
            error={!isValidKey(validationData, 'country')}
            errorText="personal-details.country-error"
            className="country-line"
          />
        </div>
      </section>
    );
  }
}
