import React, { Component } from 'react';
import ReactCodeInput from 'react-code-input';
import Translate from 'components/translate/Translate';
import Header from 'components/header/Header';
import Loader from 'components/loader/Loader';
import { ReactComponent as LogoPlaceholder } from 'assets/images/sp-logo-placeholder.svg';
import Views from 'modules/transactionCode/Views';

type Props = {
  qrCodeEnabled: boolean;
  handleServiceProviderData: any;
  getServiceProviderData: any;
  setView: any;
  onDone: any;
  customerLogo: string | null;
};

type State = {
  canGo: boolean;
  wrongCode: boolean;
  loading: boolean;
  checkingTransactionCode: boolean;
  transactionCodeLoading: boolean;
};

export default class TransactionCode extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      canGo: false,
      wrongCode: false,
      loading: false,
      checkingTransactionCode: false,
      transactionCodeLoading: false,
    };
  }

  handleServiceProviderData = (code: any) => {
    const { handleServiceProviderData, getServiceProviderData } = this.props;
    const { checkingTransactionCode } = this.state;
    if (checkingTransactionCode) return;
    if (code.length === 9) {
      this.setState({
        canGo: false,
        wrongCode: false,
        checkingTransactionCode: true,
      });
      setTimeout(() => {
        this.startLoading();
      }, 500);
      getServiceProviderData(code)
        .then((serviceProviderData: any) => {
          handleServiceProviderData(serviceProviderData);
          this.setState({
            canGo: true,
            checkingTransactionCode: false,
            transactionCodeLoading: false,
          });
        })
        .catch(() => {
          this.setState({
            wrongCode: true,
            checkingTransactionCode: false,
            transactionCodeLoading: false,
          });
        });
    } else {
      this.setState({ canGo: false });
    }
  };

  continue = () => {
    const { canGo, loading } = this.state;
    if (!canGo || loading) return;

    const { onDone } = this.props;
    onDone();
  };

  scanQrCode = () => {
    const { loading } = this.state;
    if (loading) return;

    const { setView } = this.props;
    setView(Views.qrCode);
  };

  startLoading() {
    const { canGo, wrongCode } = this.state;
    if (!canGo && !wrongCode) {
      this.setState({ transactionCodeLoading: true });
    }
  }

  render() {
    const { qrCodeEnabled, customerLogo } = this.props;
    const {
      canGo,
      wrongCode,
      loading,
      transactionCodeLoading,
    } = this.state;
    const inactiveClass = (canGo && !loading) ? 'active' : 'inactive';
    const tcError = (wrongCode)
      ? <Translate i18nKey="tc.error-message" /> : '';
    const tcInputProps: any = {
      type: 'number',
      fields: 9,
      autoFocus: true,
      isValid: !wrongCode,
      onChange: this.handleServiceProviderData,
    };
    let logoImg: any = <LogoPlaceholder className='default-sp-logo' />;
    if (canGo && customerLogo) {
      logoImg = (
        <img
          src={customerLogo}
          alt="Service provider logo"
          className="sp-logo"
        />
      );
    }
    return (
      <div className="comp-transaction-code">
        <Header />
        <div className="card">
          <div className="contents">
            <div className="main-content">
              <div className="congrats"><Translate i18nKey="tc.enter-code" /></div>
              <div>
                <div className="transaction-code-with-loader">
                  <div className="tc-loader">
                    <ReactCodeInput {...tcInputProps} />
                    {transactionCodeLoading && <Loader />}
                  </div>
                </div>
                <div className="error">{tcError}</div>
              </div>
              <div className="text"><Translate i18nKey="tc.message" /></div>
              <div className="service-provider-logo">{logoImg}</div>
            </div>
            <div className="button-with-loader">
              <div
                className={`button-big ${inactiveClass}`}
                role="button"
                onClick={this.continue}
                onKeyPress={this.continue}
                tabIndex={-1}
              >
                <span><Translate i18nKey="tc.btn.continue" /></span>
                {loading && <Loader />}
              </div>
            </div>
            {qrCodeEnabled && (
              <div
                className="link"
                role="button"
                tabIndex={-1}
                onKeyUp={this.scanQrCode}
                onClick={this.scanQrCode}
              >
                <span><Translate i18nKey="tc.qrcode" /></span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
