import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import Header from 'components/header/Header';
import Loader from 'components/loader/Loader';
import { getUA } from 'utils';

/* eslint-disable no-unused-vars */
enum Statuses {
  start = 'start',
  testing = 'testing',
  success = 'success',
  fail = 'fail',
}
/* eslint-disable no-unused-vars */

interface IProps {
  onDone: () => void;
}

interface IState {
  deviceCompatibility: boolean;
  cameraTest: boolean;
  browserLoading: boolean;
  cameraAllowedLoading: boolean;
  status: Statuses;
}

export default class EnvironmentTest extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      deviceCompatibility: false,
      cameraTest: false,
      browserLoading: false,
      cameraAllowedLoading: false,
      status: Statuses.start,
    };
  }

  handleCheck() {
    const { onDone } = this.props;
    const { status } = this.state;

    /* eslint-disable default-case */
    switch (status) {
      case Statuses.start:
      case Statuses.fail:
        this.setState({ status: Statuses.testing });
        Promise.all([
          this.checkBrowser(),
          this.checkCameraPermission(),
        ])
          .then(() => this.setState({ status: Statuses.success }))
          .catch(() => this.setState({ status: Statuses.fail }));
        break;
      case Statuses.success:
        onDone();
        break;
    }
  }

  checkCameraPermission() {
    this.setState({ cameraAllowedLoading: true });
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      this.setState({ cameraAllowedLoading: false });
      return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
    }

    return navigator.mediaDevices
      .getUserMedia({ video: { facingMode: 'user' } })
      .then(() => this.setState({ cameraTest: true, cameraAllowedLoading: false }))
      .catch(() => {
        this.setState({ cameraTest: false, cameraAllowedLoading: false });
        throw new Error('Inchvor ban');
      });
  }

  checkBrowser() {
    this.setState({ browserLoading: true });
    const {
      isMiUiBrowser,
      isApple,
      isSafari,
      isMobile,
    } = getUA();
    return !isMiUiBrowser && !(isMobile && isApple && !isSafari)
      ? this.setState({ deviceCompatibility: true, browserLoading: false })
      : this.setState({ deviceCompatibility: false, browserLoading: false });
  }

  render() {
    let title;
    let btnText;
    let cameraIcon;
    let deviceIcon;
    const {
      deviceCompatibility,
      cameraTest,
      browserLoading,
      cameraAllowedLoading,
      status,
    } = this.state;
    const loading = status === Statuses.testing;
    const btnClass = loading ? 'inactive' : 'checked';

    if (status === Statuses.start) {
      deviceIcon = null;
      cameraIcon = null;
    } else {
      deviceIcon = deviceCompatibility
        ? <img src="check.png" alt="check" />
        : <img src="x.png" alt="check" />;
      cameraIcon = cameraTest
        ? <img src="check.png" alt="check" />
        : <img src="x.png" alt="check" />;
    }

    switch (status) {
      case Statuses.start:
        title = <Translate i18nKey="environment-test.title" />;
        btnText = <Translate i18nKey="environment-test.btn.start" />;
        break;
      case Statuses.success:
        title = <Translate i18nKey="environment-test.success.title" />;
        btnText = <Translate i18nKey="environment-test.btn.continue" />;
        break;
      case Statuses.fail:
        title = <Translate i18nKey="environment-test.err.title" />;
        btnText = <Translate i18nKey="environment-test.btn.err" />;
        break;
      case Statuses.testing:
        title = <Translate i18nKey="environment-test.testing.title" />;
        btnText = <Translate i18nKey="environment-test.btn.start" />;
        break;
    }

    return (
      <div className="comp-environment-test">
        <Header />
        <div className="card">
          <div className="main-content">
            <h1>{title}</h1>
            <div className="doc-types">
              <div className="clicable-area" tabIndex={-1}>
                <div className="clicable-area-item text-caption">
                  <b><Translate i18nKey="environment-test.compatibility1" /></b>
                  {browserLoading
                    ? (
                      <span className="loader-container">
                        <Loader />
                      </span>
                    )
                    : deviceIcon}
                </div>
              </div>
              <div className="clicable-area" tabIndex={-1}>
                <div className="clicable-area-item text-caption">
                  <b><Translate i18nKey="environment-test.compatibility2" /></b>
                  {cameraAllowedLoading
                    ? (
                      <span className="loader-container">
                        <Loader />
                      </span>
                    )
                    : cameraIcon}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`button button-big ${btnClass}`}
            role="button"
            tabIndex={-1}
            onKeyPress={() => this.handleCheck()}
            onClick={() => this.handleCheck()}
          >
            {btnText}
          </div>
        </div>
      </div>
    );
  }
}
