import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import Header from 'components/header/Header';

interface IProps {
  goBack: () => void;
}

export default class ContactCustomer extends Component<IProps> {
  render() {
    const { goBack } = this.props;
    return (
      <div className="comp-verify-contact">
        <Header icons="back" goBack={goBack} />
        <div className="card">
          <div className="main-content">
            <div>
              <div className="welcome">
                <Translate i18nKey="verify-code.title" />
              </div>
            </div>
            <div className="verify-code-text">
              <Translate i18nKey="verify-code.contact-customer" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
