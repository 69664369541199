import React, { Component } from 'react';
import jsQR from 'jsqr';
import Header from 'components/header/Header';
import QrCodeMessage from 'components/qrCodeMessage/QrCodeMessage';
import {
  fetch,
  handleFetchErrors,
  closeFullscreen,
  mobileAndTabletCheck,
  vibrate,
  isLandscapeOrientation,
} from 'utils';
import { error } from 'utils/log';
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import {
  Orientation,
  VibrationTunes,
  CameraPermissions,
  QRScannerStatus,
} from 'types/enum';
import Views from 'modules/transactionCode/Views';
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */
import AppContext from 'modules/AppContext';

const scanTimeout = 10 * 1000; // 10 seconds
const potentialResolutions = [
  { width: 1280, height: 720 },
  { width: 1920, height: 1080 },
  { width: 3840, height: 2160 },
];

type Props = {
  isOnline: boolean;
  isDesktop: boolean;
  session: any;
  isApple: boolean;
  orientation: Orientation;
  prevView: Views;
  handleServiceProviderData: any;
  getServiceProviderData: any;
  setView: any;
  onDone: any;
  goBack: () => void;
};

type State = {
  status: QRScannerStatus;
  deviceId?: { exact: string };
};

export default class QrCodeScanner extends Component<Props, State> {
  private videoRef = React.createRef<HTMLVideoElement>();

  private canvas = document.createElement('canvas');

  private video: any;

  private streams: any[] = [];

  private videoResolution: any = potentialResolutions[0];

  private isFocused = true;

  private scanStartTime: number;

  private isOnMobile = mobileAndTabletCheck();

  constructor(props: any) {
    super(props);

    this.state = {
      status: QRScannerStatus.scan,
    };
  }

  componentDidMount() {
    this.video = this.videoRef.current as any;

    if (this.isOnMobile && window.document.documentElement.requestFullscreen) {
      try {
        window.document.documentElement.requestFullscreen();
      } catch (err: any) {
        const { message, stack } = err;
        error(`QR requestFullscreent error: message: ${message} stack: ${stack}`);
      }
    }

    this.setDeviceCamera(() => this.checkPermissionAndStartCasting());

    window.addEventListener('focus', this.startCastingOnFocus);
    window.addEventListener('blur', this.stopCastingOnBlur);
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.startCastingOnFocus);
    window.removeEventListener('blur', this.stopCastingOnBlur);
    this.stopVideo();
    closeFullscreen();
  }

  setDeviceCamera(callback: any) {
    const { session = {} } = this.props;
    const { cameras } = session;
    if (cameras && cameras.length) {
      const index = cameras[0];
      navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          let i = 0;
          devices.forEach((device) => {
            const { kind, deviceId } = device;

            if (kind === 'videoinput') {
              i += 1;
              if (i === index) {
                this.setState({
                  deviceId: { exact: deviceId },
                });
              }
            }
          });
        })
        .then(callback);
    } else {
      callback();
    }
  }

  setCameraPermission(changedPermissionStatus: CameraPermissions, isFirstCall: boolean): void {
    if (changedPermissionStatus === CameraPermissions.granted
      || (changedPermissionStatus === CameraPermissions.prompt && isFirstCall)) {
      this.isFocused = true;
      this.startCastingInNextTick();
    } else if (changedPermissionStatus === CameraPermissions.denied) {
      this.stopVideo();
    }
  }

  startCastingInNextTick = (): void => {
    process.nextTick(() => this.startCasting());
  };

  startCastingOnFocus = () => {
    this.isFocused = true;
    this.startCastingInNextTick();
  };

  stopCastingOnBlur = (): void => {
    this.isFocused = false;
    process.nextTick(() => {
      if (!this.isFocused) {
        this.stopVideo();
      }
    });
  };

  startCasting = (): void => {
    const { deviceId } = this.state;
    const video: any = {
      facingMode: 'environment',
      deviceId,
    };
    const getUserMedia = (index = 0) => {
      let resolutionIndex: number = index;
      video.width = this.videoResolution.width;
      video.height = this.videoResolution.height;

      return navigator.mediaDevices.getUserMedia({
        video,
        audio: false,
      }).then((stream) => {
        if (stream === null) return;
        this.streams.push(stream);
        if (!this.isFocused) {
          this.stopVideo();
          return;
        }
        this.video.srcObject = stream;
        try {
          this.video.play().then(() => this.startCaptureAndProcess());
        } catch (err: any) {
          const { message, stack } = err;
          error(`QR Video play error: message: ${message} stack: ${stack}`);
        }
      }).then(() => {
        process.nextTick(() => {
          const { isDesktop } = this.props;
          if (this.video.srcObject !== null
            && !isDesktop
          ) {
            this.addManualFocusListener();
          }
        });
      }).catch((err) => {
        if (err.name === 'NotReadableError') {
          resolutionIndex += 1;
          if (resolutionIndex < potentialResolutions.length) {
            this.videoResolution = potentialResolutions[resolutionIndex];
            getUserMedia(resolutionIndex);
          } else {
            const { message, stack } = err;
            error(`QR getUSerMedia error: message: ${message} stack: ${stack}`);
          }
        } else {
          const { message, stack } = err;
          error(`QR getUSerMedia error: message: ${message} stack: ${stack}`);
        }
      });
    };
    getUserMedia();
  };

  startCaptureAndProcess = (): void => {
    const { status } = this.state;
    if (status === QRScannerStatus.timeOuted) return;

    this.scanStartTime = Date.now();
    this.captureAndProcess();
  };

  manualFocus = (): void => {
    const { orientation } = this.props;
    if (!isLandscapeOrientation(orientation)) return;
    const track = this.video.srcObject.getVideoTracks()[0] as any;
    track.applyConstraints({ advanced: [{ focusMode: 'single-shot' }] }).then(() => {
      setTimeout(() => {
        track.applyConstraints({ advanced: [{ focusMode: 'continuous' }] });
      }, 1000);
    });
  };

  removeManualFocusListener = (): void => {
    if (window.document) {
      window.document.removeEventListener('click', this.manualFocus);
    }
  };

  addManualFocusListener = (): void => {
    if (window.document) {
      window.document.addEventListener('click', this.manualFocus);
    }
  };

  stopVideo = (): void => {
    const { isApple } = this.props;
    this.removeManualFocusListener();
    if (this.video.srcObject === null) return;
    try {
      this.streams.forEach((stream: any) => {
        stream.getTracks().forEach((a: any) => {
          a.stop();
          if (isApple) stream.removeTrack(a);
        });
      });
      this.video.srcObject = null;
      this.streams = [];
    } catch (err: any) {
      const { message, stack } = err;
      error(`QR Stop video error: message: ${message} stack: ${stack}`);
    }
  };

  getImageData = () => {
    const ctx = this.canvas.getContext('2d');
    this.canvas.width = 300;
    this.canvas.height = 300;
    ctx.drawImage(this.videoRef.current, 0, 0, this.canvas.width, this.canvas.height);
    const imageData = ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);

    return {
      data: imageData.data,
      width: imageData.width,
      height: imageData.height,
    };
  };

  readQrCode = () => {
    // TODO: Maybe need to be implemented as web worker.
    const imgData = this.getImageData();
    const { data, width, height } = imgData;
    return jsQR(data, width, height);
  };

  delayedCaptureAndProcess = () => {
    // Delay in order to not make browser throttle because of continuous calls.
    setTimeout(() => {
      this.captureAndProcess();
    }, 500);
  };

  getTransactionCode = (qrCodeData = ''): string => {
    let transactionCode = '';

    try {
      const url: URL = new URL(qrCodeData);
      if (url.origin === window.location.origin) {
        const { searchParams } = url;
        transactionCode = searchParams.get('tc') || '';
      }
    } catch (err: any) {
      if (err) {
        const { message, stack } = err;
        error(`Error error making URL from QR Code: message: ${message} stack: ${stack}`);
      }
    }

    return transactionCode;
  };

  isValidTransactionCode = (transactionCode: string): boolean => Boolean(transactionCode) && transactionCode.length === 9 && /^\d+$/.test(transactionCode);

  checkTransactionCode = (transactionCode: string) => {
    const { handleServiceProviderData, getServiceProviderData } = this.props;
    getServiceProviderData(transactionCode)
      .then((serviceProviderData: any = {}) => {
        handleServiceProviderData(serviceProviderData);
        this.setState({ status: QRScannerStatus.success });
        if (this.isOnMobile) vibrate(VibrationTunes.success);
      })
      .catch(() => {
        this.setState({ status: QRScannerStatus.fail });
        if (this.isOnMobile) vibrate(VibrationTunes.fail);
      });
  };

  scanQrCodeInBE = () => {
    const { handleServiceProviderData } = this.props;
    this.setState({ status: QRScannerStatus.scan });
    const imgData = this.getImageData();
    fetch('/qr_code', {
      method: 'POST',
      body: JSON.stringify(imgData),
    })
      .then(handleFetchErrors)
      .then((res: any) => {
        const { transactionCodeData: serviceProviderData } = res.json() || {};
        return serviceProviderData;
      })
      .then((serviceProviderData: any) => {
        if (!serviceProviderData) {
          this.captureAndProcess();
        } else {
          handleServiceProviderData(serviceProviderData);
          this.setState({ status: QRScannerStatus.success });
          if (this.isOnMobile) vibrate(VibrationTunes.success);
        }
      })
      .catch(() => {
        this.setState({ status: QRScannerStatus.fail });
        if (this.isOnMobile) vibrate(VibrationTunes.fail);
      });
  };

  scanQrCode = () => {
    const result = this.readQrCode();
    if (!result) {
      this.delayedCaptureAndProcess();
      return;
    }

    const transactionCode: string = this.getTransactionCode(result.data);
    if (this.isValidTransactionCode(transactionCode)) {
      this.checkTransactionCode(transactionCode);
    } else {
      this.delayedCaptureAndProcess();
    }
  };

  continue = () => {
    const { onDone } = this.props;
    onDone();
  };

  getFrame = () => {
    const { status } = this.state;

    let frame;
    switch (status) {
      case QRScannerStatus.success:
        frame = (
          <QrCodeMessage
            message={2}
            messageCallback={this.continue}
          />
        );
        break;
      case QRScannerStatus.fail:
        frame = (
          <QrCodeMessage
            message={3}
            messageCallback={this.messageCallback}
          />
        );
        break;
      case QRScannerStatus.timeOuted:
        frame = (
          <QrCodeMessage
            message={4}
            messageCallback={this.messageCallback}
          />
        );
        break;
      default:
        frame = (
          <QrCodeMessage message={1} />
        );
        break;
    }

    return frame;
  };

  messageCallback = (action: string): void => {
    const { setView, prevView } = this.props;

    switch (action) {
      case 'resume':
        this.setState({ status: QRScannerStatus.scan }, () => {
          this.scanStartTime = Date.now();
          this.scanQrCode();
        });
        break;
      case 'exit':
        setView(prevView);
        break;
      default:
    }
  };

  captureAndProcess = (): void => {
    const { isOnline } = this.props;

    const isActionTimeouted: boolean = this.scanStartTime
      && new Date().valueOf() - this.scanStartTime > scanTimeout;

    if (isActionTimeouted) {
      this.setState({ status: QRScannerStatus.timeOuted });
      return;
    }

    if (!isOnline) {
      window.setTimeout(() => this.captureAndProcess(), 1000);
      return;
    }

    const { status } = this.state;
    if (!this.videoStreamIsPresent() || status !== QRScannerStatus.scan) return;

    this.scanQrCode();
  };

  videoStreamIsPresent = (): boolean => Boolean(this.video.srcObject
    && this.video.srcObject.active);

  goBack() {
    const { goBack } = this.props;
    closeFullscreen();
    goBack();
  }

  checkPermissionAndStartCasting() {
    if (navigator && (navigator as any).permissions) {
      (navigator as any).permissions.query({ name: 'camera' }).then((permissionStatus: any) => {
        this.setCameraPermission(permissionStatus.state, true);
        permissionStatus.onchange = () => { // eslint-disable-line
          this.setCameraPermission(permissionStatus.state, false);
        };
      }).catch(() => {
        this.setCameraPermission(CameraPermissions.granted, true);
      });
    } else {
      this.setCameraPermission(CameraPermissions.granted, true);
    }
  }

  render() {
    const frame = this.getFrame();

    return (
      <div className="comp-qrcode-scanner">
        <Header goBack={this.goBack} />
        <div className="card">
          <video ref={this.videoRef} muted playsInline />
          {frame}
        </div>
      </div>
    );
  }
}

QrCodeScanner.contextType = AppContext;
