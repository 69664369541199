import Translate from 'components/translate/Translate';
import Loader from 'components/loader/Loader';
import { ReactComponent as Frame } from 'assets/images/frame-rect.svg';
import { ReactComponent as PassBackPic } from 'assets/images/passport-back-side.svg';
import { ReactComponent as AdditionalDocPic } from 'assets/images/doc-back-side.svg';
import React, { Component } from 'react';
import gaPageView from 'utils/GA';
import CaptureImage from 'components/captureImage/CaptureImage';

/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import {
  DocumentTypes,
  WhatToCapture,
} from 'resources/enums';
import { IAppearance } from 'components/videoStream/VideoStream';
import { IData } from 'types/index.d';
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */

enum Statuses {
  wait = 'wait',
  instruction = 'instruction',
  capture = 'capture',
  confirmation = 'confirmation',
  processing = 'processing',
}
/* eslint-enable no-unused-vars */

type Props = {
  onDone: () => void,
  changeData: (data: Partial<IData>) => void;
  documentType: DocumentTypes,
  addDVSLog: any,
  videoStream?: any,
};

type State = {
  status: Statuses,
};

export default class DocumentBackSide extends Component<Props, State> {
  private image: string;

  constructor(props: any) {
    super(props);
    this.state = {
      status: Statuses.wait,
    };
  }

  async componentDidMount() {
    const { addDVSLog } = this.props;
    gaPageView('/doc_back_side');
    addDVSLog('Document back side capturing started.');
    this.setState({ status: Statuses.instruction });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { videoStream } = this.props;
    const { status } = this.state;
    const { status: prevStatus } = prevState;

    const currentCondition = status === Statuses.capture;
    const prevCondition = prevStatus === Statuses.capture;

    if (currentCondition && !prevCondition) {
      const appearance: IAppearance = { canvasClassNames: 'video-canvas-size', videoClassNames: 'video-canvas-size' };
      if (!videoStream.isLandscapeOrientation) {
        appearance.poweredByLogo = { changePosition: true };
      }
      videoStream.updateAppearance(appearance);
    } else if (!currentCondition && prevCondition) {
      videoStream.updateAppearance({});
    }
  }

  instructionOnClickHandler = () => this.setState({ status: Statuses.capture });

  captureHandler = () => {
    const { videoStream } = this.props;

    const { capturedImg } = videoStream.captureImage(WhatToCapture.docBackside);

    this.image = capturedImg;

    videoStream.updateAppearance({ videoStyle: { display: 'none' }, poweredByLogo: { hide: true } });

    this.setState({ status: Statuses.confirmation });
  };

  recaptureCallback = () => {
    const { videoStream } = this.props;
    videoStream.updateAppearance({ videoStyle: {}, poweredByLogo: {} });
    this.setState({ status: Statuses.capture }, () => {
      /**
       * The camera is frozen when switching from landscape to portrait mode when recapture.
       * More https://pxlvision.atlassian.net/browse/PID-173.
       */
      videoStream.stopVideoStream();
      videoStream.startVideoStream();
    });
  };

  continueCallback = () => {
    this.setState({ status: Statuses.processing });
    setTimeout(() => {
      const { videoStream, changeData, onDone } = this.props;
      videoStream.updateAppearance({
        canvasClassNames: '',
        videoClassNames: '',
        videoStyle: {},
        poweredByLogo: {},
      });
      changeData({ docBackSide: this.image });
      onDone();
    }, 1000);
  };

  render() {
    const { documentType } = this.props;
    const { status } = this.state;
    let content: any = '';
    switch (status) {
      case Statuses.instruction: {
        let backPic;
        let message;
        if (documentType === 'passport') {
          backPic = <PassBackPic className="additional-doc-pic" />;
          message = <Translate i18nKey="dv.doc-back-side.swiss-doc-message" />;
        } else {
          backPic = <AdditionalDocPic className="additional-doc-pic" />;
          message = <Translate i18nKey="dv.doc-back-side.paper-permit-message" />;
        }
        content = (
          <div className="comp-doc-back-side-message">
            <div className="frame">
              <Frame className="doc-back-side-frame" />
              <div className="spacer">
                <div className="inner-frame">
                  <div>
                    <div>{backPic}</div>
                    <div className="text">{message}</div>
                    <div
                      className="button button-big overlay-button"
                      role="button"
                      onClick={this.instructionOnClickHandler}
                      onKeyPress={this.instructionOnClickHandler}
                      tabIndex={-1}
                    >
                      <Translate i18nKey="dv.btn.ok" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } break;
      case Statuses.capture:
        content = (
          <CaptureImage callback={this.captureHandler} />
        );
        break;
      case Statuses.confirmation:
        content = (
          <div className="comp-doc-back-side-message">
            <div className="captured-section">
              <div>
                <div className="confirmation-section">
                  <div className="title"><Translate i18nKey="dv.doc-back-side.confirmation-message" /></div>
                  <img
                    src={this.image}
                    alt="document-back-side-capture"
                  />
                  <div className="confirmaition-links">
                    <div
                      className="button button-big"
                      role="button"
                      tabIndex={-1}
                      onKeyPress={() => { }}
                      onClick={this.recaptureCallback}
                    >
                      <Translate i18nKey="dv.doc-back-side.recapture" />
                    </div>
                    <div
                      className="button button-big"
                      role="button"
                      tabIndex={-1}
                      onKeyPress={() => { }}
                      onClick={this.continueCallback}
                    >
                      <Translate i18nKey="dv.doc-back-side.continue" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case Statuses.processing:
        content = (
          <div className="comp-doc-back-side-message">
            <div className="captured-section">
              <div className="loader-section">
                <img
                  src={this.image}
                  alt="document-back-side-capture"
                />
                <div><Loader /></div>
              </div>
            </div>
          </div>
        );
        break;
      default:
        break;
    }

    return content;
  }
}
