import { fetch } from 'utils';

/* eslint-disable no-console */
const conf = {
  log: true,
};
const log = (...args: any) => { if (conf.log) console.log(...args); };
const debug = (...args: any) => { if (conf.log) console.debug(...args); };
const info = (...args: any) => { if (conf.log) console.info(...args); };
const warn = (...args: any) => { if (conf.log) console.warn(...args); };
const error = (...args: any) => {
  if (conf.log) console.error(...args);
  let transactionCode = '';
  const tcIndex: number = args.findIndex((item: string) => (typeof item === 'string' && item.includes('Transaction Code')));
  if (tcIndex >= 0) {
    transactionCode = args[tcIndex].slice(-9);
    args.splice(tcIndex, 1);
  }
  try {
    return fetch('/log', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: 'error',
        message: JSON.stringify(args),
        transactionCode,
      }),
    })
      .catch(() => console.log('Unexpected error.'));
  } catch (e) {
    return new Promise(() => { });
  }
};

/**
 * Handle GetUserMedia error
 * from here: https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Signaling_and_video_calling
 */
const handleGetUserMediaError = (e: any) => {
  switch (e.name) {
    case 'NotFoundError':
      error('No camera and/or microphone were found.');
      break;
    case 'SecurityError':
      error('Security error');
      break;
    case 'PermissionDeniedError':
      error('Permission Denied error');
      break;
    default:
      error(`Error opening your camera and/or microphone: ${e.message}`);
      break;
  }
};

export {
  log,
  debug,
  info,
  warn,
  error,
  handleGetUserMediaError,
};
