import React, { Component } from 'react';
import Header from 'components/header/Header';
import VideoStream from 'components/videoStream/VideoStream';
import AdditionalDocContent from 'modules/additionalDoc/additionalDocContent/AdditionalDocContent';
import { DoneStatus } from 'types/enum'; // eslint-disable-line no-unused-vars
import { IData } from 'types/index.d'; // eslint-disable-line no-unused-vars

interface IProps {
  onDone: (status: DoneStatus) => void;
  addDVSLog: (data: string) => void;
  changeData: (data: Partial<IData>) => void;
  appState: any;
}

export default class AdditionalDoc extends Component<IProps> {
  goBack = () => {
    window.history.back();
  };

  render() {
    const {
      onDone,
      appState,
      changeData,
      addDVSLog,
    } = this.props;

    return (
      <div className="comp-dv">
        <Header />
        <VideoStream appState={appState}>
          <AdditionalDocContent
            onDone={onDone}
            appState={appState}
            changeData={changeData}
            addDVSLog={addDVSLog}
          />
        </VideoStream>
      </div>
    );
  }
}
