import React from 'react';
import { getNonBlockingErrorCodes, getBlockingErrorCodes } from 'utils/flowController';
import End from 'modules/verificationEnd/views/end/End';
import EndFailed from 'modules/verificationEnd/views/endFailed/EndFailed';
/* eslint-disable no-unused-vars */
import { IData } from 'types/index.d';
import { DoneStatus } from 'types/enum';

enum Views {
  success = 'success',
  fail = 'fail',
}
/* eslint-enabled no-unused-vars */

interface IProps {
  data: IData;
  blockingErrorCodes: number[],
  onDone: (status: DoneStatus) => void;
  changeData: (data: Partial<IData>) => void;
  shareData: () => void;
  exitUrl: string;
}
interface IState {
  view: Views;
}

class VerificationEnd extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      view: this.getPage(),
    };
  }

  getPage = () => {
    const { data, blockingErrorCodes } = this.props;
    const { errorCode, fail } = data.verification;

    return fail || getBlockingErrorCodes(blockingErrorCodes).includes(errorCode)
      ? Views.fail : Views.success;
  };

  checkIfWarning = () => {
    const { data, blockingErrorCodes } = this.props;
    const { errorCode } = data.verification;
    return getNonBlockingErrorCodes(blockingErrorCodes).includes(errorCode);
  };

  render() {
    const {
      data,
      exitUrl,
      onDone,
      changeData,
      shareData
    } = this.props;
    const { isSecondTry, serviceProviderData : {
      data: {
        customer = {},
      },
    } } = data;
    const { view } = this.state;
    let content;
    switch (view) {
      case Views.success:
        content = (
          <End
            data={data}
            shareData={shareData}
            warning={this.checkIfWarning()}
            onDone={onDone}
          />
        );
        break;
      case Views.fail:
        content = (
          <EndFailed
            isSecondTry={isSecondTry}
            exitUrl={exitUrl}
            customer={customer}
            onDone={onDone}
            changeData={changeData}
            shareData={shareData}
          />
        );
        break;
      default:
        break;
    }

    return content;
  }
}


export default VerificationEnd;