import { handleFetchErrors, fetch } from 'utils';
import { error } from 'utils/log';

type TransmitDataOpts = {
  additionalDoc: any,
  docBackSide: any,
  dvsLogs: string[],
};

const getDVSFiles = (additionalDoc: string, docBackSide: string, dvsLogs: string[]) => {
  const docBackSideImage: any = docBackSide ? docBackSide.replace(/^data:image\/jpeg;base64,/, '') : null;
  const additionalDocImage: any = additionalDoc ? additionalDoc.replace(/^data:image\/jpeg;base64,/, '') : null;

  const files: any = [
    {
      content: docBackSideImage,
      encoding: 'base64',
      method: 'write',
      name: 'thirdImage.jpg',
    }, {
      content: additionalDocImage,
      encoding: 'base64',
      method: 'write',
      name: 'additionaldoc.jpg',
    }, {
      content: dvsLogs.join('\n'),
      method: 'write',
      name: 'dvs.log',
    },
  ];
  const dvsFiles: any[] = files.filter((file: any) => file.content);

  return dvsFiles;
};

const transmitData = (opts: TransmitDataOpts) => new Promise((resolve: any, reject: any) => {
  const { additionalDoc, docBackSide, dvsLogs } = opts;
  dvsLogs.push(`${new Date().toISOString()} Start data transmission.`);

  const dvsFiles: any[] = getDVSFiles(additionalDoc, docBackSide, dvsLogs);
  fetch('/transmit_dvs', {
    method: 'POST',
    body: JSON.stringify({ dvsFiles }),
  })
    .then(handleFetchErrors)
    .then(resolve)
    .catch((err: any) => {
      const { message, stack } = err;
      error(`Something went wrong while sending data: message: ${message} stack: ${stack}`);
      reject(err);
    });
});

export default transmitData;
