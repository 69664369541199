import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import { ReactComponent as IdPic } from 'assets/images/identity-card.svg';
import { ReactComponent as PassportPic } from 'assets/images/passport.svg';
import { ReactComponent as PaperPermitPic } from 'assets/images/swiss-paper-permit.svg';
import { ReactComponent as Forward } from 'assets/images/arrow-right.svg';
import { DocumentTypes } from 'resources/enums';

interface IProps {
  selectTypeOptions: any;
  selectTypeCallback: (documentType: DocumentTypes) => void;
}

export default class SelectType extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
    window.scrollTo(0, 0);
  }

  selectType(documentType: DocumentTypes) {
    const { selectTypeCallback } = this.props;

    selectTypeCallback(documentType);
  }

  render() {
    const { selectTypeOptions } = this.props;
    const { id, passport, paperPermit } = selectTypeOptions;
    return (
      <div className="card">

        <h1><Translate i18nKey="select.title" /></h1>
        <div className="doc-types">
          {id && (
            <div
              className="clicable-area"
              role="button"
              tabIndex={-1}
              onKeyPress={() => this.selectType(DocumentTypes.id)}
              onClick={() => this.selectType(DocumentTypes.id)}
            >
              <div className="clicable-area-item"><IdPic className="doc-type-icon id-pic" /></div>
              <div className="clicable-area-item text-caption">
                <b><Translate i18nKey="select.id.text1" /></b>
                {' '}
                <Translate i18nKey="select.id.text2" />
              </div>
              <div className="clicable-area-item"><Forward className="forward-icon" /></div>
            </div>
          )}

          {passport && (
            <div
              className="clicable-area"
              role="button"
              tabIndex={-1}
              onKeyPress={() => this.selectType(DocumentTypes.passport)}
              onClick={() => this.selectType(DocumentTypes.passport)}
            >
              <div className="clicable-area-item"><PassportPic className="doc-type-icon passport-pic" /></div>
              <div className="clicable-area-item text-caption">
                <b><Translate i18nKey="select.passport.text1" /></b>
                {' '}
                <Translate i18nKey="select.passport.text2" />
              </div>
              <div className="clicable-area-item"><Forward className="forward-icon" /></div>
            </div>
          )}

          {paperPermit && (
            <div
              className="clicable-area"
              role="button"
              tabIndex={-1}
              onKeyPress={() => this.selectType(DocumentTypes.paperPermit)}
              onClick={() => this.selectType(DocumentTypes.paperPermit)}
            >
              <div className="clicable-area-item"><PaperPermitPic className="doc-type-icon" /></div>
              <div className="clicable-area-item text-caption">
                <b><Translate i18nKey="select.paper-permit.text1" /></b>
                {' '}
                <span><Translate i18nKey="select.paper-permit.text2" /></span>
              </div>
              <div className="clicable-area-item"><Forward className="forward-icon" /></div>
            </div>
          )}
        </div>

      </div>
    );
  }
}
