import React, { Component } from 'react';
import { ReactComponent as FrameRect } from 'assets/images/frame-rect.svg';

type Props = {
  classNames?: string,
  children?: any,
};

type State = Record<string, never>;

export default class Frame extends Component<Props, State> {
  render(): React.ReactNode {
    const { classNames = '', children } = this.props;
    return (
      <div className="comp-id-document-scan-frame">
        <div className="frame">
          <FrameRect className={`idscan-frame ${classNames}`} />
          <div className="spacer">
            {children}
          </div>
        </div>
      </div>
    );
  }
}
