import React, { Component } from 'react';
import SuccessPictogram from 'components/successPictogram/SuccessPictogram';
import Translate from 'components/translate/Translate';
import CaptureImage from 'components/captureImage/CaptureImage';
import { ReactComponent as FrameRect } from 'assets/images/frame-rect.svg';
import { ReactComponent as AdditionalDocPic } from 'assets/images/additional-doc.svg';
import {
  DocumentTypes,
  WhatToCapture,
} from 'resources/enums';
import gaPageView from 'utils/GA';
import { error } from 'utils/log';
import { DoneStatus } from 'types/enum';

/* eslint-disable no-unused-vars */
import { IData } from 'types/index.d';

enum Statuses {
  wait = 'wait',
  instruction = 'instruction',
  capture = 'capture',
  success = 'success',
}
/* eslint-enable no-unused-vars */

interface IProps {
  onDone: (status: DoneStatus) => void;
  addDVSLog: (data: string) => void;
  changeData: (data: Partial<IData>) => void;
  appState: any;
  videoStream?: any;
}

interface IState {
  status: Statuses;
}

export default class AdditionalDocContent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      status: Statuses.wait,
    };
  }

  async componentDidMount() {
    gaPageView('/additionalDoc');
    try {
      const { videoStream } = this.props;
      await videoStream.ensureVideoStream();
      this.setState({ status: Statuses.instruction });
    } catch (err) {
      const { onDone } = this.props;
      const { message, stack } = (err || {}) as any;
      error(`Error when initializing Additional Doc: message: ${message} stack: ${stack}`);
      onDone(DoneStatus.fail);
    }
  }

  instructionOnClickHandler = () => {
    this.setState({ status: Statuses.capture });
  };

  getDocumentType = (): string => {
    const { appState } = this.props;
    const { verificationData, type } = appState;
    const { resultXML } = verificationData;
    const { permitFormat = '' } = resultXML;
    let { documentType = '' } = resultXML;
    let docType = '';
    if (documentType === 'permit' && permitFormat === 'plastic') documentType = 'plasticPermit';

    switch (documentType) {
      case 'id':
      case 'drivingLicence':
      case 'plasticPermit':
        docType = DocumentTypes.id;
        break;
      case 'passport':
        docType = DocumentTypes.passport;
        break;
      case 'permit':
        docType = DocumentTypes.paperPermit;
        break;
      default:
        docType = type;
        break;
    }

    return docType;
  };

  getInstructionMessageKey = () => {
    const documentType = this.getDocumentType();
    let instructionMessageKey: string;

    switch (documentType) {
      case DocumentTypes.id:
        instructionMessageKey = 'additional-doc.id-message';
        break;
      case DocumentTypes.passport:
        instructionMessageKey = 'additional-doc.passport-message';
        break;
      case DocumentTypes.paperPermit:
        instructionMessageKey = 'additional-doc.paper-parmit-message';
        break;
      default:
        instructionMessageKey = 'additional-doc.id-message';
        break;
    }

    return instructionMessageKey;
  };

  additionalDocShotHandler = () => {
    const {
      onDone, changeData, addDVSLog, videoStream,
    } = this.props;
    const { capturedImg } = videoStream.captureImage(WhatToCapture.additionalDoc);
    const additionalDoc: string = capturedImg;
    addDVSLog('Additional document successfully captured.');
    this.setState({ status: Statuses.success });
    setTimeout(() => {
      changeData({ additionalDoc });
      onDone(null);
    }, 1000);
  };

  render() {
    const { status } = this.state;
    let content: any = '';
    switch (status) {
      case Statuses.instruction:
        content = (
          <div className="comp-additional-doc-message">
            <div className="frame">
              <FrameRect className="additional-doc-frame" />
              <div className="spacer">
                <div className="inner-frame">
                  <div>
                    <div><AdditionalDocPic className="additional-doc-pic" /></div>
                    <div className="text"><Translate i18nKey={this.getInstructionMessageKey()} /></div>
                    <div
                      className="button button-big overlay-button"
                      role="button"
                      onClick={this.instructionOnClickHandler}
                      onKeyPress={() => { }}
                      tabIndex={-1}
                    >
                      <Translate i18nKey="additional-doc.btn.got" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case Statuses.capture:
        content = (
          <CaptureImage
            callback={this.additionalDocShotHandler}
          />
        );
        break;
      case Statuses.success:
        content = (
          <div className="comp-additional-doc-message">
            <div className="frame">
              <FrameRect className="additional-doc-frame" />
              <div className="spacer">
                <SuccessPictogram />
              </div>
            </div>
          </div>
        );
        break;
      default:
        break;
    }

    return content;
  }
}
