import React, { Component } from 'react';
import Loader from 'components/loader/Loader';
import Header from 'components/header/Header';

class Loading extends Component {
  render() {
    return (
      <div className="comp-transaction-code-loading">
        <Header />
        <div className="card">
          <div className="main-content">
            <Loader />
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;
