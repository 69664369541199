import ReactGA from 'react-ga';

import { error } from 'utils/log';
import appMetadata from 'resources/branding/appMetadata.json';
import appConfig from 'resources/branding/appconfig.json';

class GAUtils {
  constructor() {
    const { googleAnalyticsID } = appMetadata;
    if (this.needToConnectAnalytics()) {
      ReactGA.initialize(googleAnalyticsID, { testMode: process.env.NODE_ENV === 'test' });
    } else {
      error('Google Analytics configuration is not found!');
    }
  }

  needToConnectAnalytics = () => {
    const { googleAnalyticsID } = appMetadata;
    const { enableAnalytics } = appConfig;
    return enableAnalytics && googleAnalyticsID;
  };

  gaPageView = (page: string) => {
    if (this.needToConnectAnalytics()) {
      ReactGA.set({ page });
      ReactGA.pageview(page);
    }
  };
}

const gaUtils = new GAUtils();
const { gaPageView } = gaUtils;

export default gaPageView;
