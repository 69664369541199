import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import { ReactComponent as IconBack } from 'assets/images/arrow-left.svg';
import { ReactComponent as ScrollToTop } from 'assets/images/scroll-to-top.svg';

type Props = {
  goBack: () => void,
  lang: string,
  terms: any,
  type: 'tac' | 'privacyPolicy' | 'additionalPrivacyPolicy'
};

export default class TaC extends Component<Props> {
  private topButtonRef = React.createRef<HTMLDivElement>();

  private backButtonRef = React.createRef<HTMLDivElement>();

  goToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  getTermsContent = (lang: string, terms: any, type: string) => (
    <div dangerouslySetInnerHTML={{ __html: terms[lang][type] }} /> // eslint-disable-line
  );

  render() {
    const {
      lang,
      terms,
      type,
      goBack,
    } = this.props;

    return (
      <div className="comp-legal-doc">
        <div className="card">
          <div className="contents">
            {this.getTermsContent(lang, terms, type)}
            <div className="footer">
              <div
                className="back-button"
                ref={this.backButtonRef}
                role="button"
                onKeyDown={goBack}
                onClick={goBack}
                tabIndex={-1}
              >
                <IconBack className="icon-back" />
                <span><Translate i18nKey="privacy-policy.btn.back" /></span>
              </div>
              <div
                className="top-button"
                ref={this.topButtonRef}
                role="button"
                onKeyDown={this.goToTop}
                onClick={this.goToTop}
                tabIndex={-1}
              >
                <ScrollToTop />
                <span><Translate i18nKey="privacy-policy.btn.top" /></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
