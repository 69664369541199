import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import { ReactComponent as IdViz } from 'assets/images/id-viz.svg';
import { ReactComponent as IdMrz } from 'assets/images/id-mrz.svg';
import { ReactComponent as PassportPic } from 'assets/images/instruction-passport.svg';
import { ReactComponent as PaperPermit1 } from 'assets/images/swiss-paper-permit-viz.svg';
import { ReactComponent as PaperPermit2 } from 'assets/images/swiss-paper-permit-data.svg';
import { DocumentTypes, IdDocumentSide } from 'resources/enums';

type Props = {
  documentType: DocumentTypes,
  side: IdDocumentSide,
  onClickHandler: any
};

type State = Record<string, never>;

export default class DocumentRecordingInstructions extends Component<Props, State> {
  getDocumentRecordingInstruction = (
    ImageComponent: any, className: string, picClassName: string, translationKey: string,
  ): React.ReactNode => {
    const { onClickHandler } = this.props;
    // TODO: Revise classes, main div and pic classes are mixed up, need something common
    return (
      <div className={className}>
        <div><ImageComponent className={`pic ${picClassName}`} /></div>
        <div className="text"><Translate i18nKey={translationKey} /></div>
        <div
          className="button button-big overlay-button"
          role="button"
          onClick={onClickHandler}
          onKeyPress={() => { }}
          tabIndex={-1}
        >
          <Translate i18nKey="dv.doc-record.btn.ok" />
        </div>
      </div>
    );
  };

  render(): React.ReactNode {
    const { documentType, side } = this.props;
    let content;
    switch (`${documentType}-${side}`) {
      case `${DocumentTypes.id}-${IdDocumentSide.side1}`:
        content = this.getDocumentRecordingInstruction(IdViz, '', 'pic-id', 'dv.doc-record.id.record1-message');
        break;
      case `${DocumentTypes.id}-${IdDocumentSide.side2}`:
        content = this.getDocumentRecordingInstruction(IdMrz, '', 'pic-id', 'dv.doc-record.id.record2-message');
        break;
      case `${DocumentTypes.passport}-${IdDocumentSide.side1}`:
        content = this.getDocumentRecordingInstruction(PassportPic, '', '', 'dv.doc-record.passport.record-message');
        break;
      case `${DocumentTypes.paperPermit}-${IdDocumentSide.side1}`:
        content = this.getDocumentRecordingInstruction(PaperPermit1, 'paper-permit', '', 'dv.doc-record.paper-permit.record1-message');
        break;
      case `${DocumentTypes.paperPermit}-${IdDocumentSide.side2}`:
        content = this.getDocumentRecordingInstruction(PaperPermit2, '', 'pic-paper-permit', 'dv.doc-record.paper-permit.record2-message');
        break;
      default:
        break;
    }

    return (
      <div className="inner-frame">
        {content}
      </div>
    );
  }
}
