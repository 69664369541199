import React, { Component } from 'react';

import Header from 'components/header/Header';
import ThankYouView from 'modules/thankYou/views/thankYou/ThankYou';
import Feedback from 'modules/thankYou/views/feedback/Feedback';
import { Views } from 'modules/thankYou/types.d';

interface State {
  view: Views;
  prevView: Views,
  finish: boolean,
  feedback: boolean,
  loading: boolean,
}

interface Props {
  feedbackEnabled: boolean,
  finish: any,
  serviceProviderData: any,
  exitURL: string,
}

class ThankYou extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      view: Views.thankyou,
      prevView: null,
      finish: false, // eslint-disable-line react/no-unused-state
      feedback: true,
      loading: false,
    };
  }

  setLoading = (loading: boolean) => {
    this.setState({ loading });
  };

  setFeedback = (feedback: any) => {
    this.setState({ feedback });
  };

  setView = (view: Views) => {
    const { view: prevView } = this.state;
    this.setState({ view, prevView });
  };

  goBack = () => {
    const { prevView } = this.state;
    this.setView(prevView);
  };

  getCurrentView = (view: Views): React.ReactElement => {
    const { loading, feedback } = this.state;
    const {
      finish,
      feedbackEnabled,
      exitURL,
      serviceProviderData,
    } = this.props;
    const feedbackBlockStyle: { display?: string } = {};
    const isfeedbackButtonExist = feedback && feedbackEnabled;

    if (!feedback || !feedbackEnabled) {
      feedbackBlockStyle.display = 'none';
    }

    let component: React.ReactElement = (
      <ThankYouView
        giveFeedback={() => this.setView(Views.feedback)}
        finish={finish}
        isfeedbackButtonExist={isfeedbackButtonExist}
        feedbackBlockStyle={feedbackBlockStyle}
        exitURL={exitURL}
      />
    );

    if (view === Views.feedback) {
      component = (
        <Feedback
          serviceProviderData={serviceProviderData}
          setLoading={this.setLoading}
          setFeedback={this.setFeedback}
          loading={loading}
          goBack={this.goBack}
          goToThankYou={() => this.setView(Views.thankyou)}
        />
      );
    }
    return component;
  };

  render() {
    const { view, loading } = this.state;
    const backButton = view === Views.feedback ? 'back' : '';

    return (
      <div>
        <Header icons={backButton} goBack={this.goBack} loading={loading} />
        {this.getCurrentView(view)}
      </div>
    );
  }
}

export default ThankYou;
