import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import Loader from 'components/loader/Loader';

type Props = {
  image: string,
  continueCallback: () => void,
  recaptureCallback: (data?: any) => void,
};

type State = {
  loading: boolean,
};

export default class ManualCaptureMessage extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const { image, continueCallback, recaptureCallback } = this.props;
    const { loading } = this.state;

    return (
      <div className="comp-manual-capture-message">
        <div className="captured-section">
          <div>
            <div className={`confirmation-section ${loading ? 'loader-section' : ''}`}>
              {
                loading ? '' : <div className="title"><Translate i18nKey="dv.manual-capture.confirmation-message" /></div>
              }
              <img
                src={image}
                alt="manual-capture"
              />
              {
                loading ? <div className="loader-container"><Loader /></div>
                  : (
                    <div className="confirmaition-links">
                      <div
                        className="button button-big"
                        role="button"
                        tabIndex={-1}
                        onKeyPress={() => { }}
                        onClick={recaptureCallback as any}
                      >
                        <Translate i18nKey="dv.manual-capture.recapture" />
                      </div>
                      <div
                        className="button button-big"
                        role="button"
                        tabIndex={-1}
                        onKeyPress={() => { }}
                        onClick={() => {
                          this.setState({ loading: true });
                          continueCallback();
                        }}
                      >
                        <Translate i18nKey="dv.manual-capture.continue" />
                      </div>
                    </div>
                  )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
