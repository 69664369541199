import React, { Component } from 'react';

import Header from 'components/header/Header';
import TermsText from 'components/termsText/TermsText';
import TermsAgreement from 'modules/terms/views/termsAgreement/TermsAgreement';
import AppContext from 'modules/AppContext';
import { ISettings } from 'types/index.d'; // eslint-disable-line no-unused-vars, @typescript-eslint/no-unused-vars
import { Views } from 'modules/terms/types.d';

import * as termsText from 'tac/languages';

interface State {
  view: Views;
  prevView: Views,
  allowUseDataForML: boolean | string,
  TaC: boolean,
}

interface Props {
  settings: ISettings;
  terms: any;
  onDone: (data: any) => void;
  allowShareDataForML: any,
}

class Terms extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    const { settings: { TaC, allowUseDataForML } } = this.props;
    this.state = {
      view: Views.termsAgreement,
      prevView: null,
      allowUseDataForML, // eslint-disable-line react/no-unused-state
      TaC, // eslint-disable-line react/no-unused-state
    };
  }

  getData = (data: any) => {
    const { onDone } = this.props;
    this.setState({
      allowUseDataForML: data.allowUseDataForML, // eslint-disable-line react/no-unused-state
    });
    onDone(data);
  };

  setView = (view: Views) => {
    const newView = view;
    const { view: currentView } = this.state;

    this.setState({
      view: (newView as Views),
      prevView: currentView, // eslint-disable-line react/no-unused-state
    });
  };

  goBack = () => {
    const { prevView } = this.state;
    this.setView(prevView);
  };

  getCurrentView = (view: Views): React.ReactElement => {
    const { lang } = this.context;
    const { langs, default: defaultLang } = lang;
    let { currentLang } = lang;
    currentLang = langs.includes(currentLang) ? currentLang : defaultLang;
    const { terms, allowShareDataForML, } = this.props;
    const { settings: { allowUseDataForML } } = this.props;
    let component: React.ReactElement = (
      <TermsAgreement
        onDone={this.getData}
        setView={this.setView}
        terms={terms}
        allowShareDataForML={allowShareDataForML}
        allowUseDataForML={allowUseDataForML}
      />
    );
    if (view === Views.privacyPolicy) {
      component = (
        <TermsText
          goBack={this.goBack}
          lang={currentLang}
          terms={termsText}
          type='privacyPolicy'
        />
      );
    } else if (view === Views.termsAndConditions) {
      component = (
        <TermsText
          goBack={this.goBack}
          lang={currentLang}
          terms={termsText}
          type='tac'
        />
      );
    } else if (view === Views.additionalPrivacyPolicy) {
      component = (
        <TermsText
          goBack={this.goBack}
          lang={currentLang}
          terms={termsText}
          type='additionalPrivacyPolicy'
        />
      );
    }
    return component;
  };

  render() {
    const { view } = this.state;
    const backButton = (view === Views.privacyPolicy || view === Views.termsAndConditions) ? 'back' : '';

    return (
      <>
        <Header icons={backButton} goBack={this.goBack} />
        {this.getCurrentView(view)}
      </>
    );
  }
}

Terms.contextType = AppContext;

export default Terms;
