import React, { Component } from 'react';
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import Select, { Props as InputProps } from 'react-select';
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */
import Translate from 'components/translate/Translate';

interface IProps {
  label: string;
  name: string;
  changeState: (obj: { [key: string]: any }) => void;
  options: any[];
  error: boolean,
  errorText: string;
  className?: string;
  inputProps?: InputProps;
}

export default class SelectBox extends Component<IProps> {
  render() {
    const {
      label,
      name,
      options,
      changeState,
      error,
      errorText,
      className = '',
      inputProps,
    } = this.props;
    const defaultClassNames = ['custom-input', 'select-box'];

    return (
      <div className={[...defaultClassNames, className, ...(error ? ['error-input'] : [])].join(' ')}>
        <label><Translate i18nKey={label} /></label>
        <div className="input-container">
          <Select
            classNamePrefix="custom-menu"
            isSearchable
            placeholder=""
            name={name}
            labelKey={name}
            className="custom-menu"
            onChange={({ value }) => changeState({
              [name]: value,
            })}
            options={options}
            {...inputProps}
          />
        </div>
        {false && error && <div className="input-error select-box-error"><Translate i18nKey={errorText} /></div>}
      </div>
    );
  }
}
