import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import Header from 'components/header/Header';
import Loader from 'components/loader/Loader';
import { ReactComponent as SuccessPic } from 'assets/images/picto-success.svg';
import { ReactComponent as WarningPic } from 'assets/images/picto-warning.svg';
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { IData } from 'types/index.d';
import { DoneStatus } from 'types/enum';
/* eslint-enabled no-unused-vars, @typescript-eslint/no-unused-vars */

type Props = {
  warning: boolean;
  data: IData;
  onDone: (status: DoneStatus) => void;
  shareData: () => void;
};

type State = {
  loading: boolean,
};

export default class End extends Component<Props, State> {

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  async componentDidMount() {
    try {
      const { shareData } = this.props;
      this.setState({ loading: true });
      await shareData();
    } finally {
      this.setState({ loading: false }, () => {
        setTimeout(() => {
          this.continue();
        }, 2000);
      });
    }
  }

  missingMrzAndViz = () => {
    const { data } = this.props;
    const { verification } = data;
    const { resultXML = {} } = verification;
    const { mrz, viz } = resultXML;

    return !mrz && !viz;
  };

  continue() {
    const { onDone } = this.props;
    onDone(null);
  }

  render() {
    const { loading } = this.state;
    const { warning } = this.props;
    let endPictogram: any = <div><SuccessPic className="pic" /></div>;
    let endMessage: any = '';
    const endTitle: any = loading ? (
      <Translate i18nKey="end.loading-title" />
    ) : (
      <Translate i18nKey="end.title" />
    );

    if (loading) {
      endMessage = <Translate i18nKey="end.data-sending" />;
      endPictogram = (
        <div className="loader-container">
          <Loader />
        </div>
      );
    } else if (warning) {
      endPictogram = <div><WarningPic className="pic" /></div>;
      endMessage = <Translate i18nKey="end.warning-message" />;
    } else if (this.missingMrzAndViz()) {
      endMessage = <Translate i18nKey="end.review-message" />;
    } else {
      endMessage = <Translate i18nKey="end.message" />;
    }

    return (
      <div className="comp-end">
        <Header loading={loading} />
        <div className="card">

          <div className="contents">
            <div className="main-content">
              <div className="congrats">
                {endTitle}
              </div>
              {endPictogram}
              <div className="message">
                <div>{endMessage}</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
