import React from 'react';
import Cookies from 'js-cookie';
import Terms from 'modules/terms/Terms';
import ThankYou from 'modules/thankYou/ThankYou';
import TransactionCode from 'modules/transactionCode/TransactionCode';
import Swipe from 'components/swipe/Swipe';
/* eslint-disable @typescript-eslint/no-unused-vars, no-unused-vars */
import {
  IDraftTransactionCodeState,
  IData,
  IState,
  ISignedContract,
  TStateData,
} from 'types/index.d';
/* eslint-enable @typescript-eslint/no-unused-vars, no-unused-vars */
import { DoneStatus, Modules } from 'types/enum';
import AppContext from 'modules/AppContext';
import NotFound from 'modules/_404/404';
import DesktopNotSupported from 'modules/desktopNotSupported/DesktopNotSupported';
import DeviceNotSupported from 'modules/deviceNotSupported/DeviceNotSupported';
import DeviceTimeIsWrong from 'modules/deviceTimeIsWrong/DeviceTimeIsWrong';
import InformationForMtan from 'modules/informationForMtan/InformationForMtan';
import EnvironmentTest from 'modules/environmentTest/EnvironmentTest';
import SelfDeclaration from 'modules/selfDeclaration/SelfDeclaration';
import VerificationEnd from 'modules/verificationEnd/VerificationEnd';
import Start from 'modules/start/Start';
import SomethingWentWrong from 'modules/somethingWentWrong/SomethingWentWrong';
import VerifyCode from 'modules/verifyCode/VerifyCode';
import DocumentVerification from 'modules/documentVerification/DocumentVerification';
import NewVersionIsAvailable from 'modules/newVersionIsAvailable/NewVersionIsAvailable';
import LFV from 'modules/lfv/LFV';
import AdditionalDoc from 'modules/additionalDoc/AdditionalDoc';
import transmitData from 'services/TransmitData.service';
import UtilityBill from 'modules/utilityBill/UtilityBill';
import Contract from 'modules/contract/Contract';
import { closeFullscreen } from 'utils';

interface Props {
  appState: IState;
  changeHistory?: (obj: { view: Modules }) => void;
  changeSettings?: (obj: any) => void;
  changeData?: (obj: any) => void;
  changeAppConfig?: (obj: any) => void;
  changeState?: (key: keyof IState, data: TStateData) => void;
  addDVSLog?: (log: string) => void;
  setLang?: (obj: any) => void;
  leaveApp?: () => void;
  getViewsList?: () => any;
}

interface State {
  loading: boolean;
  customBack: any;
  customForward: any;
}

class ModuleManager extends React.Component<Props, State> {
  private sessionIsExpiredInterval: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      customBack: null,
      customForward: null,
    };
  }

  componentDidUpdate(prevProps: Props): void {
    const { appState } = this.props;
    const { appState: prevAppState } = prevProps;
    const currentModule: Modules = appState.history.view;
    const prevModule: Modules = prevAppState.history.view;
    const modulesRequireFullscreen: Modules[] = [Modules.dv, Modules.lfv, Modules.additionalDoc, Modules.utilityBill];
    if (modulesRequireFullscreen.includes(prevModule)
      && !modulesRequireFullscreen.includes(currentModule)) {
      closeFullscreen();
    }
    if (currentModule === Modules.dv && prevModule !== Modules.dv) {
      this.sessionIsExpiredInterval = setInterval(this.resetIfSessionIsExpired, 10 * 1000); // Check session expire time every 10 seconds
    }
  }

  componentWillUnmount(): void {
    this.clearSessionIsExpiredInterval();
  }

  getCurrentModule(): React.ReactElement {
    const { appState } = this.props;
    const { history } = appState;
    const currentModule: Modules = history.view;

    switch (currentModule) {
      case Modules.desktopNotSupported:
        return this.renderDesktopNotSupported();
      case Modules.deviceNotSupported:
        return this.renderDeviceNotSupported();
      case Modules.transactioncode:
        return this.renderTransactionCode();
      case Modules.terms:
        return this.renderTerms();
      case Modules.start:
        return this.renderStart();
      case Modules.envTest:
        return this.renderEnvTest();
      case Modules.mtan:
        return this.renderMTAN();
      case Modules.selfDeclaration:
        return this.renderSelfDeclaration();
      case Modules.dv:
        return this.renderDocumentVerification();
      case Modules.lfv:
        return this.renderLFV();
      case Modules.additionalDoc:
        return this.renderAdditionalDoc();
      case Modules.utilityBill:
        return this.renderUtilityBill();
      case Modules.contract:
        return this.renderContract();
      case Modules.verificationEnd:
        return this.renderEnd();
      case Modules.thankYou:
        return this.renderThankYou();
      case Modules.deviceTimeIsWrong:
        return this.renderDeviceWrongTime();
      case Modules.somethingWentWrong:
        return this.renderSomethingWentWrong();
      case Modules.verifyCode:
        return this.renderVerifyMtan();
      case Modules.newVersionIsAvailable:
        return this.renderNewVersionIsAvailable();
      case Modules.notFound:
      default:
        return this.renderNotFound();
    }
  }

  clearSessionIsExpiredInterval = (): void => {
    clearTimeout(this.sessionIsExpiredInterval);
  };

  resetIfSessionIsExpired = (): void => {
    if (this.sessionIsExpired()) {
      window.location.reload();
    }
  };

  sessionIsExpired(): boolean {
    return !Cookies.get('session_expires');
  }

  getNextModule = (current: Modules): Modules => {
    const { appState: { history } } = this.props;
    const nextIndex = history.views.indexOf(current) + 1;
    return history.views[nextIndex];
  };

  getPrevModule = (current: Modules): Modules => {
    const { appState: { history } } = this.props;
    const prevIndex = history.views.indexOf(current) - 1;
    return history.views[prevIndex];
  };

  shareData = async () => {
    const { appState } = this.props;
    const { data } = appState;
    const { additionalDoc, docBackSide, dvsLogs } = data;
    const opts = { additionalDoc, docBackSide, dvsLogs };
    await this.setState({ loading: true });
    await transmitData(opts);
    this.clearSessionIsExpiredInterval();
    await this.setState({ loading: false });
  };

  getFirstEnabledViewAfter = (view: Modules) => {
    const { appState } = this.props;
    const { views, originalViews } = appState.history;
    const index: number = originalViews.indexOf(view);
    const viewsAfter = originalViews.slice(index + 1);
    return viewsAfter.find((va) => views.includes(va));
  };

  ensureNavigationTo = async (view: Modules = null) => {
    const { appState } = this.props;
    const { view: currentView, views } = appState.history;

    let targetView: Modules;
    if (view) {
      targetView = views.includes(view) ? view : this.getFirstEnabledViewAfter(view);
    } else {
      const currentViewIndex: number = views.indexOf(currentView);
      const targetViewIndex: number = currentViewIndex + 1;
      targetView = views[targetViewIndex];
    }

    const shoulShareData: boolean = !views.includes(Modules.verificationEnd)
      && (!targetView || targetView === this.getFirstEnabledViewAfter(Modules.verificationEnd));
    if (shoulShareData) await this.shareData();

    if (!targetView) {
      const { leaveApp } = this.props;
      leaveApp();
      return;
    }

    const { changeHistory } = this.props;
    changeHistory({ view: targetView });
  };

  renderTransactionCode = (): React.ReactElement => {
    const {
      changeData,
      changeSettings,
      changeState,
      changeAppConfig,
      getViewsList,
      appState:
      {
        data: { serviceProviderData },
        browser,
        session,
        appConfig,
        settings: { exitURL },
        urlParams: { tc },
      },
    } = this.props;
    return (
      <TransactionCode
        onDone={(draft: IDraftTransactionCodeState) => {
          const {
            serviceProviderData: draftServiceProviderData,
            exitURL: draftExitUrl,
            appConfig: {
              modules: draftModules,
            },
          } = draft;
          changeData({ serviceProviderData: draftServiceProviderData });
          changeSettings({ exitURL: draftExitUrl });
          changeAppConfig({
            modules: draftModules,
          });
          this.ensureNavigationTo();
        }}
        serviceProviderData={serviceProviderData}
        browser={browser}
        exitURL={exitURL}
        viewConfig={appConfig}
        session={session}
        tc={tc}
        getViewsList={getViewsList}
        changeState={changeState}
      />
    );
  };

  renderStart = (): React.ReactElement => {
    const { appState } = this.props;
    const { supportEmail } = appState.appConfig;
    return (
      <Start
        supportEmail={supportEmail}
        onDone={this.ensureNavigationTo}
      />
    );
  };

  renderTerms = (): React.ReactElement => {
    const { appState, changeSettings } = this.props;
    const { settings, appConfig } = appState;
    const { terms } = appConfig.urls;
    const { allowShareDataForML } = appConfig;

    return (
      <Terms
        settings={settings}
        terms={terms}
        allowShareDataForML={allowShareDataForML}
        onDone={(data: any) => {
          changeSettings(data);
          this.ensureNavigationTo();
        }}
      />
    );
  };

  renderDesktopNotSupported = (): React.ReactElement => {
    return (
      <DesktopNotSupported />
    );
  };

  renderDeviceNotSupported = (): React.ReactElement => {
    const { appState } = this.props;
    const { appConfig } = appState;
    return (
      <DeviceNotSupported
        supportEmail={appConfig.supportEmail}
        brandName={appConfig.brand.name}
      />
    );
  };

  renderDeviceWrongTime = (): React.ReactElement => {
    return (
      <DeviceTimeIsWrong />
    );
  };

  renderSomethingWentWrong = (): React.ReactElement => {
    const { appState } = this.props;
    const { appConfig } = appState;
    return (
      <SomethingWentWrong
        config={!!appConfig}
      />
    );
  };

  renderNewVersionIsAvailable = (): React.ReactElement => {
    const { appState } = this.props;
    const { appConfig } = appState;
    return (
      <NewVersionIsAvailable config={!!appConfig} />
    );
  };

  renderMTAN = (): React.ReactElement => {
    const {
      appState: {
        appConfig: {
          lang,
        },
        data: {
          mtan,
        },
      },
      changeData,
    } = this.props;
    return (
      <InformationForMtan
        currentLang={lang.currentLang}
        data={mtan}
        onDone={(data: any) => {
          changeData({ mtan: data });
          this.ensureNavigationTo();
        }}
      />
    );
  };

  renderSelfDeclaration = (): React.ReactElement => {
    const { changeData } = this.props;
    return (
      <SelfDeclaration
        onDone={(personalDetails: any) => {
          changeData({ personalDetails });
          this.ensureNavigationTo();
        }}
      />
    );
  };

  renderDocumentVerification = (): React.ReactElement => {
    const {
      changeData,
      changeHistory,
      addDVSLog,
      appState,
    } = this.props;
    return (
      <DocumentVerification
        appState={{
          config: appState.appConfig,
          data: appState.data,
          viewsList: appState.history.views,
          session: appState.session,
          orientation: appState.browser.orientation,
          isOnline: appState.browser.isOnline,
          allowUseDataForML: appState.settings.allowUseDataForML,
          oi: appState.oi,
        }}
        onDone={(status: DoneStatus) => {
          if (status === DoneStatus.exit) {
            this.ensureNavigationTo(Modules.verificationEnd);
            changeData({ dvFailed: true });
          } else if (status === DoneStatus.fail) {
            changeHistory({ view: Modules.somethingWentWrong });
          } else if (status === DoneStatus.wrongTime) {
            changeHistory({ view: Modules.deviceTimeIsWrong });
          } else {
            this.ensureNavigationTo();
          }
        }}
        changeData={changeData}
        addDVSLog={addDVSLog}
      />
    );
  };

  renderLFV = (): React.ReactElement => {
    const {
      changeData,
      changeHistory,
      addDVSLog,
      appState,
    } = this.props;
    const { loading } = this.state;
    return (
      <LFV
        appState={{
          config: appState.appConfig,
          verificationData: appState.data.verification,
          viewsList: appState.history.views,
          session: appState.session,
          orientation: appState.browser.orientation,
          isOnline: appState.browser.isOnline,
          loading,
        }}
        changeData={changeData}
        onDone={(status: DoneStatus) => {
          if (status === DoneStatus.exit) {
            this.ensureNavigationTo(Modules.verificationEnd);
            changeData({ dvFailed: true });
          } else if (status === DoneStatus.fail) {
            changeHistory({ view: Modules.somethingWentWrong });
          } else {
            this.ensureNavigationTo();
          }
        }}
        addDVSLog={addDVSLog}
      />
    );
  };

  renderAdditionalDoc = (): React.ReactElement => {
    const {
      changeData,
      addDVSLog,
      appState,
    } = this.props;
    return (
      <AdditionalDoc
        appState={{
          verificationData: appState.data.verification,
          config: appState.appConfig,
          viewsList: appState.history.views,
          session: appState.session,
          type: appState.data.selectedType,
        }}
        changeData={changeData}
        addDVSLog={addDVSLog}
        onDone={() => {
          this.ensureNavigationTo();
        }}
      />
    );
  };

  renderUtilityBill = (): React.ReactElement => {
    const { appState, addDVSLog } = this.props;
    return (
      <UtilityBill
        onDone={(err: any) => {
          if (err) {
            const { changeHistory } = this.props;
            changeHistory({ view: Modules.somethingWentWrong });
          } else {
            this.ensureNavigationTo();
          }
        }}
        appState={{
          config: appState.appConfig,
          viewsList: appState.history.views,
          session: appState.session,
          orientation: appState.browser.orientation,
          isOnline: appState.browser.isOnline,
        }}
        addDVSLog={addDVSLog}
      />
    );
  };

  renderContract = (): React.ReactElement => {
    const {
      appState: {
        appConfig: {
          lang,
        },
        data: {
          processId,
          mtan,
          personalDetails,
          serviceProviderData: {
            data: {
              transaction
            },
          },
        },
      },
      changeData,
    } = this.props;
    return (
      <Contract
        currentLanguage={lang.currentLang}
        transaction={transaction}
        processId={processId}
        mtan={mtan}
        firstName={personalDetails.name}
        lastName={personalDetails.surname}
        onDone={(signedContract: ISignedContract) => {
          changeData({ signedContract });
          this.ensureNavigationTo();
        }}
      />
    );
  };

  renderEnvTest = (): React.ReactElement => (
    <EnvironmentTest
      onDone={this.ensureNavigationTo}
    />
  );

  renderThankYou = (): React.ReactElement => {
    const { appState: { data: { serviceProviderData }, appConfig } } = this.props;
    const {
      linkButtons: { finish },
      urls: { exit: exitURL },
      modules: { thankYou: { feedback } },
    } = appConfig;
    return (
      <ThankYou
        feedbackEnabled={feedback}
        finish={finish}
        serviceProviderData={serviceProviderData}
        exitURL={exitURL}
      />
    );
  };

  renderEnd = (): React.ReactElement => {
    const { appState, changeData } = this.props;
    const { data, appConfig } = appState;
    return (
      <VerificationEnd
        data={data}
        shareData={this.shareData}
        blockingErrorCodes={appConfig.flowRules.side1.retry.errorCodes}
        changeData={changeData}
        exitUrl={appState.settings.exitURL}
        onDone={(status: DoneStatus) => {
          if (status === DoneStatus.retry) {
            this.ensureNavigationTo(Modules.dv);
          } else {
            this.ensureNavigationTo();
          }
        }}
      />
    );
  };

  renderNotFound = (): React.ReactElement => (
    <NotFound />
  );

  renderVerifyMtan = (): React.ReactElement => {
    const { appState } = this.props;
    const { data, appConfig } = appState;
    const { code } = data.serviceProviderData.data.transaction;
    return (
      <VerifyCode
        mtan={data.mtan}
        processId={data.processId}
        tc={code}
        viewConfig={appConfig}
        onDone={this.ensureNavigationTo}
      />
    );
  };

  goBack = () => {
    // const { appState: { history } } = this.props;
    const { customBack } = this.state;
    if (customBack && typeof customBack === 'function') {
      customBack();
      return;
    }
    window.history.back();
  };

  goForward = () => {
    const { customForward } = this.state;
    if (customForward && typeof customForward === 'function') {
      customForward();
      return;
    }
    this.ensureNavigationTo();
  };

  changeGoes = ({ customBack, customForward }: { customBack: any; customForward: any }) => {
    this.setState({ customBack, customForward });
  };

  render() {
    const { setLang, leaveApp, appState } = this.props;
    const { history, appConfig, urlParams } = appState;
    const { closeButtonOnErrorScreens } = appConfig;
    return (
      <AppContext.Provider value={{
        setLang,
        urlParams,
        lang: appConfig.lang,
        history,
        leaveApp,
        closeButtonOnErrorScreens
      }}
      >
        <Swipe
          goBack={this.goBack}
          goForward={this.goForward}
        >
          {this.getCurrentModule()}
        </Swipe>
      </AppContext.Provider>
    );
  }
}

export default ModuleManager;
