import React, { Component } from 'react';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { validate as validEmail } from 'email-validator';
import Translate from 'components/translate/Translate';
import SimpleInput from 'components/inputs/SimpleInput';
import PhoneInput from 'components/inputs/PhoneInput';
import Header from 'components/header/Header';
import enCountries from 'resources/lang/en.countries.json';
import deCountries from 'resources/lang/de.countries.json';
import countryCodes from 'resources/data/countryCodes';
import { IMtan } from 'types/index.d'; // eslint-disable-line no-unused-vars, @typescript-eslint/no-unused-vars

interface IProps {
  currentLang: string;
  onDone: (data: any) => void;
  data: IMtan;
}

interface IState extends IMtan {
  touched: {
    email: boolean;
    phone: boolean;
  };
  showError: {
    email: boolean;
    phone: boolean;
  };
  canGo: boolean;
}

export default class InformationForMtan extends Component<IProps, IState> {
  startWithEmail: boolean;

  startWithPhone: boolean;

  constructor(props: IProps) {
    super(props);
    const { data } = props;
    const {
      email,
      phone,
      countryCode,
    } = data;
    this.startWithEmail = !!email;
    this.startWithPhone = !!phone;
    this.state = {
      email,
      phone,
      countryCode,
      touched: {
        email: false,
        phone: false,
      },
      showError: {
        email: false,
        phone: false,
      },
      canGo: !!email && !!phone,
    };
  }

  changeState = (state: Partial<IState>) => {
    const {
      startWithEmail,
      startWithPhone,
    } = this;
    if (startWithEmail && startWithPhone) return;
    this.setState((prevState: IState) => {
      const {
        touched,
      } = prevState;

      const newState: any = {
        ...prevState,
        touched: {
          ...touched,
        },
        ...state,
      };
      const emailIsValid = this.isValidEmail(newState.email);
      const phoneIsValid = this.isValidPhone(newState.phone);

      if (state.email) {
        newState.touched.email = true;
      }
      if (state.phone) {
        newState.touched.phone = true;
      }

      newState.canGo = emailIsValid && phoneIsValid;
      return newState;
    });
  };

  continue = () => {
    const { onDone } = this.props;
    const { email, phone, countryCode } = this.state;
    onDone({ email, phone, countryCode });
  };

  fieldBlur = (name: 'email' | 'phone') => {
    let fieldIsValid: (key: string) => boolean;
    switch (name) {
      case 'email':
        fieldIsValid = this.isValidEmail;
        break;
      case 'phone':
        fieldIsValid = this.isValidPhone;
        break;
      default:
        return;
    }

    this.setState((state) => {
      const newState = {
        ...state,
        showError: {
          ...state.showError,
          [name]: state.touched[name] && !fieldIsValid(state[name]),
        },
      };
      return newState;
    });
  };

  isValidEmail = (email: string) => {
    const {
      startWithEmail,
    } = this;
    return startWithEmail || validEmail(email || '');
  };

  isValidPhone = (phone: string) => {
    const {
      startWithPhone,
    } = this;
    return startWithPhone || isPossiblePhoneNumber(phone || '');
  };

  render() {
    const { currentLang } = this.props;
    const {
      startWithEmail,
      startWithPhone,
    } = this;
    const {
      email,
      phone,
      showError,
      canGo,
    } = this.state;

    let labels: any;
    switch (currentLang) {
      case 'de':
        labels = deCountries;
        break;
      case 'en':
      default:
        labels = enCountries;
        break;
    }
    return (
      <div className="information-for-mtan-page">
        <Header />
        <div className="card">
          <div className="card-body">
            <h1><Translate i18nKey="information-for-mtan.congrats" /></h1>
            <section className="information-for-mtan">
              <div className="section-container information-for-mtan-container">
                {
                  !startWithEmail
                    ? (
                      <SimpleInput
                        label="information-for-mtan.email"
                        name="email"
                        type="text"
                        value={email}
                        changeState={this.changeState}
                        onBlur={() => this.fieldBlur('email')}
                        error={showError.email}
                        errorText="information-for-mtan.email-error"
                      />
                    ) : null
                }
                {!startWithPhone
                  ? (
                    <PhoneInput
                      mainProps={{
                        label: 'information-for-mtan.phone-number',
                        changeState: this.changeState,
                        error: showError.phone,
                        errorText: 'information-for-mtan.phone-error',
                        name: 'phone',
                      }}
                      inputProps={{
                        value: phone,
                        onBlur: () => this.fieldBlur('phone'),
                        international: true,
                        addInternationalOption: false,
                        countries: countryCodes,
                        countryOptionsOrder: ['CH'],
                        labels,
                        onCountryChange: (countryCode: string) => {
                          this.changeState({ countryCode });
                        }
                      }}
                    />
                  ) : null}
              </div>
            </section>
          </div>
          <div className="card-footer">
            <div className="continue-button">
              <button
                className={`button-big overlay-button ${!canGo ? 'inactive' : ''}`}
                type="button"
                disabled={!canGo}
                onClick={this.continue}
              >
                <Translate i18nKey="information-for-mtan.continue" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
