import React, { Component } from 'react';
import platform from 'platform';
import Translate from 'components/translate/Translate';
import Frame from 'components/frame/Frame';
import SuccessPictogram from 'components/successPictogram/SuccessPictogram';
import Loader from 'components/loader/Loader';
import { ReactComponent as RotatePic } from 'assets/images/turn-to-portarit.svg';
import { ReactComponent as FaceFrame } from 'assets/images/frame-face-1.svg';
import { ReactComponent as UserPic } from 'assets/images/frame-face.svg';
import { ReactComponent as WaitingFrame } from 'assets/images/waiting-frame.svg';
import {
  VideoStreamStatus,
  VibrationTunes,
} from 'resources/enums';
import { fetch, handleFetchErrors } from 'utils';
import gaPageView from 'utils/GA';
import { error } from 'utils/log';
import { getFlow, vibrate } from 'utils/flowController';
import { DoneStatus, EActionTypes } from 'types/enum';

/* eslint-disable no-unused-vars */
import { IData, IMotionData, UserAgentData } from 'types/index.d';

enum Statuses {
  wait = 'wait',
  rotate = 'rotate',
  instruction = 'instruction',
  preparation = 'preparation',
  recording = 'recording',
  processing = 'processing',
  success = 'success',
  dataSending = 'dataSending',
}
/* eslint-enable no-unused-vars */

const LFV_TIMEOUT = 60 * 1000;

const PREPARATION_TIMEOUT = 3000;

type Props = {
  onDone: (status: DoneStatus) => void;
  addDVSLog: (data: string) => void;
  changeData: (data: Partial<IData>) => void;
  appState: any;
  videoStream?: any,
};

type State = {
  status: Statuses,
};

export default class LFVContent extends Component<Props, State> {
  private lastResponse: any = {};

  private lfvTimeout: any;

  private timeouts: number[] = [];

  private motionData: Partial<IMotionData>[] = [];

  constructor(props: any) {
    super(props);
    this.state = {
      status: Statuses.wait,
    };
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State): any {
    const { appState, videoStream } = nextProps;
    const { isOnline } = appState;
    const { status } = prevState;
    if (videoStream.isLandscapeOrientation
      && [Statuses.instruction, Statuses.preparation, Statuses.recording].includes(status)) {
      return {
        status: Statuses.rotate,
      };
    }

    const wasInterrupted: boolean = (
      !isOnline || videoStream.status === VideoStreamStatus.stop
    ) && [Statuses.preparation, Statuses.recording].includes(status);
    const correctOrientation: boolean = !videoStream.isLandscapeOrientation
      && status === Statuses.rotate;
    if (wasInterrupted || correctOrientation) {
      return {
        status: Statuses.instruction,
      };
    }

    return null;
  }

  async componentDidMount() {
    const { appState } = this.props;
    const { collectMotionData } = appState.config;
    if (collectMotionData) {
      if (DeviceMotionEvent && typeof (DeviceMotionEvent as any).requestPermission === 'function') {
        (DeviceMotionEvent as any).requestPermission();
      }
      window.addEventListener('devicemotion', this.handleDeviceMotion);
    }

    gaPageView('/lfv');
    try {
      const { videoStream } = this.props;
      await videoStream.ensureVideoStream();
      this.setState({ status: Statuses.instruction });
    } catch (err) {
      const { onDone } = this.props;
      const { message, stack } = (err || {}) as any;
      error(`Error when initializing LFV: message: ${message} stack: ${stack}`);
      onDone(DoneStatus.fail);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { status } = this.state;
    const { status: prevStatus } = prevState;
    const { appState: { loading } } = this.props;
    const { appState: { loading: prevLoading } } = prevProps;

    if ([Statuses.rotate, Statuses.instruction].includes(status)
      && [Statuses.preparation, Statuses.recording].includes(prevStatus)) {
      this.stopLFVRrecording();
    }

    if (loading && prevLoading !== loading) {
      this.setState({ status: Statuses.dataSending });
    }
  }

  componentWillUnmount(): void {
    for (let i = 0; i < this.timeouts.length; i++) {
      clearTimeout(this.timeouts[i]);
    }
    window.removeEventListener('devicemotion', this.handleDeviceMotion);
  }

  handleDeviceMotion = (event: DeviceMotionEvent) => {
    const {
      acceleration,
      accelerationIncludingGravity,
      rotationRate,
      timeStamp,
      interval,
    } = event;

    this.motionData.push({
      accX: acceleration?.x,
      accY: acceleration?.y,
      accZ: acceleration?.z,
      accGravX: accelerationIncludingGravity?.x,
      accGravY: accelerationIncludingGravity?.y,
      accGravZ: accelerationIncludingGravity?.z,
      gyroAlpha: rotationRate?.alpha,
      gyroBeta: rotationRate?.beta,
      gyroGamma: rotationRate?.gamma,
      timeRelativeMilli: timeStamp,
      interval: interval,
    });
  };

  updateAppData = (fail = false) => {
    const { appState, changeData } = this.props;
    const { resultXML } = this.lastResponse;
    const { verificationData } = appState;
    const { errorCode, sideCount } = verificationData;
    // TODO: Probably here need to separate LFV and IdScan results
    changeData({ verification: { errorCode, resultXML, sideCount, fail } });
  };

  startLFVTimeout = () => {
    this.lfvTimeout = setTimeout(() => {
      const { status } = this.state;
      const { onDone } = this.props;
      if (status === Statuses.recording) {
        const { videoStream } = this.props;
        videoStream.stopVideoRecording();
      }
      if (status === Statuses.processing) {
        window.stop();
      }
      error(`LFV timeouted after ${LFV_TIMEOUT / 1000} seconds.`);
      this.sendErrorProcessingTimeout();
      this.updateAppData(true);
      onDone(DoneStatus.exit);
    }, LFV_TIMEOUT);
    this.timeouts.push(this.lfvTimeout);
  };

  iosWithVersionNotSupportedMediaRecorder = () => {
    const iosMinSupportVersion = 14.6;
    if ((navigator as any).userAgentData) {
      return (navigator as any).userAgentData.getHighEntropyValues([
        'platform',
        'platformVersion',
      ])
        .then((ua: UserAgentData) => {
          return ua.platform.toLowerCase() === 'ios' && (parseFloat(ua.platformVersion) < iosMinSupportVersion);
        });
    }
    const platformInfo = platform.parse(navigator.userAgent);
    if (!platformInfo) return false;
    const { os = {} } = platformInfo;
    const { family = '' } = os;
    // Apple started to support webrtc MediaRecorder from 14.6 version of iOS
    return family.toLowerCase() === 'ios' && (parseFloat(platformInfo.os.version) < iosMinSupportVersion);
  };

  lfvWithImages = (): Promise<string> => (
    new Promise((resolve, reject) => {
      const { appState, videoStream } = this.props;
      videoStream.collectImagesForVideo(50)
        .then((images: string[]) => {
          const result: any = {
            images,
          };
          const { collectMotionData } = appState.config;
          if (collectMotionData) {
            if (this.motionData.length) {
              const lastActionIndex = this.motionData.length - 1;
              this.motionData[lastActionIndex].action = EActionTypes.lfv;
            } else {
              this.motionData.push({ action: EActionTypes.lfv });
            }
            result.motionData = this.motionData;
            this.motionData = [];
          }
          resolve(JSON.stringify(result));
        })
        .catch(reject);
    })
  );

  lfvWithVideo = (): Promise<any> => {
    const { videoStream, appState } = this.props;
    return videoStream.recordVideo((5 * 1000))
      .then((capturedVideo: any) => {
        if (!capturedVideo) return undefined;

        const fd = new FormData();
        fd.append('video', capturedVideo, 'video');
        const { collectMotionData } = appState.config;
        if (collectMotionData) {
          if (this.motionData.length) {
            const lastActionIndex = this.motionData.length - 1;
            this.motionData[lastActionIndex].action = EActionTypes.lfv;
          } else {
            this.motionData.push({ action: EActionTypes.lfv });
          }
          fd.append('motionData', JSON.stringify(this.motionData));
          this.motionData = [];
        }
        return fd;
      })
      .catch((err: any) => {
        throw err;
      });
  };

  lfvSendData = (lfvData: any): void => {
    const { addDVSLog, videoStream, onDone } = this.props;
    videoStream.updateAppearance({ poweredByLogo: { toggleTextColor: true } });
    this.setState({ status: Statuses.processing });
    fetch('/lfv', {
      method: 'POST',
      body: lfvData,
    })
      .then((res: any) => res.json())
      .then((resJson: any) => {
        this.lastResponse = resJson;
        // enforce type, because SDK sends string
        let { errorCode } = resJson;
        errorCode = Number(errorCode);

        const flow = getFlow('selfie', {
          errorCode: resJson.errorCode,
        });
        addDVSLog(`Liveness and Face verification finished, errorCode: ${errorCode}, flow: ${flow}.`);

        /* Flow lfv -> success */
        if (flow === 'success') {
          vibrate(VibrationTunes.success);
          this.updateAppData();
          this.setState({ status: Statuses.success });
          window.setTimeout(() => {
            onDone(null);
          }, 1000);
        } else {
          error(`Flow error: no match flow: ${flow}`);
          this.updateAppData(true);
          onDone(DoneStatus.exit);
        }
      })
      .catch((err: any) => {
        const { message, stack } = err;
        error(`Fetch /lfv error: message: ${message} stack: ${stack}`);
        addDVSLog('Internal Server Error during Liveness and Face verification.');
        this.updateAppData(true);
        onDone(DoneStatus.exit);
      })
      .finally(() => clearTimeout(this.lfvTimeout));
  };

  startLFV = async () => {
    this.setState({ status: Statuses.recording });

    this.startLFVTimeout();

    const { addDVSLog, onDone } = this.props;
    addDVSLog('Liveness and Face verification started');

    let lfvDataPromise: Promise<any>;
    if (!(window as any).MediaRecorder || await this.iosWithVersionNotSupportedMediaRecorder()) {
      lfvDataPromise = this.lfvWithImages();
    } else {
      lfvDataPromise = this.lfvWithVideo();
    }

    lfvDataPromise
      .then((lfvData) => {
        if (!lfvData) return;
        this.lfvSendData(lfvData);
      })
      .catch(() => {
        this.updateAppData(true);
        onDone(DoneStatus.exit);
      });
  };

  stopLFVRrecording = () => {
    const { videoStream } = this.props;
    clearTimeout(this.lfvTimeout);
    videoStream.stopVideoRecording();
  };

  // TODO: need to be revised.
  sendErrorProcessingTimeout(): void {
    fetch('/processing_timeout', {
      method: 'POST',
    })
      .then(handleFetchErrors)
      .catch(() => error('Error while sending processing timeout error.'));
  }

  instructionOnClickHandler = () => {
    this.setState({ status: Statuses.preparation });
    const { appState } = this.props;
    const { collectMotionData } = appState.config;
    if (collectMotionData) {
      if (this.motionData.length) {
        const lastActionIndex = this.motionData.length - 1;
        this.motionData[lastActionIndex].action = EActionTypes.instructionButtonClick;
      } else {
        this.motionData.push({ action: EActionTypes.instructionButtonClick });
      }
    }
    setTimeout(async () => {
      await this.startLFV();
    }, PREPARATION_TIMEOUT);
  };

  render() {
    const { status } = this.state;
    let content: any = '';
    switch (status) {
      case Statuses.rotate:
        content = (
          <Frame>
            <div className="inner-frame">
              <div>
                <div><RotatePic className="pic rotate-pic" /></div>
                <div className="text"><Translate i18nKey="dv.rotate-pic" /></div>
              </div>
            </div>
          </Frame>
        );
        break;
      case Statuses.instruction:
        content = (
          <div className="comp-selfie-frame">
            <div className="frame">
              <FaceFrame className="selfie-overlay" />
              <div className="selfie-instruction">
                <UserPic className="face-frame" />
                <div className="selfie-instruction-message">
                  <div className="instruction-text"><Translate i18nKey="dv.selfie.instruction-message" /></div>
                  <div
                    className="button button-big overlay-button"
                    role="button"
                    onClick={this.instructionOnClickHandler}
                    onKeyPress={this.instructionOnClickHandler}
                    tabIndex={-1}
                  >
                    <Translate i18nKey="dv.btn.ok" />
                  </div>
                </div>
              </div>
              <FaceFrame className="selfie-frame" />
            </div>
          </div>
        );
        break;
      case Statuses.preparation:
        content = (
          <div className="comp-selfie-frame">
            <div className="frame">
              <div className="text">
                <div><Translate i18nKey="dv.selfie.text" /></div>
              </div>
              <FaceFrame className="selfie-frame" />
            </div>
          </div>
        );
        break;
      case Statuses.recording:
        content = (
          <div className="comp-selfie-frame">
            <div className="frame">
              <div className="text">
                <div><Translate i18nKey="dv.selfie.text" /></div>
              </div>
              <FaceFrame className="selfie-frame animatable" />
            </div>
          </div>
        );
        break;
      case Statuses.processing:
        content = (
          <div className="comp-selfie-frame selfie-frame-overlay">
            <div className="frame">
              <div>
                <div className="waiting-frame-section">
                  <WaitingFrame className="waiting-frame" />
                  <div className="waiting-text"><Translate i18nKey="dv.selfie.waiting-text" /></div>
                </div>
                <div className="processing-text">
                  <div><Translate i18nKey="dv.selfie.processing-text" /></div>
                </div>
              </div>
              <FaceFrame className="selfie-frame processing-frame" />
            </div>
          </div>
        );
        break;
      case Statuses.success:
        content = (
          <SuccessPictogram classNames="success-pictogram-overlay" />
        );
        break;
      case Statuses.dataSending:
        content = (
          <div className="comp-selfie-frame selfie-frame-overlay">
            <div className="loading-frame-section">
              <div className="waiting-text"><Translate i18nKey="dv.selfie.data-sending-text" /></div>
              <Loader />
            </div>
          </div>
        );
        break;
      default:
        break;
    }

    return content;
  }
}
