import React, { Component } from 'react';
import { ReactComponent as IdBack } from 'assets/images/id-mrz.svg';
import { ReactComponent as IdFront } from 'assets/images/id-viz.svg';
import { ReactComponent as PassportData } from 'assets/images/passport-data.svg';
import { ReactComponent as PaperPermit1 } from 'assets/images/swiss-paper-permit-page-1.svg';
import { ReactComponent as PaperPermit2 } from 'assets/images/swiss-paper-permit-page-2.svg';
import { DocumentTypes, IdDocumentSide } from 'resources/enums';

type Props = {
  documentType: DocumentTypes,
  side: IdDocumentSide,
};

type State = Record<string, never>;

export default class IdDocumentOverlay extends Component<Props, State> {
  render(): React.ReactNode {
    const { documentType, side } = this.props;
    let content;
    switch (`${documentType}-${side}`) {
      case `${DocumentTypes.id}-${IdDocumentSide.side1}`:
        content = <IdFront className="id-pic" preserveAspectRatio="none" />;
        break;
      case `${DocumentTypes.id}-${IdDocumentSide.side2}`:
        content = <IdBack className="id-pic" preserveAspectRatio="none" />;
        break;
      case `${DocumentTypes.passport}-${IdDocumentSide.side1}`:
        content = <PassportData className="passport-pic" preserveAspectRatio="none" />;
        break;
      case `${DocumentTypes.paperPermit}-${IdDocumentSide.side1}`:
        content = <PaperPermit1 className="paper-pic" preserveAspectRatio="none" />;
        break;
      case `${DocumentTypes.paperPermit}-${IdDocumentSide.side2}`:
        content = <PaperPermit2 className="paper-pic" preserveAspectRatio="none" />;
        break;
      default:
        break;
    }

    return (
      <div className="comp-doc-overlay">
        {content}
      </div>
    );
  }
}
