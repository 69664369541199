/* eslint-disable no-unused-vars */
enum Views {
  start = 'START',
  terms = 'TERMS',
  selectType = 'SELECTTYPE',
  end = 'END',
  failed = 'FAILED',
  _404 = '404',
  data = 'DATA',
  transactioncode = 'TRANSACTIONCODE',
  sharedata = 'SHAREDATA',
  shareDataError = 'SHAREDATAERROR',
  thankyou = 'THANKYOU',
  feedback = 'FEEDBACK',
  compatibilitynotes = 'COMPATIBILITYNOTES',
  privacyPolicy = 'PRIVACYPOLICY',
  termsAndConditions = 'TERMSANDCONDITIONS',
  somethingWentWrong = 'SOMETHINGWENTWRONG',
  qrCode = 'QRCODE',
  deviceNotSupported = 'DEVICENOTSUPPORTED',
  desktopNotSupported = 'DESKTOPNOTSUPPORTED',
  invalidTC = 'INVALIDTC',
  endWarning = 'ENDWARNING',
  id = 'ID',
  passport = 'PASSPORT',
  paperPermit = 'PAPERPERMIT',
  deviceTimeIsWrong = 'DEVICETIMEISWRONG',
  environmentTest = 'ENVIRONMENTTEST',
  personalDetails = 'PERSONALDETAILS',
  informationForMtan = 'INFORMATIONFORMTAN',
  newVersionIsAvailable = 'NEWVERSIONISAVAILABLE',
}

enum Modules {
  transactioncode = 'TRANSACTIONCODE',
  start = 'START',
  terms = 'TERMS',
  envTest = 'ENVIRONMENTTEST',
  mtan = 'MTAN',
  verifyCode = 'VERIFYCODE',
  selfDeclaration = 'SELFDECLARATION',
  dv = 'DV',
  lfv = 'LFV',
  additionalDoc = 'ADDITIONALDOC',
  utilityBill = 'UTILITYBILL',
  verificationEnd = 'VERIFICATIONEND',
  contract = 'CONTRACT',
  thankYou = 'THANKYOU',
  // Probably need to be grouped as single module, non-configurable
  notFound = '404',
  deviceNotSupported = 'DEVICENOTSUPPORTED',
  desktopNotSupported = 'DESKTOPNOTSUPPORTED',
  deviceTimeIsWrong = 'DEVICETIMEISWRONG',
  somethingWentWrong = 'SOMETHINGWENTWRONG',
  newVersionIsAvailable = 'NEWVERSIONISAVAILABLE',
}

enum Orientation {
  landscapePrimary = 'landscape-primary',
  landscapeSecondary = 'landscape-secondary',
  portraitPrimary = 'portrait-primary',
  portraitSecondary = 'portrait-secondary',
}

enum Roles {
  placeholder = 'placeholder',
  firstPage = 'firstPage',
}

enum WhatToScan {
  side1,
  side2,
  selfie,
  additionalDoc,
  docBackSide,
}

enum DocumentTypes {
  id = 'id',
  passport = 'passport',
  paperPermit = 'paperPermit',
}

enum LfvStatus {
  waiting = 'waiting',
  preparation = 'preparation',
  recording = 'recording',
  processing = 'processing',
  success = 'success',
  fail = 'fail',
}

enum VibrationTunes {
  success = 'success',
  fail = 'fail',
}

enum SelfNavigationActions {
  doNothing = 'doNothing',
  goToFirstPage = 'goToFirstPage',
  pushNewHistory = 'pushNewHistory',
}

enum CameraPermissions {
  granted = 'granted',
  denied = 'denied',
  prompt = 'prompt',
}

enum QRScannerStatus {
  scan = 'scan',
  success = 'success',
  fail = 'fail',
  timeOuted = 'timeouted',
}

enum DoneStatus {
  fail = 'fail',
  exit = 'exit',
  retry = 'retry',
  wrongTime = 'wrongTime',
}

enum SignStatus {
  notRequested = 'notRequested',
  pending = 'pending',
  signed = 'signed',
  rejected = 'rejected',
}

enum EActionTypes {
  instructionButtonClick = 'instructionButtonClick',
  manualCapture = 'manualCapture',
  recapture = 'recapture',
  lfv = 'lfv',
}

enum IPhoneModel {
  Other = 'Other',  //Other IPhone
  ProMax14 = 'ProMax14',
  Pro14 = 'Pro14',
  ProMax12ProMax13Plus14 = 'ProMax12/ProMax13/Plus14',
  IPhone12IPhone13IPhone14Pro12Pro13 = 'IPhone12/IPhone13/IPhone14/Pro12/Pro13',
  ProMax11XSMax = 'ProMax11/XSMax',
  Pro11XSMini12Mini13IPhoneX = 'Pro11/XS/Mini12/Mini13',
  IPhone11XR = 'IPhone11/XR',
  IPhoneX = 'IPhoneX',
  IPhone8SE = 'IPhone8/SE',
  Plus8 = 'Plus8',
}
/* eslint-enable no-unused-vars */

export {
  Views,
  Orientation,
  Roles,
  WhatToScan,
  DocumentTypes,
  LfvStatus,
  QRScannerStatus,
  VibrationTunes,
  SelfNavigationActions,
  CameraPermissions,
  Modules,
  DoneStatus,
  SignStatus,
  EActionTypes,
  IPhoneModel,
};
