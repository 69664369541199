import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import Header from 'components/header/Header';
import Loader from 'components/loader/Loader';
/* eslint-disable no-unused-vars */
import { DoneStatus } from 'types/enum';
import { IData } from 'types/index.d';
/* eslint-enabled no-unused-vars */

type Props = {
  onDone: (status: DoneStatus) => void;
  changeData: (data: Partial<IData>) => void;
  shareData: () => void;
  isSecondTry: boolean;
  customer: any,
  exitUrl: string,
};

type State = {
  loading: boolean,
};

export default class EndFailed extends Component<Props, State> {
  private continueButtonRef = React.createRef<HTMLDivElement>();

  private retryButtonRef = React.createRef<HTMLDivElement>();

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  async componentDidMount() {
    const { isSecondTry } = this.props;
    if (isSecondTry) {
      try {
        const { shareData } = this.props;
        this.setState({ loading: true });
        await shareData();
      } finally {
        this.setState({ loading: false });
      }
    }
  }

  async continue() {
    const { loading } = this.state;
    if (loading) return;
    this.leaveApp();
  }

  retry() {
    const { onDone, changeData } = this.props;
    changeData({
      isSecondTry: true,
      docBackSide: '',
      additionalDoc: '',
    });
    onDone(DoneStatus.retry);
  }

  leaveApp = (): void => {
    const { customer, exitUrl } = this.props;
    const {
      forward_blockingerror_url: forwardErrorUrl,
    } = customer;
    const url = forwardErrorUrl || exitUrl;

    window.open(url, '_self');
  };

  render() {
    const { loading } = this.state;
    const { isSecondTry } = this.props;

    const inactive: string = (loading) ? 'inactive' : 'active';

    const button = isSecondTry ? (
      <div
        id="continue"
        className={`button-big ${inactive}`}
        ref={this.continueButtonRef}
        role="button"
        onClick={() => this.continue()}
        onKeyPress={() => this.continue()}
        tabIndex={-1}
      >
        <span><Translate i18nKey="failed.btn.continue" /></span>
        {loading && <Loader />}
      </div>
    ) : (
      <div
        id="retry"
        className={`button-big next-button ${inactive}`}
        ref={this.retryButtonRef}
        role="button"
        onClick={() => this.retry()}
        onKeyPress={() => this.retry()}
        tabIndex={-1}
      >
        <Translate i18nKey="failed.btn.retry" />
      </div>
    );
    const message = isSecondTry ? (
      <Translate i18nKey="failed.second-message" />
    ) : (
        <Translate i18nKey="failed.message" />
    );
    const title = isSecondTry ? (
      <Translate i18nKey="failed.second-title" />
    ) : (
        <Translate i18nKey="failed.sorry" />
    );

    return (
      <div className="comp-end-failed">
        <Header loading={loading} />
        <div className="card">

          <div className="contents">
            <div className="congrats">{title}</div>
            <div className="message">
              <div>
                {message}
              </div>
            </div>
            <div className="button-with-loader">
              {button}
            </div>
          </div>

        </div>
      </div>
    );
  }
}
