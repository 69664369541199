import React, { Component } from 'react';
import Header from 'components/header/Header';
import VideoStream from 'components/videoStream/VideoStream';
import UtilityBill from 'modules/utilityBill/utilityBill/UtilityBill';
import { DoneStatus, Orientation } from 'types/enum';

interface IProps {
  onDone: (status: DoneStatus) => void;
  addDVSLog: (data: string) => void;
  appState: any;
}

export default class UtilityBillContent extends Component<IProps> {

  isLandscapeOrientation = (): boolean => {
    const { appState } = this.props;
    const { orientation } = appState;
    return orientation === Orientation.landscapePrimary
      || orientation === Orientation.landscapeSecondary;
  };

  render() {
    const {
      onDone,
      appState,
      addDVSLog,
    } = this.props;

    return (
      <div className="comp-dv utility-billy">
        <Header />
        <VideoStream appState={appState}>
          <UtilityBill
            onDone={onDone}
            addDVSLog={addDVSLog}
            isLandscape={this.isLandscapeOrientation()}
          />
        </VideoStream>
      </div>
    );
  }
}
