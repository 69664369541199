import React, { Component } from 'react';
import Translate from 'components/translate/Translate';

type Props = {
  supportEmail: any;
};

export default class CompatibilityNotes extends Component<Props> {
  render() {
    const { supportEmail } = this.props;
    return (
      <div className="comp-compatibility-notes">
        <div className="card">
          <div className="heading"><Translate i18nKey="compatibility-notes.title" /></div>

          <div className="text-caption">
            <div>
              <Translate i18nKey="compatibility-notes.text1" />
            </div>
            <div>
              <Translate i18nKey="compatibility-notes.text2" />
            </div>
            <div>
              <Translate i18nKey="compatibility-notes.text3" />
              {' '}
              <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
