import {
  DocumentTypes,
  IdDocumentSide, // eslint-disable-line no-unused-vars, @typescript-eslint/no-unused-vars
  VerificationFlow,
  VibrationTunes,
} from 'resources/enums';
import {
  AVAILABLE_ERROR_CODES,
  ERROR_CODES_ALWAYS_BLOCKING,
  SECOND_PAGE_ERROR_CODES,
  SECOND_PAGE_ERROR_CODES_FOR_SDK3,
  SUCCESS_ERROR_CODES,
} from 'resources/constants/errorCodes';

interface IFlowControllerArg {
  errorCode: number,
  faceImage?: boolean,
  firstImage?: boolean,
  secondImage?: boolean,
  resultXML?: boolean,
  mrzOrBarcode?: boolean,
  documentType?: string,
  resJson?: any,
  templateMathcingFailed?: boolean,
  isSDK3?: boolean,
}

const vibrationPatterns: { [key in keyof (typeof VibrationTunes)]: number[] } = {
  fail: [200, 100, 200, 100, 300],
  success: [200, 100, 300],
};

// Private
const side1ToLfvConditions = (arg: IFlowControllerArg) => {
  const {
    errorCode,
    firstImage,
    faceImage,
    resultXML,
  } = arg;

  const errorCodeCondition: boolean = SUCCESS_ERROR_CODES.includes(Number(errorCode));
  const filesCondition: boolean = firstImage && resultXML && faceImage;

  return (errorCodeCondition && filesCondition);
};

const side1ToSide2ConditionForSDK3 = (arg: IFlowControllerArg) => {
  const {
    errorCode,
    firstImage,
    resultXML,
  } = arg;

  const errorCodeCondition: boolean = SECOND_PAGE_ERROR_CODES_FOR_SDK3.includes(Number(errorCode));
  const filesCondition: boolean = (firstImage && resultXML);

  return { errorCodeCondition, filesCondition };
};

const side1ToSide2ConditionForSDK2 = (arg: IFlowControllerArg) => {
  const {
    errorCode,
    firstImage,
    faceImage,
    templateMathcingFailed,
    resultXML,
  } = arg;

  const errorCodeCondition: boolean = SECOND_PAGE_ERROR_CODES.includes(Number(errorCode));
  const filesCondition: boolean = ((firstImage
    || (templateMathcingFailed && faceImage)) && resultXML);

  return { errorCodeCondition, filesCondition };
};

const side1ToSide2Conditions = (arg: IFlowControllerArg) => {
  const {
    documentType,
    isSDK3,
  } = arg;

  const { errorCodeCondition, filesCondition } = isSDK3
    ? side1ToSide2ConditionForSDK3(arg) : side1ToSide2ConditionForSDK2(arg);

  return documentType !== DocumentTypes.passport
    && errorCodeCondition && filesCondition;
};

const side1RetryConditions = (arg: IFlowControllerArg) => {
  const { errorCode } = arg;

  const successErrorCode: boolean = SUCCESS_ERROR_CODES.includes(Number(errorCode));
  const noneOfDefinedErrroCodes = !AVAILABLE_ERROR_CODES.includes(Number(errorCode));

  return successErrorCode || noneOfDefinedErrroCodes;
};

const side2ToLfvConditions = (arg: IFlowControllerArg) => {
  const {
    errorCode,
    documentType,
    secondImage,
    faceImage,
    resultXML,
    mrzOrBarcode,
  } = arg;

  const errorCodeCondition: boolean = SUCCESS_ERROR_CODES.includes(Number(errorCode));
  const filesCondition: boolean = faceImage && secondImage && resultXML
    && ((documentType !== DocumentTypes.paperPermit && mrzOrBarcode)
      || documentType === DocumentTypes.paperPermit);

  return (errorCodeCondition && filesCondition);
};

const side2RetryConditions = (arg: IFlowControllerArg) => {
  const { errorCode } = arg;

  const successErrorCode: boolean = SUCCESS_ERROR_CODES.includes(Number(errorCode));
  const noneOfDefinedErrroCodes = !AVAILABLE_ERROR_CODES.includes(Number(errorCode));

  return successErrorCode || noneOfDefinedErrroCodes;
};

const lfvToSuccess = (arg: IFlowControllerArg) => {
  const { errorCode } = arg;

  const errorCodeCondition: boolean = SUCCESS_ERROR_CODES.includes(Number(errorCode));

  return errorCodeCondition;
};

/**
   * *** Flow Rules ***
   *
   * Rules definition:
   * {
   *  currentStage {
   *    nextStageRule: {
   *      errorCodes: number[],
   *      condition: bool fn(arg: IFlowControllerArg)
   *    },
   *    ...
   *  }
   * }
   *
   * Test sequence is the same as propery order.
   * Every next rules is tested before the previous.
   *
   * The flow is as follows:
   *
   * ______Side 1_____  -->  [retry until flowRules.side1.side2 or flowRules.side1.lfv are matched]
   *    |       |
   *    |       V
   *    |   __Side 2__   -->  [retry until flowRules.side2.lfv is matched]
   *    |       |
   *    V       V
   * _______LVF_______  -->  [retry until flowRules.lfv.success is matched]
   *         |
   *         V
   *    __Success__
   *
   */
// See https://pxlvision.atlassian.net/browse/DGW-522 for more details
const flowRules: any = {
  side1: {
    [VerificationFlow.lfv]: {
      condition: side1ToLfvConditions,
    },
    [VerificationFlow.side2]: {
      condition: side1ToSide2Conditions,
    },
    [VerificationFlow.retry]: {
      condition: side1RetryConditions,
    },
  },
  side2: {
    [VerificationFlow.lfv]: {
      condition: side2ToLfvConditions,
    },
    [VerificationFlow.retry]: {
      condition: side2RetryConditions,
    },
  },
  selfie: {
    success: {
      condition: lfvToSuccess,
    },
  },
};

// Public
const getBlockingErrorCodes = (configErrorCodes: number[]) => (
  [...configErrorCodes, ...ERROR_CODES_ALWAYS_BLOCKING]
);

const getNonBlockingErrorCodes = (configErrorCodes: number[]) => {
  const errorCodesToExclude: number[] = [
    ...SUCCESS_ERROR_CODES,
    ...SECOND_PAGE_ERROR_CODES,
    ...getBlockingErrorCodes(configErrorCodes),
  ];
  return AVAILABLE_ERROR_CODES
    .filter((errorCode) => !errorCodesToExclude.includes(errorCode));
};

/**
   * @returns {String} - one of the flowRules keys, at the moment they are:
   *  'retry', 'lfv', 'side2', 'success';
   */
const getFlow = (side: IdDocumentSide | 'selfie', arg: IFlowControllerArg): string => {
  const rule: any = flowRules[side];
  const keys = Object.keys(rule);
  let key;
  for (let i = 0; i < keys.length; i += 1) {
    key = keys[i];
    const { condition } = rule[key];
    if (condition(arg)) {
      return key;
    }
  }
  return VerificationFlow.retry;
};

const vibrate = (tune: VibrationTunes): void => {
  if (!navigator.vibrate) return;
  navigator.vibrate(vibrationPatterns[tune]);
};

export {
  getFlow,
  getBlockingErrorCodes,
  getNonBlockingErrorCodes,
  vibrate,
};
