/* eslint-disable no-unused-vars */
enum Views {
  start = 'START'
  , terms = 'TERMS'
  , verifyCode = 'VERIFYCODE'
  , environmentTest = 'ENVIRONMENTTEST'
  , personalDetails = 'PERSONALDETAILS'
  , selectType = 'SELECTTYPE'
  , end = 'END'
  , endWarning = 'ENDWARNING'
  , failed = 'FAILED'
  , data = 'DATA'
  , sharedata = 'SHAREDATA'
  , shareDataError = 'SHAREDATAERROR'
  , transactioncode = 'TRANSACTIONCODE'
  , thankyou = 'THANKYOU'
  , feedback = 'FEEDBACK'
  , compatibilitynotes = 'COMPATIBILITYNOTES'
  , privacyPolicy = 'PRIVACYPOLICY'
  , termsAndConditions = 'TERMSANDCONDITIONS'
  , somethingWentWrong = 'SOMETHINGWENTWRONG'
  , qrCode = 'QRCODE'
  , deviceNotSupported = 'DEVICENOTSUPPORTED'
  , desktopNotSupported = 'DESKTOPNOTSUPPORTED'
  , invalidTC = 'INVALIDTC'
  , id = 'ID'
  , passport = 'PASSPORT'
  , paperPermit = 'PAPERPERMIT'
  , docBackSide = 'DOCBACKSIDE'
  , additionalDoc = 'ADDITIONALDOC'
  , lfv = 'LFV'
  , deviceTimeIsWrong = 'DEVICETIMEISWRONG'
  , informationForMtan = 'INFORMATIONFORMTAN'
}

enum IdDocumentSide {
  side1 = 'side1',
  side2 = 'side2',
}

enum DocumentTypes {
  id = 'id',
  passport = 'passport',
  paperPermit = 'paperPermit'
}

enum VibrationTunes {
  success = 'success',
  fail = 'fail'
}

enum FacingModes {
  environment = 'environment',
  user = 'user'
}

enum VideoStreamStatus {
  init = 'init',
  ready = 'ready',
  play = 'play',
  stop = 'stop',
  cameraPermissionDenied = 'cameraPermissionDenied'
}

enum VerificationFlow {
  side2 = 'side2',
  lfv = 'lfv',
  retry = 'retry',
}

enum WhatToCapture {
  idDocument = 'idDocument',
  selfie = 'selfie',
  docBackside = 'docBackside',
  additionalDoc = 'additionalDoc',
  utilityBill = 'utilityBill',
}

enum TimeoutActions {
  reset = 'reset',
  exit = 'exit',
}
/* eslint-enable no-unused-vars */

export {
  Views,
  IdDocumentSide,
  DocumentTypes,
  VibrationTunes,
  FacingModes,
  VideoStreamStatus,
  VerificationFlow,
  WhatToCapture,
  TimeoutActions,
}; // eslint-disable-line import/prefer-default-export
