import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import { TimeoutActions } from 'resources/enums';

type Props = {
  callback: (actions: TimeoutActions) => void;
};

type State = Record<string, never>;

export default class IdDocumentScanTimeout extends Component<Props, State> {
  render(): React.ReactNode {
    const { callback } = this.props;

    return (
      <div className="inner-frame">
        <div>
          <div>
            <div className="text"><Translate i18nKey="dv.error-message" /></div>
          </div>
          <div
            className="button button-big timeouted-action first-button overlay-button"
            role="button"
            onClick={() => callback(TimeoutActions.reset)}
            onKeyPress={() => { }}
            tabIndex={-1}
          >
            <Translate i18nKey="dv.btn.restart" />
          </div>
          <div
            className="button button-link timeouted-action"
            role="button"
            onClick={() => callback(TimeoutActions.exit)}
            onKeyPress={() => { }}
            tabIndex={-1}
          >
            <Translate i18nKey="dv.btn.exit" />
          </div>
        </div>
      </div>
    );
  }
}
