import React, { Component } from 'react';
import Translate from 'components/translate/Translate';

interface IProps {
  label: string;
  changeState: (obj: { [key: string]: number | string }) => void;
  error: boolean,
  errorText: string;
  type: string;
  name: string;
  value: any;
  className?: string;
  disabled?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

export default class SimpleInput extends Component<IProps> {
  render() {
    const {
      label,
      type,
      name,
      value,
      disabled,
      changeState,
      onBlur,
      error,
      errorText,
      className,
    } = this.props;
    const defaultClassNames = ['custom-input', 'simple-input'];

    return (
      <div className={[...defaultClassNames, className, ...(error ? ['error-input'] : [])].join(' ')}>
        <label><Translate i18nKey={label} /></label>
        <div className="input-container">
          <input
            type={type}
            name={name}
            value={value}
            disabled={disabled}
            className={error ? 'invalid' : ''}
            onChange={(e: any) => changeState({ [name]: e.target.value })}
            onBlur={onBlur}
          />
        </div>
        {false && error && <div className="input-error simple-input-error"><Translate i18nKey={errorText} /></div>}
      </div>
    );
  }
}
