import React, { Component } from 'react';
import VerifyCodeStart from 'modules/verifyCode/views/start/Start';
import VerifyCode from 'modules/verifyCode/views/verify/Verify';
import ContactCustomer from 'modules/verifyCode/views/contactCustomer/ContactCustomer';
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { IAppConfig, IMtan } from 'types/index.d';
import { handleFetchErrors, fetch } from 'utils';
import AppContext from 'modules/AppContext';

enum Views {
  start = 'START',
  verify = 'VERIFY',
  contact = 'CONTACT',
}
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */

interface IProps {
  onDone: () => void;
  viewConfig: IAppConfig;
  mtan: IMtan;
  tc: string;
  processId: string;
}
interface IState {
  view: Views;
  transactionData: any;
  id?: number;
}

export default class VerifyCodeContent extends Component<IProps, IState> {
  static contextType?: React.Context<any> = AppContext;

  constructor(props: IProps) {
    super(props);
    this.state = {
      view: Views.start,
      transactionData: {
        mtan: {
          default: 'email',
          allowed: ['sms', 'email'],
        },
      },
    };
  }

  onClick = (isValid: boolean) => {
    const { onDone, viewConfig } = this.props;
    if (isValid) {
      onDone();
      return;
    }
    if (viewConfig.modules.mtan.enabled) {
      // TODO: Go back to MTAN conditionally
      return;
    }
    this.setState({ view: Views.contact });
  };

  notReceivedData = () => {
    this.setState({ view: Views.contact });
  };

  goBack = () => {
    const { view } = this.state;
    if (view === Views.contact) {
      this.setState({ view: Views.verify });
    } else if (view === Views.verify) {
      this.setState({ view: Views.start });
    }
  };

  sendVerification =  async () => {
    const { mtan, tc } = this.props;
    const { transactionData } = this.state;
    const { lang } = this.context;
    return fetch('/sendVerification', {
      method: 'POST',
      body: JSON.stringify({
        ...mtan,
        type: transactionData.mtan.default,
        transactionCode: tc,
        lang: lang.default,
      }),
    })
      .then(handleFetchErrors)
      .then((res) => res.json())
      .then(({ id }) => {
        this.setState({ view: Views.verify, id });
      });
  };

  verify = async (code: string): Promise<any> => {
    const { onDone, tc } = this.props;
    return fetch('/verify', {
      method: 'POST',
      body: JSON.stringify({
        transactionCode: tc,
        code
      }),
    })
      .then(handleFetchErrors)
      .then((res) => res.json())
      .then(() => onDone());
  };

  render() {
    const { view, transactionData } = this.state;
    let currentView = (
      <VerifyCodeStart
        sendVerification={this.sendVerification}
        transactionData={transactionData}
      />
    );
    if (view === Views.verify) {
      currentView = (
        <VerifyCode
          verify={this.verify}
          transactionData={transactionData}
          onClick={this.onClick}
          notReceivedData={this.notReceivedData}
          goBack={this.goBack}
        />
      );
    } else if (view === Views.contact) {
      currentView = (
        <ContactCustomer goBack={this.goBack} />
      );
    }
    return currentView;
  }
}
