import React, { Component } from 'react';
import { ReactComponent as Frame } from 'assets/images/frame-rect.svg';

type Props = {
  callback: any,
};

export default class CaptureImage extends Component<Props> {
  render(): React.ReactNode {
    const { callback } = this.props;
    return (
      <div className="comp-capture-image">
        <div className="frame">
          <Frame className="capture-image-frame" />
        </div>
        <div className="capture-button-section capture-button-background">
          <div
            className="button"
            role="button"
            aria-label="Capture button"
            onClick={callback}
            onKeyPress={callback}
            tabIndex={-1}
          />
        </div>
      </div>
    );
  }
}
