import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import langs from 'resources/lang/languages';
import ach from 'resources/lang/ach.lang.json';
import appConfig from 'resources/branding/appconfig.json';

const DETECTION_OPTIONS = {
  order: ['navigator'],
};

const { lang: langsConfig } = appConfig;

let config: any;
const urlParams = new URLSearchParams(window.location.search);
const translate = urlParams.get('translate') === 'true';
const isCrowdinTest = urlParams.get('crowdin_test') === 'true';
if (process.env.REACT_APP_ENV === 'CROWDIN' && (translate || isCrowdinTest)) {
  const ACH = 'ach';
  config = {
    fallbackLng: ACH,
    resources: {
      ach: {
        translation: ach,
      },
    },
    supportedLngs: [ACH],
    keySeparator: false,
  };
} else {
  let defaultLang = 'en';

  if (langsConfig) {
    defaultLang = langsConfig.default;
  }

  config = {
    detection: DETECTION_OPTIONS,
    resources: langs,
    fallbackLng: defaultLang,
    supportedLngs: langsConfig.langs,
    keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // support basic HTML tags
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: [
        'br', 'strong', 'i', 'u',
        'b', 'wbr', 'sub', 'sup',
        'del', 'ins', 'span', 'small',
        'samp', 's', 'pre', 'mark',
        'kdb', 'hr', 'em', 'dfn',
        'code', 'cite', 'bdo', 'bdi',
        'aside', 'article', 'address', 'abbr',
      ],
    },
  };
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(config);

export default i18n;
