import React, { Component } from 'react';

import Header from 'components/header/Header';
import Start from 'modules/start/views/start/Start';
import CompatibilityNotes from 'modules/start/views/compatibilityNotes/CompatibilityNotes';

import Views from 'modules/start/types.d';

interface State {
  view: Views;
  prevView: Views,
}

interface Props {
  supportEmail: any;
  onDone: () => void;
}

export default class StartContent extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      view: Views.start,
      prevView: null,
    };
  }

  setView = (view: Views) => {
    const { view: prevView } = this.state;
    this.setState({ view, prevView });
  };

  goBack = () => {
    const { prevView } = this.state;
    this.setView(prevView);
  };

  getCurrentView = (view: Views): React.ReactElement => {
    const { supportEmail, onDone } = this.props;
    let component: React.ReactElement = (
      <Start
        onDone={onDone}
        onCompatibilityNotesTextClick={() => this.setView(Views.compatibilityNotes)}
      />
    );
    if (view === Views.compatibilityNotes) {
      component = (
        <CompatibilityNotes
          supportEmail={supportEmail}
        />
      );
    }
    return component;
  };

  render() {
    const { view } = this.state;
    const backButton = view === Views.compatibilityNotes ? 'back' : '';

    return (
      <>
        <Header icons={backButton} goBack={this.goBack} />
        {this.getCurrentView(view)}
      </>
    );
  }
}
