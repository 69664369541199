import React, { Component } from 'react';
import Translate from 'components/translate/Translate';
import Header from 'components/header/Header';
import AppContext from 'modules/AppContext';

type Props = {
  config: boolean,
};

class NewVersionIsAvailable extends Component<Props> {
  render() {
    const { config } = this.props;
    const { leaveApp, closeButtonOnErrorScreens } = this.context;

    return (
      <div className="comp-new-version-available">
        {config && <Header />}
        <div className="card">
          <div className="message">
            <div>
              <strong>
                <Translate i18nKey="new-version-is-available.message" />
              </strong>
            </div>
          </div>
          {
            closeButtonOnErrorScreens
            && (
              <div
                className="button button-big"
                role="button"
                onKeyPress={() => leaveApp(true)}
                onClick={() => leaveApp(true)}
                tabIndex={-1}
              >
                <Translate i18nKey="error-pages.close" />
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

NewVersionIsAvailable.contextType = AppContext;

export default NewVersionIsAvailable;
