import React, { Component } from 'react';
import QRCode from 'qrcode.react';
import Header from 'components/header/Header';
import Translate from 'components/translate/Translate';
import AppContext from 'modules/AppContext';

class DesktopNotSupported extends Component {
  render() {
    const { closeButtonOnErrorScreens, leaveApp } = this.context;
    const { href } = (window as any).location;
    return (
      <div className="comp-desktop-not-supported">
        <Header />
        <div className="card">
          <div className="fill-area">
            <div className="contents">
              <div className="message">
                <Translate i18nKey="desktop-not-supported.message" />
              </div>
            </div>
          </div>
          <div className="qr-container">
            <div>
              <QRCode value={href} size={200} />
            </div>
          </div>
          {closeButtonOnErrorScreens && (
            <div
              className="button button-big"
              role="button"
              onKeyPress={() => leaveApp(true)}
              onClick={() => leaveApp(true)}
              tabIndex={-1}
            >
              <Translate i18nKey="error-pages.close" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

DesktopNotSupported.contextType = AppContext;

export default DesktopNotSupported;
