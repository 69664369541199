import React, { Component } from 'react';
import Translate from 'components/translate/Translate';

type Props = {
  leaveApp: any,
  closeButtonOnErrorScreens: boolean,
};

export default class NoInternetConnection extends Component<Props> {
  render() {
    const { leaveApp, closeButtonOnErrorScreens } = this.props;
    return (
      <div className="offline-overlay">
        <div className="offline-message">
          <Translate i18nKey="no-connection.message" />
        </div>
        {
          closeButtonOnErrorScreens
          && (
            <div
              className="button button-big"
              role="button"
              onKeyPress={() => leaveApp(true)}
              onClick={() => leaveApp(true)}
              tabIndex={-1}
            >
              <Translate i18nKey="error-pages.close" />
            </div>
          )
        }
      </div>
    );
  }
}
